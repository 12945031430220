import {
  _,
  bind,
  IAjaxState,
  React,
} from "$Imports/Imports";

import {
  AjaxActionIndicator
} from "$Components/Common/AjaxActionIndicator";

import {
  TenantJobsLink
} from "./TenantJobsLink";

import {
  DataTable,
  DateFormatter,
  DateRangeCalculator,
  IDataTableColumn
} from "../components/Common";

import {
  DelinquentTenantViewModel,
  DelinquentTenantViewModelIEnumerableResponseBase
} from "../generated/api";

import { JobService } from "../state/JobFreezerService";

import { NavigationService } from "../state/NavigationFreezerService";

import { ISecurityContext } from "$Providers/AuthenticationProvider";

const styles: {
  title: string,
  critical: string,
  scroll: string,
} = require("./DelinquentTenantTable.scss");

interface IDelinquentTenantProps {
  securityContext: ISecurityContext;
  delinquentTenantResults: IAjaxState<DelinquentTenantViewModelIEnumerableResponseBase>,
  stickyHeader?: boolean,
}

export class DelinquentTenantTable extends React.Component<IDelinquentTenantProps> {

  @bind
  private _onTenantClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: DelinquentTenantViewModel) {
    if (event.button !== 0) {
      return;
    }
    JobService.setFilter({
      adapter: "",
      tenantId: data && data.workflowInstanceId ?
        data.workflowInstanceId : data && data.tenantId ?
          data.tenantId : undefined,
      dateRange: DateRangeCalculator.calcDateRange("today"),
      status: ""
    });
    JobService.fetchJobs(true);
    NavigationService.navigateTo("/jobs");
  }

  private getColumns(securityContext: ISecurityContext): IDataTableColumn<DelinquentTenantViewModel>[] {
    // Delinquent Tenant columns
    var table: IDataTableColumn<DelinquentTenantViewModel>[] = [
      {
        columnName: "tenantName",
        columnFieldData: (d) => {
          return (
            <TenantJobsLink
              tenant={d}
              onClick={this._onTenantClick}
            />
          )
        },
        headerValue: "Tenant Name",
        sortMethod: (d) => (d.tenantName ? d.tenantName : "").toLowerCase(),
      },
      {
        columnName: "adapter",
        columnFieldData: (d) => d.adapter,
        headerValue: "Adapter",
        sortMethod: (d) => (d.adapter ? d.adapter : "").toLowerCase(),
      },
      {
        columnName: "lastSuccessDate",
        columnFieldData: (d) => d.lastSuccessDate ? (<DateFormatter value={d.lastSuccessDate} />) : null,
        headerValue: "Last Success Date",
        sortMethod: (d) => (d.lastSuccessDate ? d.lastSuccessDate : ""),
      },
    ];

    // Add tenant id for Admin Users
    if (securityContext.IsAdminUser) {
      table.push(
        {
          columnName: "tenantId",
          columnFieldData: (d) => d.tenantId,
          headerValue: "Tenant ID",
          sortMethod: (d) => (d.tenantId ? d.tenantId : ""),
        },
      );
    }

    return table;
  };


  render() {
    const { securityContext, delinquentTenantResults, stickyHeader } = this.props;
    const delinquentTenantList = delinquentTenantResults?.data?.data ?? [];
    const count = delinquentTenantList.length;
    const label = `${count} ` + (count === 1 ? "Delinquent Tenant" : "Delinquent Tenants");

    return (
      <>
        <div className={count > 0 ? styles.critical : styles.title}>
          {label}
        </div>
        <AjaxActionIndicator
          state={[delinquentTenantResults]}
        />
        <div
          className={styles.scroll}
        >
          <DataTable
            stickyHeader={stickyHeader}
            columns={this.getColumns(securityContext)}
            data={delinquentTenantList}
          />
        </div>
      </>
    );
  }
}