import {
  React,
  bind,
} from "$Imports/Imports";

import {
  AdvanceTextField
} from "$Components/Common";

import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  FormControlLabel,
  Switch
} from "$Imports/MaterialUIComponents";

import {
  AdvancedSettingsService,
  IAdvancedSettingsServiceInjectedProps
} from "$State/tenant-views/AdvancedSettingsFreezerService";

interface IAdvancedSettingsBaseProps {
  open: boolean;
  tenantId: string;
  onCancel: () => void;
  onSave: () => void;
}

type IAdvancedSettingsProps = IAdvancedSettingsBaseProps & IAdvancedSettingsServiceInjectedProps;

export class _AdvancedSettings extends React.Component<IAdvancedSettingsProps> {

  @bind
  private _onCancelClick(event: React.MouseEvent<HTMLButtonElement>) {
    this.props.advancedSettingsService.resetFreezer();
  }

  @bind
  private _onSaveClick(event: React.MouseEvent<HTMLButtonElement>) {
    this.props.onSave();
  }

  @bind
  private onDebouncedChangeImageName(newValue: string): void {
    this.props.advancedSettingsService.updateSettings({ imageName: newValue });
  }

  @bind
  private onDebouncedChangeFriendlyName(newValue: string): void {
    this.props.advancedSettingsService.updateSettings({ friendlyName: newValue });
  }

  @bind
  private onDebouncedChangeCronTabSchedule(newValue: string): void {
    this.props.advancedSettingsService.updateSettings({ crontabSchedule: newValue });
  }

  @bind
  private onCheck(e: React.ChangeEvent<HTMLInputElement>, checked: boolean): void {
    this.props.advancedSettingsService.updateSettings({ active: checked });
  }

  render() {

    const tenantData = this.props.advancedSettingsService.getState().tenant || {};

    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.onCancel}
        key={"dialog"}
      >
        <DialogTitle>Advanced Settings</DialogTitle>
        <DialogContent
          style={{ width: "28em" }}
        >
          <div>
            <AdvanceTextField
              label="Tenant Id"
              value={tenantData.tenantId}
              fullWidth
              disabled
            />
          </div>
          <div>
            <AdvanceTextField
              label="Friendly Name"
              name="friendlyName"
              value={tenantData.friendlyName}
              fullWidth
              onDebouncedChange={this.onDebouncedChangeFriendlyName}
            />
          </div>
          <div>
            <AdvanceTextField
              label="Image Name"
              name="imgageName"
              value={tenantData.imageName}
              fullWidth
              onDebouncedChange={this.onDebouncedChangeImageName}
            />
          </div>
          <div>
            <AdvanceTextField
              label="Cron Tab Schedule"
              name="cronTabSchedule"
              value={tenantData.crontabSchedule}
              onDebouncedChange={this.onDebouncedChangeCronTabSchedule}
            />
          </div>
          <div>
            <FormControlLabel
              control={<Switch
                name="active"
                checked={!!tenantData.active}
                onChange={this.onCheck}
              />}
              label="Active"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={this._onSaveClick} color="primary">
            Save
          </Button>
          <Button variant="contained" onClick={this._onCancelClick} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export const AdvancedSettings = AdvancedSettingsService.inject(
  _AdvancedSettings
);