import {
  FreezerService,
  IAjaxState,
  managedAjaxUtil,
  _,
  moment,
  bind
} from "$Imports/Imports";

import {
  SitePubSubManager
} from "$Utilities/PubSubUtil";

import {
  IDateRange,
  DateRangeCalculator
} from "$Components/Common";

import {
  IPagerState
} from "./PagerPagingState";

import {
  ErrorService
} from "./ErrorFreezerService";

import {
  JobSummaryViewModelIEnumerableResponseBase,
  ActivityApiFactory
} from "$Generated/api";

import { ISortState } from "./SortState";

const InjectedPropName = "jobService";

interface IFilter {
  adapter: string;
  searchValue: string;
  status: string;
  dateRange: IDateRange;
  tenantId: string;
}

interface IJobFreezerState {
  recentJobsResults: IAjaxState<JobSummaryViewModelIEnumerableResponseBase>;
  recentJobsPagerState: IPagerState;
  recentJobsSortState: ISortState;
  filter: IFilter;
  errorState: boolean;
}

const defaultPagerSettings: IPagerState = {
  page: 0,
  rowsPerPage: 25
};

class JobFreezerService extends FreezerService<IJobFreezerState, typeof InjectedPropName> {
  constructor() {
    super({
      recentJobsResults: managedAjaxUtil.createInitialState(),
      recentJobsPagerState: defaultPagerSettings,
      filter: {
        adapter: "",
        searchValue: "",
        status: "",
        dateRange: DateRangeCalculator.calcDateRange("today"),
        tenantId: ""
      },
      recentJobsSortState: {
        sortColumnName: "start-date",
        sortDirection: "desc"
      },
      errorState: false,
    }, InjectedPropName);

    SitePubSubManager.subscribe("application:login:before", this.clearResults);
  }

  @bind
  private clearResults() {

    this.clearRecentJobs(); // Moved to a function so we can clear jobs without resetting filter

    this.freezer.get().filter.set({
      adapter: "",
      searchValue: "",
      status: ""
    });
  }

  private _isFilterDateRangeValid(): boolean {
    const filter = this.freezer.get().filter.toJS();

    return this._isDateRangeValid(filter.dateRange.startDate, filter.dateRange.endDate);
  }

  private _isDateRangeValid(
    startDateTime: moment.Moment | Date | null,
    endDateTime: moment.Moment | Date | null): boolean {

    if (startDateTime === null || endDateTime === null) {
      return false;
    }

    const endDate = moment(endDateTime);
    const startDate = moment(startDateTime);

    if (endDate.isValid() &&
      startDate.isValid() &&
      startDate.isBefore(endDate) &&
      endDate.diff(startDate, "days") <= 90) {
      return true;
    }

    return false;
  }

  private _hasDateRangeChanged(oldDateRange: IDateRange, newDateRange: IDateRange): boolean {

    if ((oldDateRange.startDate !== null && newDateRange.startDate === null) ||
      (oldDateRange.startDate === null && newDateRange.startDate !== null) ||
      (oldDateRange.endDate !== null && newDateRange.endDate === null) ||
      (oldDateRange.endDate === null && newDateRange.endDate !== null)) {
      return true;
    }

    if (oldDateRange.endDate === null ||
      oldDateRange.startDate === null ||
      newDateRange.endDate === null ||
      newDateRange.startDate === null) {
      return false;
    }

    const oldStartDate = moment(oldDateRange.startDate);
    const oldEndDate = moment(oldDateRange.endDate);
    const newStartDate = moment(newDateRange.startDate);
    const newEndDate = moment(newDateRange.endDate);

    if (!oldStartDate.isSame(newStartDate) || !oldEndDate.isSame(newEndDate)) {
      return true;
    }

    return false;
  }

  public async fetchJobs(forceUpdate: boolean = false) {
    const results = this.freezer.get().recentJobsResults;
    const filterDateRange = this.freezer.get().filter.dateRange.toJS();
    const tenantId = this.freezer.get().filter.tenantId
    if (results.hasFetched && !forceUpdate) {
      return;
    }
    // If any required fields aren't filled in, return and set error state
    if (tenantId === undefined || tenantId === "") {
      // Display message
      this.freezer.get().set({errorState: true});
      return;
    }
    // Unable to fetch data if the dates are null.
    if (!this._isFilterDateRangeValid() ||
      filterDateRange.startDate === null ||
      filterDateRange.endDate === null) {
      return;
    }
    const isoDateRange = DateRangeCalculator.convertToISOString(filterDateRange);
    return managedAjaxUtil.fetchResults({
      ajaxStateProperty: "recentJobsResults",
      freezer: this.freezer,
      onExecute: (apiOptions, params, options) => {
        const activityFactory = ActivityApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
        return activityFactory.apiV1ActivitySummaryGet(params);
      },
      params: {
        startDateTime: isoDateRange.startDate,
        endDateTime: isoDateRange.endDate,
        tenantId: tenantId,
      },
      onError: () => {
        ErrorService.pushErrorMessage("Failed to retrieve job data from the server.");
      }
    });
  }

  public setRecentJobsPagerState(pager: IPagerState) {
    this.freezer.get().recentJobsPagerState.set(pager);
  }

  public setRecentJobsSortState(sortState: ISortState) {
    this.freezer.get().recentJobsSortState.set(sortState);
  }

  @bind
  public clearRecentJobs()
  {
    this.freezer.get().set({
      recentJobsResults: managedAjaxUtil.createInitialState(),
      recentJobsPagerState: defaultPagerSettings,
    });

    this.freezer.get().recentJobsPagerState.set({
      page: 0,
    });
  }

  public setFilter(filter: Partial<IFilter>) {
    const currentFilter = this.freezer.get().filter.toJS();
    const page = this.freezer.get().recentJobsPagerState.toJS();
    const { dateRange, tenantId } = filter;
    this.freezer.get().set({
      filter: _.assign(currentFilter, filter),
    });
  }
}

export const JobService = new JobFreezerService();
export type IJobServiceInjectedProps = ReturnType<JobFreezerService["getPropsForInjection"]>;