import {
	React,
	bind,
	_
} from "$Imports/Imports";

import {
	viewType
} from "$State/TenantSummaryFreezerService";

import {
	TenantSummaryViewModel
} from "$Generated/api";

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle
} from "$Imports/MaterialUIComponents";

interface IHardDeleteTenantProps {
	/** The view type that should be loaded */
	view: viewType;

	/** The tenant to hard delete */
	hardDeleteTenant: string | null;

	/** Cancel button click handler */
	onCancelClick: () => void;

	/** Deactivate button click handler */
	onHardDeleteClick: () => void;
}

export class HardDeleteTenantDialog extends React.Component<IHardDeleteTenantProps> {

	@bind
	private _onCancelClick() {
		this.props.onCancelClick();
	}

	@bind
	private _onHardDeleteClick() {
		this.props.onHardDeleteClick();
	}

	render() {
		const { view, hardDeleteTenant } = this.props;
		const isOpen = view === "HardDeleteTenant";
		const primaryColor = "primary";
		return (
			<Dialog open={isOpen}>
				<DialogTitle>Hard Delete Tenant</DialogTitle>
				<DialogContent>
					Continuing will hard delete {hardDeleteTenant ?? "the tenant"}.
				</DialogContent>
				<DialogActions>
					<Button color={primaryColor} onClick={this._onCancelClick}>Cancel</Button>
					<Button color={primaryColor} onClick={this._onHardDeleteClick}>Hard Delete</Button>
				</DialogActions>
			</Dialog>
		);
	}
}