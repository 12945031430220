import {
  React,
  _,
  memoizeOne,
  bind
} from "$Imports/Imports";

import {
  Table,
  TableFooter
} from "$Imports/MaterialUIComponents";

import {
  DataTableHeader
} from "./DataTableHeader";

import {
  DataTableRows
} from "./DataTableRows";

import { directionType } from "./../DirectionType";

import {
  IDataTableColumn,
  sortFunctionType
} from "./IDataTableColumn";

interface IDataTableProps<T = unknown> {
  data: T[];
  columns: IDataTableColumn<T>[];
  takeRows?: number;
  skipRows?: number;
  defaultSortColumnName?: string;
  defaultSortDirection?: directionType;
  tableFooterComponent?: JSX.Element;
  hideHeader?: boolean;
  stickyHeader?: boolean;
  onRowClick?: (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, data: T) => void;
  onCellClick?: (event: React.MouseEvent<HTMLTableCellElement, MouseEvent>, data: T, column: IDataTableColumn<T>) => void;
  onSortChange?: (event: React.MouseEvent<HTMLElement>, columnName: string | undefined, direction: directionType) => void;
  setRowStyle?: (rowData: T) => string;
}

interface IDataTableState {
  sortDirection: directionType;
  sortColumnName?: string;
  propSortDirection?: directionType;
  propSortColumnName?: string;
}

export class DataTable<T = unknown> extends React.PureComponent<
  IDataTableProps<T>,
  IDataTableState
  > {

  state: IDataTableState = {
    sortDirection: "asc",
  };

  static getDerivedStateFromProps(props: IDataTableProps<unknown>, state: IDataTableState): Partial<IDataTableState> | null {

    // Update the state of the lastValue does not match the prop.
    if (state === null ||
      props.defaultSortColumnName !== state.propSortColumnName ||
      props.defaultSortDirection !== state.propSortDirection) {
      return {
        sortColumnName: props.defaultSortColumnName ? props.defaultSortColumnName : state.sortColumnName,
        sortDirection: props.defaultSortDirection ? props.defaultSortDirection : state.sortDirection,
        propSortColumnName: props.defaultSortColumnName,
        propSortDirection: props.defaultSortDirection
      };
    }

    // Do nothing if the value does not change.
    return null;
  }

  @bind
  private _onSortColumn(event: React.MouseEvent<HTMLElement>, sortColumnName: string | undefined, sortdirection: directionType) {
    const newSortDirection = sortdirection === "asc" ? "desc" : "asc";

    this.setState({
      sortDirection: newSortDirection,
      sortColumnName: sortColumnName,
    });

    if (this.props.onSortChange) {
      this.props.onSortChange(event, sortColumnName, newSortDirection);
    }
  }

  private _sortData(data: T[], columns: Array<IDataTableColumn<T>>, sortColumnName: string | undefined, sortDirection: directionType): T[] {
    let sortFunction: sortFunctionType<T> = (d) => d;

    if (sortDirection === null || sortColumnName === undefined) {
      return data;
    }

    const column = _.find(columns, (c) => c.columnName === sortColumnName);

    if (column && column.sortMethod !== undefined) {
      sortFunction = column.sortMethod;
    }

    let sortedData = data;

    if (sortFunction) {
      sortedData = _.sortBy(sortedData, sortFunction);
    }

    if (sortDirection === "desc") {
      sortedData = _.reverse(sortedData);
    }

    return sortedData;
  }

  private _pageData(data: T[], take?: number, skip?: number): T[] {
    if (take !== undefined && skip !== undefined) {
      return _.slice(data, skip, skip + take);
    }

    return data;
  }

  @bind
  private _onRowClick(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, data: T) {
    if (this.props.onRowClick) {
      this.props.onRowClick(event, data);
    }
  }

  @bind
  private _onCellClick(event: React.MouseEvent<HTMLTableCellElement, MouseEvent>, data: T, column: IDataTableColumn<T>) {
    if (this.props.onCellClick) {
      this.props.onCellClick(event, data, column);
    }
  }

  private readonly _sortData_memoized = memoizeOne(this._sortData);
  private readonly _pageData_memoized = memoizeOne(this._pageData);

  render(): JSX.Element {
    const { data, columns, skipRows, takeRows, tableFooterComponent, hideHeader, stickyHeader, setRowStyle } = this.props;
    const { sortDirection, sortColumnName } = this.state;

    const sortedData = this._sortData_memoized(data, columns, sortColumnName, sortDirection);
    const pagedData = this._pageData_memoized(sortedData, takeRows, skipRows);

    return (
      <Table stickyHeader={stickyHeader}>
        {
          hideHeader ? null :
          <DataTableHeader
            onSortColumnClick={this._onSortColumn}
            sortDirection={sortDirection}
            columns={columns}
            sortColumnName={sortColumnName}
          />
        }
        <DataTableRows
          data={pagedData}
          columns={columns}
          onRowClick={this._onRowClick}
          onCellClick={this._onCellClick}
          setRowStyle={setRowStyle}
        />
        <TableFooter>
          {tableFooterComponent}
        </TableFooter>
      </Table>
    );
  }
}