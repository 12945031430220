import {
  React,
  bind,
  _,
  memoizeOne,
  numeral
} from "$Imports/Imports";

import {
  Container
} from "./Container";

import {
  DashboardLink
} from "./DashboardLink";

import {
  Paper,
} from "$Imports/MaterialUIComponents";

import {
  IDashboardServiceInjectedProps,
  DashboardService
} from "$State/DashboardFreezerService";

import {
  AjaxActionIndicator
} from "$Components/Common/AjaxActionIndicator";

import {
  DelinquentTenantTable
} from "./DelinquentTenantTable"

import {
  TenantHistoryTable
} from "./TenantHistoryTable"

import {
  TenantSummary
} from "./TenantSummary";

import {
  DataTable,
  IDataTableColumn,
  PageHeader,
  SearchBox
} from "$Components/Common";

import {
  AdapterSummaryModel,
  AdapterSummaryModelIEnumerableResponseBase,
  SourceProviderModel
} from "$Generated/api";

import { TenantSummaryService } from "$State/TenantSummaryFreezerService";

import { NavigationService } from "$State/NavigationFreezerService";

import { ApplicationSecurityConsumer } from "$Providers/AuthenticationProvider";

const styles: {
  bottomPaper: string;
  bottomPanel: string;
  leftPanel: string;
  leftPaper: string;
  leftTableScroll: string;
  mainVerticalContainer: string;
  rightPanel: string;
  rightPaper: string;
  topContainer: string;
} = require("./Dashboard.scss");

const dashboardImage = require("$Images/demo/dashboard-graph.png");

interface IAdapterSummaryBaseProps {

}

type IAdapterSummaryProps = IAdapterSummaryBaseProps & IDashboardServiceInjectedProps;

export class _Dashboard extends React.Component<IAdapterSummaryProps> {

  @bind
  private _onClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: AdapterSummaryModel) {
    if (event.button !== 0) {
      return;
    }
    TenantSummaryService.setFilterValue({
      adapter: data.sourceProviders !== undefined && data.sourceProviders[0].friendlyName !== undefined ? data.sourceProviders[0].friendlyName : "",
    });
    NavigationService.navigateTo("/tenants");
  }

  private readonly columns: Array<IDataTableColumn<AdapterSummaryModel>> = [
    {
      columnName: "adapter",
      columnFieldData: (d) => {
        return (
          <DashboardLink
            data={d}
            onClick={this._onClick}
          />)
      },
      headerValue: "Adapter",
      sortMethod: (d) => this.getAdapterNames(d)
    },
    {
      columnName: "tenantCount",
      columnFieldData: (d) => d.tenantCount ? d.tenantCount : "",
      headerValue: "Tenant Count",
      sortMethod: (d) => d.tenantCount ? d.tenantCount : 0
    }
  ];

  componentWillMount() {
    this.props.dashboardService.fetchAdapterSummary(true);
    this.props.dashboardService.fetchDelinquentTenants(true);
  }

  private readonly _onSearch_memoizeOne = memoizeOne(this._onSearch);

  @bind
  private getAdapterNames(d: AdapterSummaryModel) {
    var names = _.map(d.sourceProviders, (s: SourceProviderModel) => s.friendlyName);
    return _.join(names, ", ");
  }

  @bind
  private _onClearAdapterSearch() {
    this.props.dashboardService.setAdapterSearch("");
  }

  @bind
  private _onChangeAdapterSearch(newValue: string) {
    this.props.dashboardService.setAdapterSearch(newValue);
  }

  private _onSearch(adapterData: AdapterSummaryModelIEnumerableResponseBase | null | undefined, adapterFilter: string): AdapterSummaryModelIEnumerableResponseBase | null | undefined {
    if (adapterData?.data) {
      var filteredAdapters = _.clone(adapterData);
      filteredAdapters.data = _.filter(adapterData.data, (d) => {
        if (adapterFilter.trim() === "") {
          return true;
        }

        const names = this.getAdapterNames(d);
        const nameFilter: boolean = names.toLowerCase().indexOf(adapterFilter.toLowerCase()) !== -1;

        return nameFilter;
      });

      return filteredAdapters;
    }
    return adapterData;
  }

  render() {
    const { adapterSummaryResults, adapterFilter, delinquentTenantResults } = this.props.dashboardService.getState();
    const { data } = adapterSummaryResults;
    const tenantData = this._onSearch_memoizeOne(data, adapterFilter);
    const adapterList = tenantData ? (tenantData.data ? tenantData.data : []) : []

    return (
      <Container>
        <PageHeader title="Dashboard" />
        <div className={styles.mainVerticalContainer}>
          <div className={styles.topContainer}>
            <div className={styles.leftPanel}>
              <Paper>
                <TenantSummary
                  data={data ? data : {}}
                />
              </Paper>
              <Paper className={styles.leftPaper}>
                <AjaxActionIndicator
                  state={[adapterSummaryResults]}
                />
                <SearchBox
                  value={adapterFilter}
                  onDebouncedChange={this._onChangeAdapterSearch}
                  filterStatusLabel={` ${numeral(adapterList.length).format("0,000")} ${adapterList.length === 1 ? "Adapter" : "Adapters"}`}
                  onClearClick={this._onClearAdapterSearch}
                />
                <div className={styles.leftTableScroll}>
                  <DataTable
                    stickyHeader={true}
                    columns={this.columns}
                    data={adapterList}
                  />
                </div>
              </Paper>
            </div>
            <div className={styles.rightPanel}>
              <Paper className={styles.rightPaper}>
                <ApplicationSecurityConsumer>
                  {(securityContext) => {
                    return (<DelinquentTenantTable
                      securityContext={securityContext}
                      stickyHeader={true}
                      delinquentTenantResults={delinquentTenantResults}
                    />)
                  }}
                </ApplicationSecurityConsumer>
              </Paper>
            </div>
          </div>
          <div className={styles.bottomPanel}>
            <Paper className={styles.bottomPaper}>
              <TenantHistoryTable
                stickyHeader={true}
              />
            </Paper>
          </div>
        </div>
      </Container>
    );
  }
}

export const Dashboard = DashboardService.inject(
  _Dashboard
);
