import {
  React,
  bind,
  _
} from "$Imports/Imports";

import {
  AdvanceTextField,
  SourceProviderSelector
} from "$Components/Common";

import {
  InputLabel,
  MenuItem,
  Select,
  Step
} from "$Imports/MaterialUIComponents";

import {
  IClientListItem,
  INewTenant
} from "$State/tenant-views/TenantAddFreezerService";

import {
  CategoryItem
} from "$Generated/api";

import {
  getEnumLabel
} from "$Utilities/enums";

interface IInitialStepProps {
  onChange: (e: any) => void;
  onClientChange: (id: number) => void;
  onAdapterChange: (e: React.ChangeEvent<{ name?: string | undefined; value: unknown; }>, child: React.ReactNode, adapterName: string) => void;
  adapters: CategoryItem[];
  clients: IClientListItem[];
  newTenantModel: INewTenant;
}

export class InitialStep extends React.PureComponent<IInitialStepProps> {
  render() {
    return (
      <Step>
        <>
          {this.props.clients.length > 1 && (
            <>
              <InputLabel id="lbl-client">Client</InputLabel>
              <Select
                name="client"
                onChange={(e) => this.props.onClientChange(parseInt(e.target.value as string))}
                value={this.props.newTenantModel.clientId}
                labelId="lbl-client"
              >
                <MenuItem key={0} value={0}><em>Select client</em></MenuItem>
                {this.props.clients.map((x) => (
                  <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>
                ))}
              </Select>
              <br />
            </>
          )}
          <AdvanceTextField
            label="Tenant Name"
            name="name"
            onChange={this.props.onChange}
            value={this.props.newTenantModel.name}
            fullWidth
            inputProps={{ maxLength: 100 }}
            helperText="Max Length 100 Characters"
          />
          <br/>
          <SourceProviderSelector
            sourceAdapterData={this.props.adapters}
            value={this.props.newTenantModel.adapterFriendlyName}
            onAdapterChange={this.props.onAdapterChange}
            style={{width: "350px"}}
          />
          <br/>
          <InputLabel
            id="lbl-preferred-freq"
            style={{marginTop: "16px"}}
          >
            Preferred Frequency
          </InputLabel>
          <Select
            name="frequency"
            onChange={this.props.onChange}
            value={this.props.newTenantModel.frequency}
            style={{width: "350px"}}
            labelId="lbl-preferred-freq"
          >
            <MenuItem value="EveryQuarterHour">{getEnumLabel("EveryQuarterHour")}</MenuItem>
            <MenuItem value="EveryHalfHour">{getEnumLabel("EveryHalfHour")}</MenuItem>
            <MenuItem value="EveryHour">{getEnumLabel("EveryHour")}</MenuItem>
            <MenuItem value="EveryTwoHours">{getEnumLabel("EveryTwoHours")}</MenuItem>
          </Select>
        </>
      </Step>
    );
  }
}