import {
    _,
    bind,
    React,
    numeral,
    memoizeOne,
  } from "$Imports/Imports";

  import {
    AjaxActionIndicator
  } from "$Components/Common/AjaxActionIndicator";
  
  import {
    TenantJobsLink
  } from "./TenantJobsLink";

  import {
    DataTable,
    DateRangeCalculator,
    IDataTableColumn,
    SearchBox,
  } from "$Components/Common";
  
  import {
    AdapterViewModel,
    DelinquentTenantViewModel,
    DelinquentTenantViewModelIEnumerableResponseBase,
  } from "$Generated/api";
  
  import { JobService } from "$State/JobFreezerService";
  
  import { NavigationService } from "$State/NavigationFreezerService";
  
  import {
    MonthlyTenantSummaryService,
    IMonthlyTenantSummaryServiceInjectedProps,
  } from "$State/MonthlyTenantSummaryFreezerService";
  import { TrendingUp, TrendingDown, TrendingFlat } from "$Imports/MaterialUIIcons";
  
  const styles: {
    title: string,
    critical: string,
    tenantTableScroll: string,
    tableTitle: string,
    titleArea: string,
    tableSearchBarArea: string,
    tableTitleArea: string,
  } = require("./TenantHistoryTable.scss");
  
  interface ITenantHistoryTableBaseProps {
    stickyHeader?: boolean,
  }

  type ITenantHistoryTableProps = IMonthlyTenantSummaryServiceInjectedProps & ITenantHistoryTableBaseProps;
  
  export interface CombinedTenantViewModel {
    tenantId: string;
    tenantName: string | undefined;
    adapters: string | undefined;
    weeklyJobSuccessRate: number | undefined;
    weeklySubmissionSuccessRate: number | undefined;
    monthlyJobSuccessRate: number | undefined;
    monthlySubmissionSuccessRate: number | undefined;
  }

  export class _TenantHistoryTable extends React.Component<ITenantHistoryTableProps> {
  
    @bind
    private _onTenantClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, data: DelinquentTenantViewModel) 
    {
      if (event.button !== 0) {
        return;
      }
      JobService.setFilter({
        adapter: "",
        tenantId: data && data.workflowInstanceId ?
          data.workflowInstanceId : data && data.tenantId ?
            data.tenantId : undefined,
        dateRange: DateRangeCalculator.calcDateRange("today"),
        status: "",
      });
      JobService.fetchJobs(true);
      NavigationService.navigateTo("/jobs");
    }

    componentDidMount(): void {
        this.props.tenantMonthlySummaryService.fetchTenants();
    }

    diffIcon(value: number)
    {
      return (<><div style={{display:"flex", alignItems: "center"}}>{value.toFixed(3) + "%"}{
        value >0 ? 
        <TrendingUp htmlColor="#2e7d32"/>
        : 
        (value<0 ?
          <TrendingDown color="error"/>
          :
          <TrendingFlat htmlColor="#0C66E4"/>
        )
      }</div></>);
    }

    private readonly columns: Array<IDataTableColumn<CombinedTenantViewModel>> = [
      {
        columnName: "tenantName",
        columnFieldData: (d) => {
          return (
            <TenantJobsLink
            tenant={{
              tenantName: d.tenantName,
              adapter: d.adapters,
              lastSuccessDate: undefined,
              workflowInstanceId : d.tenantId,
              tenantId: d.tenantId}}
            onClick={this._onTenantClick}
          />
          )
        },
        headerValue: "Tenant Name",
        sortMethod: (d) => (d.tenantName ? d.tenantName : "").toLowerCase(),
      },
      {
        columnName: "adapters",
        columnFieldData: (d) => d.adapters,
        headerValue: "Adapter",
        sortMethod: (d) => (d.adapters),
      },
      {
        columnName: "weeklyJobSuccessRate",
        columnFieldData: (d) => d.weeklyJobSuccessRate + "%",
        headerValue: "Weekly Job",
        sortMethod: (d) => (d.weeklyJobSuccessRate ? d.weeklyJobSuccessRate : ""),
      },
      {
        columnName: "monthlyJobSuccessRate",
        columnFieldData: (d) => d.monthlyJobSuccessRate + "%",
        headerValue: "Monthly Job",
        sortMethod: (d) => (d.monthlyJobSuccessRate ? d.monthlyJobSuccessRate : ""),
      },
      {
        columnName: "jobSuccessRateDifference",
        columnFieldData: (d) => {
          return this.diffIcon((d.weeklyJobSuccessRate ? d.weeklyJobSuccessRate : 0) - (d.monthlyJobSuccessRate ? d.monthlyJobSuccessRate : 0));
        },
        headerValue: "Job Difference",
        sortMethod: (d) => ((d.weeklyJobSuccessRate ? d.weeklyJobSuccessRate : 0) - (d.monthlyJobSuccessRate ? d.monthlyJobSuccessRate : 0)),
      },
      {
        columnName: "weeklySubmissionsSuccessRate",
        columnFieldData: (d) => d.weeklySubmissionSuccessRate + "%",
        headerValue: "Weekly Submission",
        sortMethod: (d) => (d.weeklySubmissionSuccessRate ? d.weeklySubmissionSuccessRate : ""),
      },
      {
        columnName: "monthlySubmissionsSuccessRate",
        columnFieldData: (d) => d.monthlySubmissionSuccessRate + "%",
        headerValue: "Monthly Submission",
        sortMethod: (d) => (d.monthlySubmissionSuccessRate ? d.monthlySubmissionSuccessRate : ""),
      },
      {
        columnName: "submissionSuccessRateDifference",
        columnFieldData: (d) => {
          return this.diffIcon((d.weeklySubmissionSuccessRate ? d.weeklySubmissionSuccessRate : 0) - (d.monthlySubmissionSuccessRate ? d.monthlySubmissionSuccessRate : 0))
        },
        headerValue: "Submission Difference",
        sortMethod: (d) => ((d.weeklySubmissionSuccessRate ? d.weeklySubmissionSuccessRate : 0) - (d.monthlySubmissionSuccessRate ? d.monthlySubmissionSuccessRate : 0)),
      },
    ];

    @bind
    private _onChangeTableSearch(newValue: string){
       this.props.tenantMonthlySummaryService.setTableFilter(newValue);
    }

    @bind
    private _onClearTableSearch(){
      this.props.tenantMonthlySummaryService.setTableFilter("");
    }

    private readonly _onSearch_memoizeOne = memoizeOne(this._onSearch);

    private _onSearch(data: CombinedTenantViewModel[], search: string): CombinedTenantViewModel[] {
      if (search.trim() === "") {
        return data;
      }
  
      return _.filter(data, (d) => {
        const tenantName = (d.tenantName ? d.tenantName.toLowerCase() : "").indexOf(search.toLowerCase()) !== -1;
        if (tenantName) {
          return true;
        }
        
        const adapterResults = (d.adapters ? d.adapters.toLowerCase() : "").indexOf(search.toLowerCase()) !== -1; 
        if (adapterResults) {
          return true;
        }

        return false;
      });
    }

    sumUpAdapters(adapter : AdapterViewModel[]){
      let adapterList = "";
      if(adapter.length>0){
        if(adapter[0].friendlyName){
          adapterList = adapter[0].friendlyName;
        }
      }
      if(adapter.length>1){
        for(let i =1; i<adapter.length; i++){
          if(adapter[i].friendlyName!==undefined){
            adapterList = adapterList + ", " + adapter[i].friendlyName!;
          }
        }
      }
      return adapterList;
    }
    render() {
      const { stickyHeader } = this.props;
      const label = "Job/Submission Success Rate Comparison";
      const {
        tenantSummaryResults,
        tenantMonthlySummaryResults,
        tableFilter,
      } = this.props.tenantMonthlySummaryService.getState();
    let tenantSummaryData = tenantSummaryResults.data?.data || [];
    let tenantMonthlySummaryData = tenantMonthlySummaryResults.data?.data || [];
    const tableData : CombinedTenantViewModel[] = [];

    if((tenantSummaryData!==undefined) && (tenantSummaryData.length!==0) && (tenantSummaryData!==undefined) && (tenantSummaryData.length!==0)){
      tenantSummaryData.forEach(weekly => {
        if(weekly.tenantId!==undefined && weekly.jobSuccessRate!==undefined && weekly.submissionSuccessRate!==undefined ){
          tenantMonthlySummaryData.forEach(monthly=> {
            if(monthly.tenantId!==undefined && monthly.jobSuccessRate!==undefined && monthly.submissionSuccessRate!==undefined ){
              if(monthly.tenantId===weekly.tenantId){
                tableData.push({
                  tenantId: monthly.tenantId,
                  tenantName: monthly.tenantFriendlyName,
                  adapters: (monthly.adapters ? this.sumUpAdapters(monthly.adapters) : ""),
                  weeklyJobSuccessRate: weekly.jobSuccessRate,
                  weeklySubmissionSuccessRate: weekly.submissionSuccessRate,
                  monthlyJobSuccessRate: monthly.jobSuccessRate,
                  monthlySubmissionSuccessRate: monthly.submissionSuccessRate,
                })
              return;
              }
            }
          })
        }
      })
    }

    const filteredData = this._onSearch_memoizeOne(tableData, tableFilter);
    return (
      <> 
        <div className={styles.titleArea}>
          <div className={styles.tableSearchBarArea}>
            <SearchBox
              value={tableFilter}
              onDebouncedChange={this._onChangeTableSearch}
              filterStatusLabel={` ${numeral(filteredData.length).format("0,000")} ${filteredData.length === 1 ? "Row" : "Rows"}`}
              onClearClick={this._onClearTableSearch}
            />
          </div>
          <div className={styles.tableTitleArea}>
            <div className={styles.tableTitle}> 
              {label}
            </div>
          </div>
        </div>
        <AjaxActionIndicator
          state={[tenantMonthlySummaryResults]}
        />
        <div
          className={styles.tenantTableScroll}
        >
          <DataTable stickyHeader={stickyHeader}
            columns={this.columns}
            data={filteredData}
          />
        </div>
      </>
      );
    }
  }

  export  const TenantHistoryTable =  MonthlyTenantSummaryService.inject(
    _TenantHistoryTable
  );