import {
	_,
	bind,
	FreezerService,
	IAjaxState,
	managedAjaxUtil
} from "$Imports/Imports";

import {
	ResponseBase,
	TenantApiFactory,
	TenantSummaryViewModel,
	TenantViewModel
} from "$Generated/api";

import {
	SitePubSubManager
} from "$Utilities/PubSubUtil";

import {
	ErrorService
} from "../ErrorFreezerService";

const InjectedPropName = "tenantRenameService";

interface ITenantRenameState {
	/** The original name of the tenant */
	originalName: string;
	/** The new name the tenant has entered */
	newName: string;
	/** True if the rename dialog should be open */
	open: boolean;
	/** Rename ajax result stored in this property */
	renameTenantResult: IAjaxState<ResponseBase>;
	/** ID for tenant the user wants to rename*/
	tenantId: string;
	/** Reason (if any) why the new name is invalid */
	invalidReason: string;
}

class TenantRenameFreezerService extends FreezerService<ITenantRenameState, typeof InjectedPropName> {
  	constructor() {
		super ({
			newName: "",
			open: false,
			originalName: "",
			renameTenantResult: managedAjaxUtil.createInitialState(),
			tenantId: "",
			invalidReason: "",
		}, InjectedPropName);

		SitePubSubManager.subscribe("application:login:before", this._clear);
  	}

  	public closeRenameDialog() {
		this.freezer.get().set({
			open: false,
		})
  	}

	public hasNameChanged(): boolean {
		return this.freezer.get().originalName !== this.freezer.get().newName;
	}

	public onNameChange(newValue: string) {
		this.freezer.get().set({
			newName: newValue,
		})
		this.validateName(newValue);
	}
  
  	public openRenameDialog(tenant: TenantSummaryViewModel){
		this.freezer.get().set({
			open: true,
			originalName: tenant.tenantFriendlyName,
			newName: tenant.tenantFriendlyName,
			tenantId: tenant.tenantId,
			invalidReason: "",
		})
  	}
  
  	public async renameTenant() {
		const failMessage = "Failed to rename tenant";
		return managedAjaxUtil.fetchResults({
			freezer: this.freezer,
			ajaxStateProperty: "renameTenantResult",
			onExecute: (apiOptions, params, options) => {
				const tenantId = this.freezer.get().tenantId;
				const newName = this.freezer.get().newName;

				const tenantViewModel: TenantViewModel = {
					tenantId: tenantId,
					friendlyName: newName,
				};

				const tenantApi = TenantApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
				return tenantApi.apiV1TenantRenamePost({ body: tenantViewModel });
			},
			onOk: (data: ResponseBase) => {
				if (data.success) {
					SitePubSubManager.publish("tenant:tenant-renamed", data.success);
				}
				else {
					ErrorService.pushErrorMessage(failMessage);
				}

				return data;
			},
			onError: () => {
				ErrorService.pushErrorMessage(failMessage);
			}
		})
  	}

	private validateName(newName: string) {
		let invalidReason = "";
		if (newName.length === 0) {
			invalidReason = "Name cannot be blank";
		}
		this.freezer.get().set({
			invalidReason: invalidReason
		})
	}

	@bind
	private _clear() {
		this.freezer.get().set({
			newName: "",
			originalName: "",
			renameTenantResult: managedAjaxUtil.createInitialState(),
			tenantId: "",
			invalidReason: "",
		})
	}
}

export const TenantRenameService = new TenantRenameFreezerService();
export type ITenantRenameServiceInjectedProps = ReturnType<TenantRenameFreezerService["getPropsForInjection"]>;