  import {
    FreezerService,
    IAjaxState,
    managedAjaxUtil,
    _,
    bind
  } from "$Imports/Imports";
  
  import {
    TenantSummaryViewModelIEnumerableResponseBase,
    TenantApiFactory,
    TenantSuccessSummaryViewModelIEnumerableResponseBase,
    TenantMonthlySuccessSummaryViewModelIEnumerableResponseBase,
  } from "$Generated/api";
  
  import {
    SitePubSubManager
  } from "$Utilities/PubSubUtil";
  
  import {
    ErrorService
  } from "./ErrorFreezerService";
  
  const InjectedPropName = "tenantMonthlySummaryService";
  
  interface ITenantSummaryState {
    tenantResults: IAjaxState<TenantSummaryViewModelIEnumerableResponseBase>;
    tenantSummaryResults: IAjaxState<TenantSuccessSummaryViewModelIEnumerableResponseBase>;
    tenantMonthlySummaryResults: IAjaxState<TenantMonthlySuccessSummaryViewModelIEnumerableResponseBase>;
    tableFilter: string;
  
    /** If true, additionally display inactive tenants in the tenant summary */
    showInactiveTenants: boolean;
  }
  
  class MonthlyTenantSummaryFreezerService extends FreezerService<ITenantSummaryState, typeof InjectedPropName> {
    constructor() {
      super({
        tenantResults: managedAjaxUtil.createInitialState(),
        tenantSummaryResults: managedAjaxUtil.createInitialState(),
        tenantMonthlySummaryResults: managedAjaxUtil.createInitialState(),
        tableFilter:"",
        showInactiveTenants: false,
      }, InjectedPropName);
  
      SitePubSubManager.subscribe("application:login:before", this.clearResults);
      SitePubSubManager.subscribe("tenant:tenant-created", () => this.fetchTenants(true));
      SitePubSubManager.subscribe("tenant:tenant-deactivated", () => this.fetchTenants(true));
      SitePubSubManager.subscribe("tenant:tenant-activated", () => this.fetchTenants(true));
      SitePubSubManager.subscribe("tenant:tenant-renamed", () => this.fetchTenants(true));
    }

    //#region Show Inactive Tenants
  
    public setShowInactiveTenants(showInactive: boolean){
      this.freezer.get().set({
        showInactiveTenants: showInactive,
      })
    }
  
    //#endregion
  
    //#region Search filter
     
    public setTableFilter(search: string) {
      this.freezer.get().set({
        tableFilter: search
      });
    }
  
    //#endregion

    //#region Api Calls

    public async fetchTenants(forceUpdate: boolean = false) {
      const adapterSummaryState = this.freezer.get().tenantResults;
  
      if (adapterSummaryState.hasFetched && !forceUpdate) {
        return;
      }
  
      this.fetchTenantSummaries(true);
      this.fetchMonthlyTenantSummaries(true);

      return managedAjaxUtil.fetchResults({
        ajaxStateProperty: "tenantResults",
        freezer: this.freezer,
        onExecute: (apiOptions, params, options) => {
          const tenantFactory = TenantApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
          return tenantFactory.apiV1TenantSummaryGet({
            isActive: !this.freezer.get().showInactiveTenants,
          });
        },
        onError: () => {
          ErrorService.pushErrorMessage("Failed to retrieve tenant data from the server.");
        }
      });
    }
  
    public async fetchTenantSummaries(forceUpdate: boolean = false) {
      const adapterSummaryState = this.freezer.get().tenantSummaryResults;
  
      if (adapterSummaryState.hasFetched && !forceUpdate) {
        return;
      }
  
      return managedAjaxUtil.fetchResults({
        ajaxStateProperty: "tenantSummaryResults",
        freezer: this.freezer,
        onExecute: (apiOptions, params, options) => {
          const tenantFactory = TenantApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
          var summaries = tenantFactory.apiV1TenantSuccessSummaryGet({
            isActive: !this.freezer.get().showInactiveTenants,
          });
          return summaries;
        },
        onError: () => {
          ErrorService.pushErrorMessage("Failed to retrieve tenant success data from the server.");
        }
      });
    }
  
    public async fetchMonthlyTenantSummaries(forceUpdate: boolean = false) {
      const adapterSummaryState = this.freezer.get().tenantMonthlySummaryResults;
    
      if (adapterSummaryState.hasFetched && !forceUpdate) {
        return;
      }
    
      return managedAjaxUtil.fetchResults({
        ajaxStateProperty: "tenantMonthlySummaryResults",
        freezer: this.freezer,
        onExecute: (apiOptions, params, options) => {
          const tenantFactory = TenantApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
          var summaries = tenantFactory.apiV1TenantMonthlySuccessSummaryGet({
            isActive: !this.freezer.get().showInactiveTenants,
          });
          return summaries;
        },
        onError: () => {
          ErrorService.pushErrorMessage("Failed to retrieve tenant monthly success data from the server.");
        }
      });
    }

    @bind
      private clearResults() {
        this.freezer.get().set({
          tenantResults: managedAjaxUtil.createInitialState(),
          tenantSummaryResults: managedAjaxUtil.createInitialState(),
          tenantMonthlySummaryResults: managedAjaxUtil.createInitialState(),
          tableFilter: "",
        });
      }
  }
 
  //#endregion

  export const MonthlyTenantSummaryService = new MonthlyTenantSummaryFreezerService();
  export type IMonthlyTenantSummaryServiceInjectedProps = ReturnType<MonthlyTenantSummaryFreezerService["getPropsForInjection"]>;