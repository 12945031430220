import {
	bind,
	FreezerService,
	IAjaxState,
	managedAjaxUtil
} from "$Imports/Imports";

import {
	ResponseBase,
	TenantApiFactory,
	TenantViewModel,
	TenantViewModelResponseBase,
} from "$Generated/api";

import {
	SitePubSubManager
} from "$Utilities/PubSubUtil";

import {
	ErrorService
} from "../ErrorFreezerService";

const InjectedPropName = "advancedSettingsService";

interface IAdvancedSettingsState {
	dataPopulated: boolean;
	tenant: TenantViewModel | null;
	tenantFetchResult: IAjaxState<TenantViewModelResponseBase>;
	tenantSaveResult: IAjaxState<ResponseBase>;
}

class AdvancedSettingsFreezerService extends FreezerService<IAdvancedSettingsState, typeof InjectedPropName> {

	constructor() {
		super({
			dataPopulated: false,
			tenant: null,
			tenantFetchResult: managedAjaxUtil.createInitialState(),
			tenantSaveResult: managedAjaxUtil.createInitialState(),
		}, InjectedPropName);

		SitePubSubManager.subscribe("application:login:before", this.resetFreezer);
	}

	public openAdvancedSettingsDialog(tenantId: string) {
		this.fetchTenantbyId(tenantId);
	}

	public getTenantId(): string {
		return this.freezer.get().tenant?.tenantId || "";
	}

	public isTenantFetched(): boolean {
		return this.freezer.get().dataPopulated;
	}

	public updateSettings(partialData: Partial<TenantViewModel>) {
		this.freezer.get().tenant?.set(partialData);
	};

	private async fetchTenantbyId(tenantId: string): Promise<void> {
		await managedAjaxUtil.fetchResults({
			freezer: this.freezer,
			ajaxStateProperty: "tenantFetchResult",
			onExecute: (apiOptions, params, options) => {
				const tenantApiFactory = TenantApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
				return tenantApiFactory.apiV1TenantIdGet({ id: tenantId });
			},
			onOk: (response: TenantViewModelResponseBase) => {
				if (response.success && response.data) {
					this.freezer.get().set({
						tenant: response.data,
						dataPopulated: true
					});
				}
			},
			onError: () => {
				ErrorService.pushErrorMessage("Failed to retrieve tenant data from the server.");
			}
		});
	}

	@bind
	public async saveUpdatedTenant() {
		const failMessage = "Failed to update tenant";
		await managedAjaxUtil.fetchResults({
			freezer: this.freezer,
			ajaxStateProperty: "tenantSaveResult",
			onExecute: (apiOptions, params, options) => {
				const updatedTenant = this.freezer.get().tenant?.toJS();
				const tenantApiFactory = TenantApiFactory(apiOptions.wrappedFetch, apiOptions.baseUrl);
				return tenantApiFactory.apiV1TenantAdvancedSettingsSavePost({ body: updatedTenant });
			},
			onOk: (data: ResponseBase) => {
				if (data.success) {
					SitePubSubManager.publish("tenant:advanced-settings-saved", data.success);
					this.resetFreezer();
				}
				else {
					ErrorService.pushErrorMessage(failMessage);
				}
			},
			onError: () => {
				ErrorService.pushErrorMessage(failMessage);
			}
		});
	}

	@bind
	public resetFreezer() {
		//this.freezer.get().reset;
		this.freezer.get().set({
			dataPopulated: false,
			tenantFetchResult: managedAjaxUtil.createInitialState(),
			tenantSaveResult: managedAjaxUtil.createInitialState()
		});

	}
}

export const AdvancedSettingsService = new AdvancedSettingsFreezerService();
export type IAdvancedSettingsServiceInjectedProps = ReturnType<AdvancedSettingsFreezerService["getPropsForInjection"]>;