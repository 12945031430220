import {
  React,
  bind,
  _,
  numeral
} from "$Imports/Imports";

import {
  ISearchFilter,
} from "$State/VehicleSearchFreezerService";

import {
  TenantViewModel,
  Assignment
} from "$Generated/api";

import {
  Filter
} from "./Filter";

import {
  AssignmentGrid
} from "./AssignmentGrid";

import {
  Divider,
  Snackbar,
  IconButton,
} from "$Imports/MaterialUIComponents";

import {
  IPagerState
} from "$State/PagerPagingState";

import {
  ISortState
} from "$State/SortState";

import {
  DataTablePager,
  timeZoneType,
  DateFormatter
} from "$Components/Common";

import {
  ArrowDownward
} from "$Imports/MaterialUIIcons";

import {
  ITimeZoneServiceInjectedProps,
  TimeZoneService
} from "$State/TimeZoneFreezerService";

import {
  ApplicationSecurityConsumer
} from "$Providers/AuthenticationProvider";

const styles: {
  paperStyle: string;
  searchPaperStyle: string;
  headerGroup: string;
} = require("./AssignmentRecordsSearch.scss");

interface IAssignmentRecordsSearchBaseProps {
  filter: ISearchFilter;
  assignmentRecordsData: Assignment[];
  tenantSummaryData: TenantViewModel[];
  pagerState: IPagerState;
  sortState: ISortState;
  onFilterChange?: (filter: ISearchFilter, reload: boolean) => void;
  onPagerStateChange?: (pagerState: IPagerState) => void;
  onSortChange?: (sortState: ISortState) => void;
  onRunSearch?: () => void;
  errorState: boolean;
  hasSearched: boolean;
}

type IAssignmentRecordsSearchProps = IAssignmentRecordsSearchBaseProps & ITimeZoneServiceInjectedProps;

export class _AssignmentRecordsSearch extends React.PureComponent<IAssignmentRecordsSearchProps> {

  @bind
  private _onFilterChange(filter: ISearchFilter, reload: boolean): void {
    if (this.props.onFilterChange) {
      this.props.onFilterChange(filter, reload);
    }
  }

  @bind
  private _onRunSearch() {
    if (this.props.onRunSearch) {
      this.props.onRunSearch();
    }
  }

  @bind
  private _onPagerStateChange(pagerState: IPagerState) {
    if (this.props.onPagerStateChange) {
      this.props.onPagerStateChange(pagerState);
    }
  }

  @bind
  private _onPagerStateChangePager(page: number, rowsPerPage: number) {
    this._onPagerStateChange({
      page,
      rowsPerPage,
    });
  }

  @bind
  private _onSortChange(sortState: ISortState) {
    if (this.props.onSortChange) {
      this.props.onSortChange(sortState);
    }
  }

  @bind
  private _onChangePage() {
    // Do nothing.  Handled by the component.  Remove once typescript has been updated.
  }

  @bind
  private saveCSV() {
    const { assignmentRecordsData } = this.props;
    var timeZone: timeZoneType = this.props.timeZoneService.getTimeZone() || "UTC";
    var isMilitaryTime: boolean = this.props.timeZoneService.getisMilitaryTime() || false;

    let csvData = "First Name, Last Name, Driver Id, Vehicle Id, Start Time, End Time, Tenant Id\n";

    assignmentRecordsData.forEach(row => {
      csvData +=
        row.driverFirstname + "," +
        row.driverLastname + "," +
        row.driverId + "," +
        row.vehicleId + "," +
        (row.startTime ? DateFormatter.formatDateString(row.startTime, timeZone, "", isMilitaryTime) : "") + "," +
        (row.endTime ? DateFormatter.formatDateString(row.endTime, timeZone, "", isMilitaryTime) : "") + "," +
        row.tenantId + "\n";
    })

    let saveDocument = document.createElement('a');
    saveDocument.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvData);
    saveDocument.target = '_blank';
    saveDocument.download = 'assignmentRecordSearchResults.csv';
    saveDocument.click();
  }

  render() {
    const {
      filter,
      assignmentRecordsData,
      tenantSummaryData,
      sortState,
      pagerState,
      hasSearched
    } = this.props;

    return (
      <>
        <div
          className={styles.searchPaperStyle}
        >
          <Filter
            filter={filter}
            onFilterChanged={this._onFilterChange}
            filterStatusLabel={`${numeral(assignmentRecordsData.length).format("0,000")} ${assignmentRecordsData.length === 1 ? "Assignment Record" : "Assignment Records"}`}
            tenantSummaryData={tenantSummaryData}
            onRunSearch={this._onRunSearch}
            errorState={this.props.errorState}
          />
          <div
            className={styles.headerGroup}
          >
            <IconButton
              color="primary"
              title="Download"
              onClick={this.saveCSV}
            >
              <ArrowDownward />
            </IconButton>
            <DataTablePager
              onChangePage={this._onChangePage}
              count={assignmentRecordsData.length}
              page={pagerState.page}
              rowsPerPage={pagerState.rowsPerPage}
              onPagerStateChange={this._onPagerStateChangePager}
            />
          </div>
        </div>
        <Divider />
        <ApplicationSecurityConsumer>
          {(securityContext) => {
            return (
              <AssignmentGrid
                securityContext={securityContext}
                data={assignmentRecordsData}
                sortState={sortState}
                pagerState={pagerState}
                onPagerStateChange={this._onPagerStateChange}
                onSortChange={this._onSortChange}
              />
            )
          }}
        </ApplicationSecurityConsumer>
        <Snackbar
          open={hasSearched && assignmentRecordsData.length === 0}
          message={"No data found"}
          autoHideDuration={5000}
          anchorOrigin={{
            horizontal: "center",
            vertical: "bottom"
          }}
        />
      </>
    );
  }
}

export const AssignmentRecordsSearch = TimeZoneService.inject(
  _AssignmentRecordsSearch
);
