import {
    React,
    _,
    bind
} from "$Imports/Imports";
import { Button, FormControlLabel, Checkbox } from "$Imports/MaterialUIComponents";
import { SingleLytxCredentials } from "$Generated/api";
import { AdvanceTextField } from "$Components/Common";

interface ISingleLytxBaseProps {
    data: SingleLytxCredentials;
    onChange: (index: number, data: Partial<SingleLytxCredentials>) => void;
    delete: (index: number) => void;
    index: number;
}

export class SingleLytx extends React.PureComponent<ISingleLytxBaseProps> {

    @bind
    private deleteClick(): void
    {
        this.props.delete(this.props.index);
    }

    @bind
    private onCheck(e: React.ChangeEvent<HTMLInputElement>, checked: boolean): void {
        if (e.target.name === "validateOnly") {
            this.props.onChange(this.props.index, { validateOnly: checked });
        }
        if (e.target.name === "autoAddDriver") {
            this.props.onChange(this.props.index, { autoAddDriver: checked });
        }
        if (e.target.name === "useRestApi") {
            this.props.onChange(this.props.index, { useRestApi: checked });
        }
    }

    @bind
    private onDebouncedChangeEndpointId(newValue: string): void {
        this.props.onChange(this.props.index, { endpointId: newValue });
    }

    @bind
    private onDebouncedChangeEndpoint(newValue: string): void {
        this.props.onChange(this.props.index, { submissionEndPoint: newValue });
    }

    @bind
    private onDebouncedChangeUserName(newValue: string): void {
        this.props.onChange(this.props.index, { username: newValue });
    }

    @bind
    private onDebouncedChangePassword(newValue: string): void {
        this.props.onChange(this.props.index, { password: newValue });
    }

    render(): JSX.Element {
        const lytx: SingleLytxCredentials = (this.props.data) ? this.props.data : {} as SingleLytxCredentials;
        return (
            <div style={{border: "2px"}}>
                <AdvanceTextField
                    label="End Point Id*"
                    name="endpointId"
                    onDebouncedChange={this.onDebouncedChangeEndpointId}
                    value={lytx.endpointId ? lytx.endpointId : ""}
                    error={(lytx.endpointId === "")}
                /><br />
                <AdvanceTextField
                    label="End Point*"
                    name="endPoint"
                    onDebouncedChange={this.onDebouncedChangeEndpoint}
                    value={lytx.submissionEndPoint ? lytx.submissionEndPoint : ""}
                    error={(lytx.submissionEndPoint === "")}
                /><br />
                <AdvanceTextField
                    label="User Name*"
                    name="username"
                    onDebouncedChange={this.onDebouncedChangeUserName}
                    value={lytx.username ? lytx.username : ""}
                    error={(lytx.username === "")}
                /><br />
                <AdvanceTextField
                    label="Password*"
                    name="password"
                    type="password"
                    onDebouncedChange={this.onDebouncedChangePassword}
                    value={lytx.password ? lytx.password : ""}
                    error={(lytx.password === "")}

                />
                <br />
                <FormControlLabel
                    control={<Checkbox
                        name="validateOnly"
                        checked={lytx.validateOnly}
                        onChange={this.onCheck}
                    />}
                    label="Validate Only"
                /><br />
                                <FormControlLabel
                    control={<Checkbox
                        name="useRestApi"
                        checked={lytx.useRestApi}
                        onChange={this.onCheck}
                    />}
                    label="Rest API Enabled"
                /><br />
                <br />
                <FormControlLabel
                    control={<Checkbox
                        name="autoAddDriver"
                        checked={lytx.autoAddDriver}
                        onChange={this.onCheck}
                    />}
                    label="Auto Add Driver"
                /><br />
                <Button
                    className="float-right"
                    disabled={true}
                    color="primary"
                >
                    VALIDATE CREDENTIALS
                </Button>{/* Disabled for as per LDM - 481 */}
                <Button
                    className="float-left"
                    color="primary"
                    onClick={this.deleteClick}
                >
                    DELETE
                </Button>{/* Disabled for as per LDM - 481 */}
            </div>
        );
    }

}