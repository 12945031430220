/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import * as querystring from "querystring";
import * as url from "url";

const isomorphicFetch = fetch;
const assign: (target: any, ...sources: any[]) => any = (Object as any).assign;

interface Dictionary<T> { [index: string]: T; }
export interface FetchAPI { (url: string, init?: any): Promise<any>; }

const BASE_PATH = "https://localhost".replace(/\/+$/, "");

export interface FetchArgs {
    url: string;
    options: any;
}

export class BaseAPI {
    basePath: string;
    fetch: FetchAPI;

    constructor(fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) {
        this.basePath = basePath;
        this.fetch = fetch;
    }
};

export interface Adapter {
    "jobId"?: string;
    "id"?: string;
    "friendlyName"?: string;
}

export interface AdapterSummaryModel {
    "id"?: number;
    "sourceProviders"?: Array<SourceProviderModel>;
    "tenantCount"?: number;
}

export interface AdapterSummaryModelIEnumerableResponseBase {
    "data"?: Array<AdapterSummaryModel>;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface AdapterViewModel {
    "id"?: string;
    "friendlyName"?: string;
}

export interface AdminFtpCredentials {
    "transferServiceType"?: string;
    "portNumber"?: number;
    "ftpAddress"?: string;
    "ftpUsername"?: string;
    "ftpPassword"?: string;
}

export interface AdminFtpCredentialsResponseBase {
    "data"?: AdminFtpCredentials;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface Asset {
    "storageArea"?: AssetStorageAreaEnum;
    "storageKey"?: string;
    "template"?: string;
}

export type AssetStorageAreaEnum = "Configuration" | "Workflow" | "Data" | "State" | "ConfigurationNonSecure";
export interface Assignment {
    "id"?: number;
    "tenantId"?: string;
    "jobId"?: string;
    "driverId"?: string;
    "driverFirstname"?: string;
    "driverLastname"?: string;
    "vehicleId"?: string;
    "vehicleName"?: string;
    "startTime"?: Date;
    "endTime"?: Date;
    "latitude"?: number;
    "longitude"?: number;
}

export interface AssignmentIEnumerableResponseBase {
    "data"?: Array<Assignment>;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface AssociatedEntity {
    "id"?: number;
    "entityId"?: string;
    "entityType"?: EntityType;
    "categoryType"?: CategoryType;
    "categoryItem"?: CategoryItem;
    "adHocValue"?: string;
}

export interface AssociatedEntityViewModel {
    "id"?: number;
    "entityId"?: string;
    "entityType"?: EntityType;
    "categoryType"?: CategoryType;
    "categoryItem"?: CategoryItem;
    "adHocValue"?: string;
}

export interface AssociatedEntityViewModelIEnumerablePagedResponseBase {
    "currentPage"?: number;
    "itemsPerPage"?: number;
    "totalPages"?: number;
    "data"?: Array<AssociatedEntityViewModel>;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface AutocompleteViewModel {
    "text"?: string;
    "value"?: string;
}

export interface AutocompleteViewModelIEnumerableResponseBase {
    "data"?: Array<AutocompleteViewModel>;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface BigChangeCredentials {
    "username"?: string;
    "password"?: string;
    "apiKey"?: string;
    "baseUrl"?: string;
}

export interface BigChangeCredentialsResponseBase {
    "data"?: BigChangeCredentials;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface BigRoadCredentials {
    "apiKey"?: string;
    "useTruckId"?: boolean;
}

export interface BigRoadCredentialsResponseBase {
    "data"?: BigRoadCredentials;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface BlueTreeCredentials {
    "username"?: string;
    "password"?: string;
}

export interface BlueTreeCredentialsResponseBase {
    "data"?: BlueTreeCredentials;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface CADECCredentials {
    "clientId"?: string;
}

export interface CADECCredentialsResponseBase {
    "data"?: CADECCredentials;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface CategoryItem {
    "id"?: number;
    "categoryType"?: CategoryType;
    "value"?: string;
}

export interface CategoryItemIEnumerableResponseBase {
    "data"?: Array<CategoryItem>;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface CategoryType {
    "id"?: number;
    "name"?: string;
    "isConstrained"?: boolean;
}

export interface CategoryTypeIEnumerableResponseBase {
    "data"?: Array<CategoryType>;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface ChildJobViewModel {
    "endDate"?: Date;
    "startDate"?: Date;
    "relatedJobId"?: string;
    "jobId"?: string;
    "jobStatus"?: ChildJobViewModelJobStatusEnum;
}

export type ChildJobViewModelJobStatusEnum = "Complete" | "Error" | "Processing" | "Resubmit";
export interface Client {
    "id"?: number;
    "friendlyName"?: string;
}

export interface CoretexCredentials {
    "username"?: string;
    "password"?: string;
    "apiKey"?: string;
}

export interface CoretexCredentialsResponseBase {
    "data"?: CoretexCredentials;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface DataLink {
    "jobId"?: string;
    "name"?: string;
    "timestamp"?: Date;
    "value"?: string;
    "messageId"?: string;
}

export interface DateTimeDateTimeTuple {
    "item1"?: Date;
    "item2"?: Date;
}

export interface DateTimeDateTimeTupleResponseBase {
    "data"?: DateTimeDateTimeTuple;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

/**
 * Summary of a single day for the generic report (see ReportController)
 */
export interface DaySummary {
    /**
     * If this summary is a total column
     */
    "isTotal"?: boolean;
    /**
     * Date of this summary
     */
    "date"?: Date;
    /**
     * Number of hours on the job
     */
    "hoursOnJob"?: number;
    /**
     * Number of hours in the office
     */
    "officeHours"?: number;
    /**
     * Total drive time from home to work
     */
    "driveTime"?: number;
    /**
     * Home departure time
     */
    "homeDeparture"?: Date;
    /**
     * Home arrival time
     */
    "homeArrival"?: Date;
    /**
     * Job arrival time
     */
    "jobArrival"?: Date;
    /**
     * Job departure time
     */
    "jobDeparture"?: Date;
    /**
     * Idle time total
     */
    "idleTime"?: number;
    /**
     * Total milage in the day
     */
    "totalMileage"?: number;
    /**
     * Total time parked at a non-geofenced location
     */
    "parkedTime"?: number;
}

export interface DelinquentTenantViewModel {
    "tenantName"?: string;
    "adapter"?: string;
    "lastSuccessDate"?: Date;
    "workflowInstanceId"?: string;
    "tenantId"?: string;
}

export interface DelinquentTenantViewModelIEnumerableResponseBase {
    "data"?: Array<DelinquentTenantViewModel>;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface DigitalFleetCredentials {
    "apiKey"?: string;
    "driverIdKey"?: string;
}

export interface DigitalFleetCredentialsResponseBase {
    "data"?: DigitalFleetCredentials;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface DriveCamSubmissionResultViewModel {
    "hasErrors"?: boolean;
    "successCount"?: number;
    "totalCount"?: number;
    "errorDetails"?: Array<SubmissionErrorDetailViewModel>;
    "finishedTime"?: Date;
    "status"?: DriveCamSubmissionResultViewModelStatusEnum;
}

export type DriveCamSubmissionResultViewModelStatusEnum = "NotSubmitted" | "Pending" | "Processing" | "Complete" | "Failed";
export interface DriveCamSubmissionResultViewModelResponseBase {
    "data"?: DriveCamSubmissionResultViewModel;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface DriverTechCredentials {
    "username"?: string;
    "password"?: string;
    "datastoreName"?: string;
    "companyCode"?: string;
}

export interface DriverTechCredentialsResponseBase {
    "data"?: DriverTechCredentials;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface DrivingFaultEvent {
    "vehicleId"?: string;
    "vehicleName"?: string;
    "vin"?: string;
    "driverId"?: string;
    "driverFirstname"?: string;
    "driverLastname"?: string;
    "eventDateTime"?: Date;
    "faultCode"?: string;
    "failureMode"?: string;
    "occuranceCount"?: number;
    "data"?: { [key: string]: any; };
}

export interface DrivingFaultEventIEnumerableResponseBase {
    "data"?: Array<DrivingFaultEvent>;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface DrivingStatusEvent {
    "driverId"?: string;
    "driverFirstName"?: string;
    "driverLastName"?: string;
    "vehicleId"?: string;
    "vehicleName"?: string;
    "vin"?: string;
    "latitude"?: number;
    "longitude"?: number;
    "eventDateTime"?: Date;
    "data"?: { [key: string]: any; };
}

export interface DrivingStatusEventIEnumerableResponseBase {
    "data"?: Array<DrivingStatusEvent>;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface DutyStatusActivity {
    "id"?: number;
    "tenantId"?: string;
    "jobId"?: string;
    "driverId"?: string;
    "driverFirstname"?: string;
    "driverLastname"?: string;
    "vehicleId"?: string;
    "vehicleName"?: string;
    "eventDate"?: Date;
    "activityTimestamp"?: Date;
    "dutyStatus"?: DutyStatusActivityDutyStatusEnum;
    "latitude"?: number;
    "longitude"?: number;
}

export type DutyStatusActivityDutyStatusEnum = "Unknown" | "LogOff" | "OffDuty" | "Sleeper" | "LogIn" | "OnDuty" | "OffDutyDriving" | "Driving";
export interface DutyStatusActivityIEnumerableResponseBase {
    "data"?: Array<DutyStatusActivity>;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface ERoadsCredentials {
    "apiKey"?: string;
}

export interface ERoadsCredentialsResponseBase {
    "data"?: ERoadsCredentials;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface EmailCSVCredentials {
    "messageHeaderIdentificationPrefix"?: string;
    "graphClientId"?: string;
    "graphTenantId"?: string;
    "graphUsername"?: string;
}

export interface EmailCSVCredentialsResponseBase {
    "data"?: EmailCSVCredentials;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface EntityType {
    "id"?: number;
    "name"?: string;
}

export interface EntityTypeIEnumerableResponseBase {
    "data"?: Array<EntityType>;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface ErrorDetails {
    "action"?: string;
    "errorType"?: string;
    "employeeId"?: string;
    "lastName"?: string;
    "firstName"?: string;
    "error"?: string;
}

export interface FileDownload {
    "data"?: string;
    "fileName"?: string;
}

export interface FileDownloadResponseBase {
    "data"?: FileDownload;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface GeotabCredentials {
    "sessionId"?: string;
    "database"?: string;
    "password"?: string;
    "server"?: string;
    "userName"?: string;
    "serviceOptions"?: Array<string>;
}

export interface GeotabCredentialsResponseBase {
    "data"?: GeotabCredentials;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface ISAACInstrumentsAuthentication {
    "username"?: string;
    "password"?: string;
    "grantType"?: string;
}

export interface ISAACInstrumentsCredentials {
    "authentication"?: ISAACInstrumentsAuthentication;
    "basicToken"?: string;
    "hostUrl"?: string;
}

export interface ISAACInstrumentsCredentialsResponseBase {
    "data"?: ISAACInstrumentsCredentials;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface JJKellerCredentials {
    "userName"?: string;
    "password"?: string;
}

export interface JJKellerCredentialsResponseBase {
    "data"?: JJKellerCredentials;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface JobStep {
    "jobId"?: string;
    "stepNumber"?: number;
    "success"?: boolean;
    "friendlyName"?: string;
    "message"?: string;
    "resultStatus"?: string;
    "timestamp"?: Date;
    "messageId"?: string;
}

export interface JobSummaryViewModel {
    "adapters"?: Array<AdapterViewModel>;
    "endDate"?: Date;
    "itemsSubmitted"?: number;
    "itemsFailed"?: number;
    "itemsSuccessful"?: number;
    "jobId"?: string;
    "startDate"?: Date;
    "tenantFriendlyName"?: string;
    "tenantId"?: string;
    "jobStatus"?: JobSummaryViewModelJobStatusEnum;
    "relatedJobId"?: string;
}

export type JobSummaryViewModelJobStatusEnum = "Complete" | "Error" | "Processing" | "Resubmit";
export interface JobSummaryViewModelIEnumerableResponseBase {
    "data"?: Array<JobSummaryViewModel>;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface JobViewModel {
    "initialData"?: DataLink;
    "finalData"?: DataLink;
    "canResubmit"?: boolean;
    "childJobs"?: Array<ChildJobViewModel>;
    "id"?: string;
    "jobId"?: string;
    "relatedJobId"?: string;
    "startDate"?: Date;
    "endDate"?: Date;
    "messageId"?: string;
    "itemsSubmitted"?: number;
    "itemsSuccessful"?: number;
    "itemsFailed"?: number;
    "jobStatus"?: JobViewModelJobStatusEnum;
    "statusMessage"?: string;
    "clientId"?: number;
    "tenantId"?: string;
    "adapters"?: Array<Adapter>;
    "links"?: Array<DataLink>;
    "steps"?: Array<JobStep>;
    "messages"?: Array<MessageInfo>;
    "metaData"?: { [key: string]: string; };
    "client"?: Client;
    "tenant"?: Tenant;
}

export type JobViewModelJobStatusEnum = "Complete" | "Error" | "Processing" | "Resubmit";
export interface JobViewModelIEnumerableResponseBase {
    "data"?: Array<JobViewModel>;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface JobViewModelIEnumerableResponseBaseError {
    "message"?: string;
    "source"?: string;
    "stackTrace"?: string;
}

export interface JobViewModelResponseBase {
    "data"?: JobViewModel;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface KeepTruckinCredentials {
    "apiKey"?: string;
    "url"?: string;
    "companyId"?: string;
    "sharedSecret"?: string;
}

export interface KeepTruckinCredentialsResponseBase {
    "data"?: KeepTruckinCredentials;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface LoadTrekCredentials {
    "username"?: string;
    "password"?: string;
    "company"?: string;
    "driverGroup"?: string;
}

export interface LoadTrekCredentialsResponseBase {
    "data"?: LoadTrekCredentials;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface LoginViewModel {
    "username": string;
    "password": string;
}

export interface LytxAdminReportView {
    "actionMetadata"?: Array<StringInt32KeyValuePair>;
    "errors"?: Array<ErrorDetails>;
    "createdOn"?: string;
}

export interface LytxAdminReportViewListResponseBase {
    "data"?: Array<LytxAdminReportView>;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface LytxContacts {
    "errorReportEmailRecipients"?: Array<string>;
}

export interface LytxContactsResponseBase {
    "data"?: LytxContacts;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface LytxCredentials {
    "credentials"?: Array<SingleLytxCredentials>;
}

export interface LytxCredentialsResponseBase {
    "data"?: LytxCredentials;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface LytxEmailRecipients {
    "recipients"?: Array<string>;
}

export interface LytxEmailRecipientsResponseBase {
    "data"?: LytxEmailRecipients;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface MessageInfo {
    "jobId"?: string;
    "name"?: string;
    "timestamp"?: Date;
    "content"?: string;
    "messageId"?: string;
}

export interface NetradyneCredentials {
    "ftpAddress"?: string;
    "basePath"?: string;
    "ftpUsername"?: string;
    "ftpPassword"?: string;
}

export interface NetradyneCredentialsResponseBase {
    "data"?: NetradyneCredentials;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface NewTenantViewModel {
    "clientId"?: string;
    "tenantName"?: string;
    "sourceProviderId"?: number;
    "submissionProviderId"?: number;
    "workflowDefinitionTemplateName"?: string;
    "frequency"?: NewTenantViewModelFrequencyEnum;
    "sourceConfiguration"?: any;
    "submissionConfiguration"?: any;
    "contactEmails"?: Array<string>;
}

export type NewTenantViewModelFrequencyEnum = "EveryMinute" | "EveryFiveMinutes" | "EveryQuarterHour" | "EveryHalfHour" | "EveryHour" | "EveryTwoHours" | "EveryThreeHours" | "EverySixHours" | "EveryTwelveHours" | "EveryDay";
export interface OmniOneCredentials {
    "username"?: string;
    "password"?: string;
}

export interface OmniOneCredentialsResponseBase {
    "data"?: OmniOneCredentials;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface OmnitracsAuthLogsCredentials {
    "authCompany"?: string;
    "authPassword"?: string;
    "authUsername"?: string;
}

export interface OmnitracsAuthLogsCredentialsResponseBase {
    "data"?: OmnitracsAuthLogsCredentials;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface OmnitracsCredentials {
    "username"?: string;
    "password"?: string;
    "company"?: string;
    "depotsEndpoint"?: string;
    "driversEndpoint"?: string;
}

export interface OmnitracsCredentialsResponseBase {
    "data"?: OmnitracsCredentials;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface OmnitracsXRSCredentials {
    "url"?: string;
    "username"?: string;
    "password"?: string;
    "companyId"?: string;
}

export interface OmnitracsXRSCredentialsResponseBase {
    "data"?: OmnitracsXRSCredentials;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface OrganizationalUnitViewModel {
    "organizationalUnitUid"?: string;
    "friendlyName"?: string;
}

export interface OrganizationalUnitViewModelListResponseBase {
    "data"?: Array<OrganizationalUnitViewModel>;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface PedigreeCredentials {
    "username"?: string;
    "password"?: string;
}

export interface PedigreeCredentialsResponseBase {
    "data"?: PedigreeCredentials;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface PeoplenetCredentials {
    "CID"?: string;
    "password"?: string;
}

export interface PeoplenetCredentialsResponseBase {
    "data"?: PeoplenetCredentials;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface PlatformScienceCredentials {
    "baseUrl"?: string;
    "bearerToken"?: string;
    "carrierIdFilter"?: string;
}

export interface PlatformScienceCredentialsResponseBase {
    "data"?: PlatformScienceCredentials;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface ProcessControlMessage {
    "messageId"?: string;
    "queueName"?: string;
    "processId"?: string;
    "tenantId"?: string;
    "targetDataKey"?: string;
    "priority"?: number;
    "processData"?: { [key: string]: string; };
}

export interface RawDataViewModel {
    "id"?: string;
    "logId"?: string;
    "driverId"?: string;
    "vehicleId"?: string;
    "dutyStatus"?: string;
    "timestamp"?: Date;
    "originalLogId"?: string;
    "timezone"?: string;
}

export interface RawDataViewModelIEnumerablePagedResponseBase {
    "currentPage"?: number;
    "itemsPerPage"?: number;
    "totalPages"?: number;
    "data"?: Array<RawDataViewModel>;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface ResponseBase {
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface RevealCredentials {
    "appId"?: string;
    "password"?: string;
    "url"?: string;
    "username"?: string;
}

export interface RevealCredentialsResponseBase {
    "data"?: RevealCredentials;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface RoadnetMobileCredentials {
    "username"?: string;
    "password"?: string;
    "subscriberId"?: string;
}

export interface RoadnetMobileCredentialsResponseBase {
    "data"?: RoadnetMobileCredentials;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface RoleViewModel {
    "roleName"?: string;
}

export interface RoleViewModelListResponseBase {
    "data"?: Array<RoleViewModel>;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface SFTPCredentials {
    "ftpAddress"?: string;
    "ftpUsername"?: string;
    "ftpPassword"?: string;
}

export interface SFTPCredentialsResponseBase {
    "data"?: SFTPCredentials;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface SamsaraCredentials {
    "url"?: string;
    "accessToken"?: string;
}

export interface SamsaraCredentialsResponseBase {
    "data"?: SamsaraCredentials;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface SamsaraHosExportError {
    "driverId"?: string;
    "vehicleId"?: string;
    "error"?: string;
}

export interface SamsaraHosExportSubmissionResult {
    "exportErrors"?: Array<SamsaraHosExportError>;
    "exportResultDateTime"?: Date;
}

export interface SamsaraHosExportSubmissionResultListResponseBase {
    "data"?: Array<SamsaraHosExportSubmissionResult>;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface ShawCredentials {
    "username"?: string;
    "password"?: string;
    "companyId"?: string;
}

export interface ShawCredentialsResponseBase {
    "data"?: ShawCredentials;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface SingleLytxCredentials {
    "submissionEndPoint"?: string;
    "username"?: string;
    "password"?: string;
    "validateOnly"?: boolean;
    "autoAddDriver"?: boolean;
    "endpointId"?: string;
    "useRestApi"?: boolean;
}

export interface SmartDriveCredentials {
    "bearer"?: string;
    "siteName"?: string;
    "url"?: string;
}

export interface SmartDriveCredentialsResponseBase {
    "data"?: SmartDriveCredentials;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface SmartDriveExportError {
    "employeeId"?: string;
    "vehicleSN"?: string;
    "startDateTime"?: Date;
    "endDateTime"?: Date;
    "responseCode"?: string;
    "responseMessage"?: string;
}

export interface SmartDriveExportResult {
    "exportErrors"?: Array<SmartDriveExportError>;
    "exportResultDateTime"?: Date;
}

export interface SmartDriveExportResultListResponseBase {
    "data"?: Array<SmartDriveExportResult>;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface SourceProviderModel {
    "id"?: number;
    "friendlyName"?: string;
}

export interface StringDaySummaryListDictionaryResponseBase {
    "data"?: { [key: string]: Array<DaySummary>; };
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface StringIEnumerableResponseBase {
    "data"?: Array<string>;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface StringInt32KeyValuePair {
    "key"?: string;
    "value"?: number;
}

export interface StringListResponseBase {
    "data"?: Array<string>;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface StringResponseBase {
    "data"?: string;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface StringStringDictionaryResponseBase {
    "data"?: { [key: string]: string; };
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface StringWorkflowDefinitionTemplateViewModelIDictionaryResponseBase {
    "data"?: { [key: string]: WorkflowDefinitionTemplateViewModel; };
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface SubmissionErrorDetailViewModel {
    "firstName"?: string;
    "lastName"?: string;
    "driverId"?: string;
    "vehicleId"?: string;
    "startDate"?: Date;
    "endDate"?: Date;
    "responseCode"?: string;
    "description"?: string;
    "endpointId"?: string;
    "submissionId"?: number;
    "additionalInformation"?: { [key: string]: string; };
}

export interface TeletracCredentials {
    "username"?: string;
    "password"?: string;
    "tenantId"?: string;
    "accountId"?: string;
}

export interface TeletracCredentialsResponseBase {
    "data"?: TeletracCredentials;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface TelogisCredentials {
    "userName"?: string;
    "password"?: string;
    "companyID"?: string;
}

export interface TelogisCredentialsResponseBase {
    "data"?: TelogisCredentials;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface Tenant {
    "id"?: string;
    "tenantId"?: string;
    "friendlyName"?: string;
}

export interface TenantConfig {
    "tenantUid"?: string;
    "tenantId"?: string;
    "friendlyName"?: string;
    "imageName"?: string;
    "createdDate"?: string;
    "crontabSchedule"?: string;
    "status"?: TenantConfigStatusEnum;
    "active"?: boolean;
    "instances"?: Array<WorkflowInstanceConfig>;
    "customAttributes"?: { [key: string]: string; };
}

export type TenantConfigStatusEnum = "Disabled" | "Unconfigured" | "Enabled";
export interface TenantMonthlySuccessSummaryViewModel {
    "tenantId"?: string;
    "tenantFriendlyName"?: string;
    "adapters"?: Array<AdapterViewModel>;
    "submissionSuccessRate"?: number;
    "jobSuccessRate"?: number;
}

export interface TenantMonthlySuccessSummaryViewModelIEnumerableResponseBase {
    "data"?: Array<TenantMonthlySuccessSummaryViewModel>;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface TenantSuccessSummaryViewModel {
    "tenantId"?: string;
    "submissionSuccessRate"?: number;
    "jobSuccessRate"?: number;
}

export interface TenantSuccessSummaryViewModelIEnumerableResponseBase {
    "data"?: Array<TenantSuccessSummaryViewModel>;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface TenantSummaryViewModel {
    "tenantId"?: string;
    "tenantFriendlyName"?: string;
    "workflowInstanceId"?: string;
    "adapters"?: Array<AdapterViewModel>;
    "clientOrgUnitId"?: string;
    "clientOrgName"?: string;
    "submissionProviderId"?: string;
    "assignmentOption"?: string;
    "active"?: boolean;
    "status"?: TenantSummaryViewModelStatusEnum;
}

export type TenantSummaryViewModelStatusEnum = "Disabled" | "Unconfigured" | "Enabled";
export interface TenantSummaryViewModelIEnumerableResponseBase {
    "data"?: Array<TenantSummaryViewModel>;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface TenantTokenExpirationDateViewModel {
    "tenantId"?: string;
    "expirationDate"?: Date;
    "providerType"?: number;
    "scalarValue"?: number;
    "siteName"?: string;
}

export interface TenantTokenExpirationDateViewModelIEnumerableResponseBase {
    "data"?: Array<TenantTokenExpirationDateViewModel>;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface TenantViewModel {
    "tenantUid"?: string;
    "tenantId"?: string;
    "friendlyName"?: string;
    "imageName"?: string;
    "crontabSchedule"?: string;
    "status"?: TenantViewModelStatusEnum;
    "instances"?: Array<WorkflowInstanceViewModel>;
    "customAttributes"?: { [key: string]: string; };
    "workflowInstanceId"?: string;
    "vehicleMappingsFile"?: string;
    "active"?: boolean;
}

export type TenantViewModelStatusEnum = "Disabled" | "Unconfigured" | "Enabled";
export interface TenantViewModelIEnumerableResponseBase {
    "data"?: Array<TenantViewModel>;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface TenantViewModelResponseBase {
    "data"?: TenantViewModel;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface TrackItCredentials {
    "webKey"?: string;
    "timeZoneString"?: string;
}

export interface TrackItCredentialsResponseBase {
    "data"?: TrackItCredentials;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

/**
 * User View Model
 */
export interface UserInfoViewModel {
    /**
     * Gets or sets the first name.
     */
    "firstName"?: string;
    /**
     * Gets or sets the last name.
     */
    "lastName"?: string;
    /**
     * Gets or sets the email address.
     */
    "emailAddress"?: string;
    /**
     * Gets or sets the roles.
     */
    "roles"?: Array<string>;
    /**
     * Gets or sets the client identifier.
     */
    "clientIds"?: Array<number>;
}

export interface UserViewModel {
    "userName"?: string;
    "firstName"?: string;
    "lastName"?: string;
    "phoneNumber"?: string;
    "organizationalUnit"?: OrganizationalUnitViewModel;
    "roles"?: Array<RoleViewModel>;
}

export interface UserViewModelListResponseBase {
    "data"?: Array<UserViewModel>;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface VdasValidationResult {
    "success"?: boolean;
    "errorMessage"?: string;
}

export interface VehicleAssignmentViewModel {
    "id"?: string;
    "firstName"?: string;
    "lastName"?: string;
    "driverId"?: string;
    "vehicleId"?: string;
    "startDate"?: Date;
    "endDate"?: Date;
    "info"?: string;
}

export interface VehicleAssignmentViewModelIEnumerableResponseBase {
    "data"?: Array<VehicleAssignmentViewModel>;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface VehicleCountReportLine {
    "tenantId"?: string;
    "count"?: string;
    "friendlyName"?: string;
    "adapter"?: string;
    "client"?: string;
}

export interface VehicleCountReportViewModel {
    "tenantCount"?: number;
    "vehicleCount"?: number;
    "filename"?: string;
    "rawData"?: string;
    "reportLines"?: Array<VehicleCountReportLine>;
}

export interface VehicleCountReportViewModelResponseBase {
    "data"?: VehicleCountReportViewModel;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface VisTracksCredentials {
    "username"?: string;
    "password"?: string;
}

export interface VisTracksCredentialsResponseBase {
    "data"?: VisTracksCredentials;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}

export interface WorkflowDefinitionTemplateViewModel {
    "tenantConfigTemplate"?: TenantConfig;
    "isDefault"?: boolean;
    "workflowTemplate"?: string;
    "appSettingsTemplate"?: string;
    "additionalAssets"?: Array<Asset>;
    "associatedEntities"?: Array<AssociatedEntity>;
    "sourceProviders"?: Array<number>;
    "submissionProviders"?: Array<number>;
    "sourceProviderOverrides"?: { [key: string]: WorkflowDefinitionTemplateViewModel; };
}

export interface WorkflowInstanceConfig {
    "instanceId"?: string;
    "tenant"?: TenantConfig;
    "friendlyName"?: string;
    "notes"?: string;
    "customAttributes"?: { [key: string]: string; };
}

export interface WorkflowInstanceViewModel {
    "workflowInstanceId"?: string;
    "friendlyName"?: string;
}

export interface ZonarCredentials {
    "username"?: string;
    "password"?: string;
    "url"?: string;
}

export interface ZonarCredentialsResponseBase {
    "data"?: ZonarCredentials;
    "success"?: boolean;
    "error"?: JobViewModelIEnumerableResponseBaseError;
    "totalCount"?: number;
}



/**
 * ActivityApi - fetch parameter creator
 */
export const ActivityApiFetchParamCreator = {
    /** 
     * Get all recent jobs filtered and cropped to page.
     * @param startDateTime Start Date Time
     * @param endDateTime End Date Time
     * @param tenantId 
     */
    apiV1ActivitiesGet(params: {  "startDateTime"?: string; "endDateTime"?: string; "tenantId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/activities`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "startDateTime": params["startDateTime"],
            "endDateTime": params["endDateTime"],
            "tenantId": params["tenantId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get details on specific activity
     * @param id Job Id to fetch
     */
    apiV1ActivityIdGet(params: {  "id": string; }, options?: any): FetchArgs {
        // verify required parameter "id" is set
        if (params["id"] == null) {
            throw new Error("Missing required parameter id when calling apiV1ActivityIdGet");
        }
        const baseUrl = `/api/v1/activity/{id}`
            .replace(`{${"id"}}`, `${ params["id"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Resubmits a job
     * @param jobId 
     */
    apiV1ActivityResubmitJobPost(params: {  "jobId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/activity/resubmitJob`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "jobId": params["jobId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Resubmit the requested message for the requested job
     * @param jobId 
     * @param messageId 
     */
    apiV1ActivityResubmitMessagePost(params: {  "jobId"?: string; "messageId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/activity/resubmitMessage`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "jobId": params["jobId"],
            "messageId": params["messageId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get activity summary for requested date range
     * @param startDateTime 
     * @param endDateTime 
     * @param tenantId 
     */
    apiV1ActivitySummaryGet(params: {  "startDateTime"?: string; "endDateTime"?: string; "tenantId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/activitySummary`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "startDateTime": params["startDateTime"],
            "endDateTime": params["endDateTime"],
            "tenantId": params["tenantId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get a list of all Adapter Types
     * @param clientId 
     */
    apiV1AdaptersGet(params: {  "clientId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/adapters`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "clientId": params["clientId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get a list of all Clients
     */
    apiV1ClientsGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/clients`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get a list of all Tenants Types
     * @param job 
     * @param message 
     * @param name 
     */
    apiV1LinkGet(params: {  "job"?: string; "message"?: string; "name"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/link`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "job": params["job"],
            "message": params["message"],
            "name": params["name"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * ActivityApi - functional programming interface
 */
export const ActivityApiFp = {
    /** 
     * Get all recent jobs filtered and cropped to page.
     * @param startDateTime Start Date Time
     * @param endDateTime End Date Time
     * @param tenantId 
     */
    apiV1ActivitiesGet(params: { "startDateTime"?: string; "endDateTime"?: string; "tenantId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JobViewModelIEnumerableResponseBase> {
        const fetchArgs = ActivityApiFetchParamCreator.apiV1ActivitiesGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get details on specific activity
     * @param id Job Id to fetch
     */
    apiV1ActivityIdGet(params: { "id": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JobViewModelResponseBase> {
        const fetchArgs = ActivityApiFetchParamCreator.apiV1ActivityIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Resubmits a job
     * @param jobId 
     */
    apiV1ActivityResubmitJobPost(params: { "jobId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseBase> {
        const fetchArgs = ActivityApiFetchParamCreator.apiV1ActivityResubmitJobPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Resubmit the requested message for the requested job
     * @param jobId 
     * @param messageId 
     */
    apiV1ActivityResubmitMessagePost(params: { "jobId"?: string; "messageId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseBase> {
        const fetchArgs = ActivityApiFetchParamCreator.apiV1ActivityResubmitMessagePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get activity summary for requested date range
     * @param startDateTime 
     * @param endDateTime 
     * @param tenantId 
     */
    apiV1ActivitySummaryGet(params: { "startDateTime"?: string; "endDateTime"?: string; "tenantId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JobSummaryViewModelIEnumerableResponseBase> {
        const fetchArgs = ActivityApiFetchParamCreator.apiV1ActivitySummaryGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get a list of all Adapter Types
     * @param clientId 
     */
    apiV1AdaptersGet(params: { "clientId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StringIEnumerableResponseBase> {
        const fetchArgs = ActivityApiFetchParamCreator.apiV1AdaptersGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get a list of all Clients
     */
    apiV1ClientsGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AutocompleteViewModelIEnumerableResponseBase> {
        const fetchArgs = ActivityApiFetchParamCreator.apiV1ClientsGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get a list of all Tenants Types
     * @param job 
     * @param message 
     * @param name 
     */
    apiV1LinkGet(params: { "job"?: string; "message"?: string; "name"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FileDownloadResponseBase> {
        const fetchArgs = ActivityApiFetchParamCreator.apiV1LinkGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * ActivityApi - object-oriented interface
 */
export class ActivityApi extends BaseAPI {
    /** 
     * Get all recent jobs filtered and cropped to page.
     * @param startDateTime Start Date Time
     * @param endDateTime End Date Time
     * @param tenantId 
     */
    apiV1ActivitiesGet(params: {  "startDateTime"?: string; "endDateTime"?: string; "tenantId"?: string; }, options?: any) {
        return ActivityApiFp.apiV1ActivitiesGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get details on specific activity
     * @param id Job Id to fetch
     */
    apiV1ActivityIdGet(params: {  "id": string; }, options?: any) {
        return ActivityApiFp.apiV1ActivityIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Resubmits a job
     * @param jobId 
     */
    apiV1ActivityResubmitJobPost(params: {  "jobId"?: string; }, options?: any) {
        return ActivityApiFp.apiV1ActivityResubmitJobPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Resubmit the requested message for the requested job
     * @param jobId 
     * @param messageId 
     */
    apiV1ActivityResubmitMessagePost(params: {  "jobId"?: string; "messageId"?: string; }, options?: any) {
        return ActivityApiFp.apiV1ActivityResubmitMessagePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get activity summary for requested date range
     * @param startDateTime 
     * @param endDateTime 
     * @param tenantId 
     */
    apiV1ActivitySummaryGet(params: {  "startDateTime"?: string; "endDateTime"?: string; "tenantId"?: string; }, options?: any) {
        return ActivityApiFp.apiV1ActivitySummaryGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get a list of all Adapter Types
     * @param clientId 
     */
    apiV1AdaptersGet(params: {  "clientId"?: string; }, options?: any) {
        return ActivityApiFp.apiV1AdaptersGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get a list of all Clients
     */
    apiV1ClientsGet(options?: any) {
        return ActivityApiFp.apiV1ClientsGet(options)(this.fetch, this.basePath);
    }
    /** 
     * Get a list of all Tenants Types
     * @param job 
     * @param message 
     * @param name 
     */
    apiV1LinkGet(params: {  "job"?: string; "message"?: string; "name"?: string; }, options?: any) {
        return ActivityApiFp.apiV1LinkGet(params, options)(this.fetch, this.basePath);
    }
};

/**
 * ActivityApi - factory interface
 */
export const ActivityApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Get all recent jobs filtered and cropped to page.
         * @param startDateTime Start Date Time
         * @param endDateTime End Date Time
         * @param tenantId 
         */
        apiV1ActivitiesGet(params: {  "startDateTime"?: string; "endDateTime"?: string; "tenantId"?: string; }, options?: any) {
            return ActivityApiFp.apiV1ActivitiesGet(params, options)(fetch, basePath);
        },
        /** 
         * Get details on specific activity
         * @param id Job Id to fetch
         */
        apiV1ActivityIdGet(params: {  "id": string; }, options?: any) {
            return ActivityApiFp.apiV1ActivityIdGet(params, options)(fetch, basePath);
        },
        /** 
         * Resubmits a job
         * @param jobId 
         */
        apiV1ActivityResubmitJobPost(params: {  "jobId"?: string; }, options?: any) {
            return ActivityApiFp.apiV1ActivityResubmitJobPost(params, options)(fetch, basePath);
        },
        /** 
         * Resubmit the requested message for the requested job
         * @param jobId 
         * @param messageId 
         */
        apiV1ActivityResubmitMessagePost(params: {  "jobId"?: string; "messageId"?: string; }, options?: any) {
            return ActivityApiFp.apiV1ActivityResubmitMessagePost(params, options)(fetch, basePath);
        },
        /** 
         * Get activity summary for requested date range
         * @param startDateTime 
         * @param endDateTime 
         * @param tenantId 
         */
        apiV1ActivitySummaryGet(params: {  "startDateTime"?: string; "endDateTime"?: string; "tenantId"?: string; }, options?: any) {
            return ActivityApiFp.apiV1ActivitySummaryGet(params, options)(fetch, basePath);
        },
        /** 
         * Get a list of all Adapter Types
         * @param clientId 
         */
        apiV1AdaptersGet(params: {  "clientId"?: string; }, options?: any) {
            return ActivityApiFp.apiV1AdaptersGet(params, options)(fetch, basePath);
        },
        /** 
         * Get a list of all Clients
         */
        apiV1ClientsGet(options?: any) {
            return ActivityApiFp.apiV1ClientsGet(options)(fetch, basePath);
        },
        /** 
         * Get a list of all Tenants Types
         * @param job 
         * @param message 
         * @param name 
         */
        apiV1LinkGet(params: {  "job"?: string; "message"?: string; "name"?: string; }, options?: any) {
            return ActivityApiFp.apiV1LinkGet(params, options)(fetch, basePath);
        },
    };
};


/**
 * AdminApi - fetch parameter creator
 */
export const AdminApiFetchParamCreator = {
    /** 
     * Returns list of all organizations
     */
    apiV1AdminOrgsGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/admin/orgs`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Returns list of all roles
     */
    apiV1AdminRolesGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/admin/roles`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Creates a new user
     * @param user 
     */
    apiV1AdminUsersCreatePost(params: {  "user"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/admin/users/create`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "user": params["user"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Returns list of all users
     */
    apiV1AdminUsersGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/admin/users`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Updates the given user
     * @param user 
     */
    apiV1AdminUsersUpdatePost(params: {  "user"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/admin/users/update`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "user": params["user"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * AdminApi - functional programming interface
 */
export const AdminApiFp = {
    /** 
     * Returns list of all organizations
     */
    apiV1AdminOrgsGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OrganizationalUnitViewModelListResponseBase> {
        const fetchArgs = AdminApiFetchParamCreator.apiV1AdminOrgsGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Returns list of all roles
     */
    apiV1AdminRolesGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RoleViewModelListResponseBase> {
        const fetchArgs = AdminApiFetchParamCreator.apiV1AdminRolesGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Creates a new user
     * @param user 
     */
    apiV1AdminUsersCreatePost(params: { "user"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseBase> {
        const fetchArgs = AdminApiFetchParamCreator.apiV1AdminUsersCreatePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Returns list of all users
     */
    apiV1AdminUsersGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserViewModelListResponseBase> {
        const fetchArgs = AdminApiFetchParamCreator.apiV1AdminUsersGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Updates the given user
     * @param user 
     */
    apiV1AdminUsersUpdatePost(params: { "user"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseBase> {
        const fetchArgs = AdminApiFetchParamCreator.apiV1AdminUsersUpdatePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * AdminApi - object-oriented interface
 */
export class AdminApi extends BaseAPI {
    /** 
     * Returns list of all organizations
     */
    apiV1AdminOrgsGet(options?: any) {
        return AdminApiFp.apiV1AdminOrgsGet(options)(this.fetch, this.basePath);
    }
    /** 
     * Returns list of all roles
     */
    apiV1AdminRolesGet(options?: any) {
        return AdminApiFp.apiV1AdminRolesGet(options)(this.fetch, this.basePath);
    }
    /** 
     * Creates a new user
     * @param user 
     */
    apiV1AdminUsersCreatePost(params: {  "user"?: string; }, options?: any) {
        return AdminApiFp.apiV1AdminUsersCreatePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Returns list of all users
     */
    apiV1AdminUsersGet(options?: any) {
        return AdminApiFp.apiV1AdminUsersGet(options)(this.fetch, this.basePath);
    }
    /** 
     * Updates the given user
     * @param user 
     */
    apiV1AdminUsersUpdatePost(params: {  "user"?: string; }, options?: any) {
        return AdminApiFp.apiV1AdminUsersUpdatePost(params, options)(this.fetch, this.basePath);
    }
};

/**
 * AdminApi - factory interface
 */
export const AdminApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Returns list of all organizations
         */
        apiV1AdminOrgsGet(options?: any) {
            return AdminApiFp.apiV1AdminOrgsGet(options)(fetch, basePath);
        },
        /** 
         * Returns list of all roles
         */
        apiV1AdminRolesGet(options?: any) {
            return AdminApiFp.apiV1AdminRolesGet(options)(fetch, basePath);
        },
        /** 
         * Creates a new user
         * @param user 
         */
        apiV1AdminUsersCreatePost(params: {  "user"?: string; }, options?: any) {
            return AdminApiFp.apiV1AdminUsersCreatePost(params, options)(fetch, basePath);
        },
        /** 
         * Returns list of all users
         */
        apiV1AdminUsersGet(options?: any) {
            return AdminApiFp.apiV1AdminUsersGet(options)(fetch, basePath);
        },
        /** 
         * Updates the given user
         * @param user 
         */
        apiV1AdminUsersUpdatePost(params: {  "user"?: string; }, options?: any) {
            return AdminApiFp.apiV1AdminUsersUpdatePost(params, options)(fetch, basePath);
        },
    };
};


/**
 * ApiEndpointApi - fetch parameter creator
 */
export const ApiEndpointApiFetchParamCreator = {
    /** 
     */
    apiHelloGet(options?: any): FetchArgs {
        const baseUrl = `/api/hello`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * @param body 
     */
    apiProcessControlMessageByNamePost(params: {  "body"?: ProcessControlMessage; }, options?: any): FetchArgs {
        const baseUrl = `/api/ProcessControlMessageByName`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * ApiEndpointApi - functional programming interface
 */
export const ApiEndpointApiFp = {
    /** 
     */
    apiHelloGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
        const fetchArgs = ApiEndpointApiFetchParamCreator.apiHelloGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * @param body 
     */
    apiProcessControlMessageByNamePost(params: { "body"?: ProcessControlMessage;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = ApiEndpointApiFetchParamCreator.apiProcessControlMessageByNamePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * ApiEndpointApi - object-oriented interface
 */
export class ApiEndpointApi extends BaseAPI {
    /** 
     */
    apiHelloGet(options?: any) {
        return ApiEndpointApiFp.apiHelloGet(options)(this.fetch, this.basePath);
    }
    /** 
     * @param body 
     */
    apiProcessControlMessageByNamePost(params: {  "body"?: ProcessControlMessage; }, options?: any) {
        return ApiEndpointApiFp.apiProcessControlMessageByNamePost(params, options)(this.fetch, this.basePath);
    }
};

/**
 * ApiEndpointApi - factory interface
 */
export const ApiEndpointApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         */
        apiHelloGet(options?: any) {
            return ApiEndpointApiFp.apiHelloGet(options)(fetch, basePath);
        },
        /** 
         * @param body 
         */
        apiProcessControlMessageByNamePost(params: {  "body"?: ProcessControlMessage; }, options?: any) {
            return ApiEndpointApiFp.apiProcessControlMessageByNamePost(params, options)(fetch, basePath);
        },
    };
};


/**
 * AssociatedEntitiesApi - fetch parameter creator
 */
export const AssociatedEntitiesApiFetchParamCreator = {
    /** 
     * Get all category items with a category type
     * @param categoryTypeId 
     */
    apiV1AssociatedEntitiesCategoryItemsByTypeCategoryTypeIdGet(params: {  "categoryTypeId": number; }, options?: any): FetchArgs {
        // verify required parameter "categoryTypeId" is set
        if (params["categoryTypeId"] == null) {
            throw new Error("Missing required parameter categoryTypeId when calling apiV1AssociatedEntitiesCategoryItemsByTypeCategoryTypeIdGet");
        }
        const baseUrl = `/api/v1/AssociatedEntities/CategoryItemsByType/{categoryTypeId}`
            .replace(`{${"categoryTypeId"}}`, `${ params["categoryTypeId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get all category types
     */
    apiV1AssociatedEntitiesCategoryTypesGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/AssociatedEntities/CategoryTypes`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Create Associated Entity object
     * @param body 
     */
    apiV1AssociatedEntitiesCreateAssociatedEntityPost(params: {  "body"?: AssociatedEntityViewModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/AssociatedEntities/CreateAssociatedEntity`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Delete Associated Entity object
     * @param id 
     */
    apiV1AssociatedEntitiesDeleteAssociatedEntityIdPost(params: {  "id": number; }, options?: any): FetchArgs {
        // verify required parameter "id" is set
        if (params["id"] == null) {
            throw new Error("Missing required parameter id when calling apiV1AssociatedEntitiesDeleteAssociatedEntityIdPost");
        }
        const baseUrl = `/api/v1/AssociatedEntities/DeleteAssociatedEntity/{id}`
            .replace(`{${"id"}}`, `${ params["id"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get all entity types
     */
    apiV1AssociatedEntitiesEntityTypesGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/AssociatedEntities/EntityTypes`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get all recent jobs filtered and cropped to page.
     * @param skip Page number default is the first page
     * @param take Number of items per page default to all items(0)
     * @param sortColumn Column Name to Sort By Default is Date
     * @param sortAscending Sort Ascending or Descending
     * @param search 
     * @param entityId 
     * @param entityTypeId 
     * @param categoryTypeId 
     */
    apiV1AssociatedEntitiesGetAssociatedEntitiesGet(params: {  "skip"?: number; "take"?: number; "sortColumn"?: string; "sortAscending"?: boolean; "search"?: string; "entityId"?: string; "entityTypeId"?: number; "categoryTypeId"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/AssociatedEntities/GetAssociatedEntities`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "skip": params["skip"],
            "take": params["take"],
            "sortColumn": params["sortColumn"],
            "sortAscending": params["sortAscending"],
            "search": params["search"],
            "entityId": params["entityId"],
            "entityTypeId": params["entityTypeId"],
            "categoryTypeId": params["categoryTypeId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get available source providers
     */
    apiV1AssociatedEntitiesSourceProvidersGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/AssociatedEntities/SourceProviders`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Update Associated Entity object
     * @param body 
     */
    apiV1AssociatedEntitiesUpdateAssociatedEntityPost(params: {  "body"?: AssociatedEntityViewModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/AssociatedEntities/UpdateAssociatedEntity`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * AssociatedEntitiesApi - functional programming interface
 */
export const AssociatedEntitiesApiFp = {
    /** 
     * Get all category items with a category type
     * @param categoryTypeId 
     */
    apiV1AssociatedEntitiesCategoryItemsByTypeCategoryTypeIdGet(params: { "categoryTypeId": number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CategoryItemIEnumerableResponseBase> {
        const fetchArgs = AssociatedEntitiesApiFetchParamCreator.apiV1AssociatedEntitiesCategoryItemsByTypeCategoryTypeIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get all category types
     */
    apiV1AssociatedEntitiesCategoryTypesGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CategoryTypeIEnumerableResponseBase> {
        const fetchArgs = AssociatedEntitiesApiFetchParamCreator.apiV1AssociatedEntitiesCategoryTypesGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Create Associated Entity object
     * @param body 
     */
    apiV1AssociatedEntitiesCreateAssociatedEntityPost(params: { "body"?: AssociatedEntityViewModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseBase> {
        const fetchArgs = AssociatedEntitiesApiFetchParamCreator.apiV1AssociatedEntitiesCreateAssociatedEntityPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Delete Associated Entity object
     * @param id 
     */
    apiV1AssociatedEntitiesDeleteAssociatedEntityIdPost(params: { "id": number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseBase> {
        const fetchArgs = AssociatedEntitiesApiFetchParamCreator.apiV1AssociatedEntitiesDeleteAssociatedEntityIdPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get all entity types
     */
    apiV1AssociatedEntitiesEntityTypesGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityTypeIEnumerableResponseBase> {
        const fetchArgs = AssociatedEntitiesApiFetchParamCreator.apiV1AssociatedEntitiesEntityTypesGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get all recent jobs filtered and cropped to page.
     * @param skip Page number default is the first page
     * @param take Number of items per page default to all items(0)
     * @param sortColumn Column Name to Sort By Default is Date
     * @param sortAscending Sort Ascending or Descending
     * @param search 
     * @param entityId 
     * @param entityTypeId 
     * @param categoryTypeId 
     */
    apiV1AssociatedEntitiesGetAssociatedEntitiesGet(params: { "skip"?: number; "take"?: number; "sortColumn"?: string; "sortAscending"?: boolean; "search"?: string; "entityId"?: string; "entityTypeId"?: number; "categoryTypeId"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AssociatedEntityViewModelIEnumerablePagedResponseBase> {
        const fetchArgs = AssociatedEntitiesApiFetchParamCreator.apiV1AssociatedEntitiesGetAssociatedEntitiesGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get available source providers
     */
    apiV1AssociatedEntitiesSourceProvidersGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CategoryItemIEnumerableResponseBase> {
        const fetchArgs = AssociatedEntitiesApiFetchParamCreator.apiV1AssociatedEntitiesSourceProvidersGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Update Associated Entity object
     * @param body 
     */
    apiV1AssociatedEntitiesUpdateAssociatedEntityPost(params: { "body"?: AssociatedEntityViewModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseBase> {
        const fetchArgs = AssociatedEntitiesApiFetchParamCreator.apiV1AssociatedEntitiesUpdateAssociatedEntityPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * AssociatedEntitiesApi - object-oriented interface
 */
export class AssociatedEntitiesApi extends BaseAPI {
    /** 
     * Get all category items with a category type
     * @param categoryTypeId 
     */
    apiV1AssociatedEntitiesCategoryItemsByTypeCategoryTypeIdGet(params: {  "categoryTypeId": number; }, options?: any) {
        return AssociatedEntitiesApiFp.apiV1AssociatedEntitiesCategoryItemsByTypeCategoryTypeIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get all category types
     */
    apiV1AssociatedEntitiesCategoryTypesGet(options?: any) {
        return AssociatedEntitiesApiFp.apiV1AssociatedEntitiesCategoryTypesGet(options)(this.fetch, this.basePath);
    }
    /** 
     * Create Associated Entity object
     * @param body 
     */
    apiV1AssociatedEntitiesCreateAssociatedEntityPost(params: {  "body"?: AssociatedEntityViewModel; }, options?: any) {
        return AssociatedEntitiesApiFp.apiV1AssociatedEntitiesCreateAssociatedEntityPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Delete Associated Entity object
     * @param id 
     */
    apiV1AssociatedEntitiesDeleteAssociatedEntityIdPost(params: {  "id": number; }, options?: any) {
        return AssociatedEntitiesApiFp.apiV1AssociatedEntitiesDeleteAssociatedEntityIdPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get all entity types
     */
    apiV1AssociatedEntitiesEntityTypesGet(options?: any) {
        return AssociatedEntitiesApiFp.apiV1AssociatedEntitiesEntityTypesGet(options)(this.fetch, this.basePath);
    }
    /** 
     * Get all recent jobs filtered and cropped to page.
     * @param skip Page number default is the first page
     * @param take Number of items per page default to all items(0)
     * @param sortColumn Column Name to Sort By Default is Date
     * @param sortAscending Sort Ascending or Descending
     * @param search 
     * @param entityId 
     * @param entityTypeId 
     * @param categoryTypeId 
     */
    apiV1AssociatedEntitiesGetAssociatedEntitiesGet(params: {  "skip"?: number; "take"?: number; "sortColumn"?: string; "sortAscending"?: boolean; "search"?: string; "entityId"?: string; "entityTypeId"?: number; "categoryTypeId"?: number; }, options?: any) {
        return AssociatedEntitiesApiFp.apiV1AssociatedEntitiesGetAssociatedEntitiesGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get available source providers
     */
    apiV1AssociatedEntitiesSourceProvidersGet(options?: any) {
        return AssociatedEntitiesApiFp.apiV1AssociatedEntitiesSourceProvidersGet(options)(this.fetch, this.basePath);
    }
    /** 
     * Update Associated Entity object
     * @param body 
     */
    apiV1AssociatedEntitiesUpdateAssociatedEntityPost(params: {  "body"?: AssociatedEntityViewModel; }, options?: any) {
        return AssociatedEntitiesApiFp.apiV1AssociatedEntitiesUpdateAssociatedEntityPost(params, options)(this.fetch, this.basePath);
    }
};

/**
 * AssociatedEntitiesApi - factory interface
 */
export const AssociatedEntitiesApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Get all category items with a category type
         * @param categoryTypeId 
         */
        apiV1AssociatedEntitiesCategoryItemsByTypeCategoryTypeIdGet(params: {  "categoryTypeId": number; }, options?: any) {
            return AssociatedEntitiesApiFp.apiV1AssociatedEntitiesCategoryItemsByTypeCategoryTypeIdGet(params, options)(fetch, basePath);
        },
        /** 
         * Get all category types
         */
        apiV1AssociatedEntitiesCategoryTypesGet(options?: any) {
            return AssociatedEntitiesApiFp.apiV1AssociatedEntitiesCategoryTypesGet(options)(fetch, basePath);
        },
        /** 
         * Create Associated Entity object
         * @param body 
         */
        apiV1AssociatedEntitiesCreateAssociatedEntityPost(params: {  "body"?: AssociatedEntityViewModel; }, options?: any) {
            return AssociatedEntitiesApiFp.apiV1AssociatedEntitiesCreateAssociatedEntityPost(params, options)(fetch, basePath);
        },
        /** 
         * Delete Associated Entity object
         * @param id 
         */
        apiV1AssociatedEntitiesDeleteAssociatedEntityIdPost(params: {  "id": number; }, options?: any) {
            return AssociatedEntitiesApiFp.apiV1AssociatedEntitiesDeleteAssociatedEntityIdPost(params, options)(fetch, basePath);
        },
        /** 
         * Get all entity types
         */
        apiV1AssociatedEntitiesEntityTypesGet(options?: any) {
            return AssociatedEntitiesApiFp.apiV1AssociatedEntitiesEntityTypesGet(options)(fetch, basePath);
        },
        /** 
         * Get all recent jobs filtered and cropped to page.
         * @param skip Page number default is the first page
         * @param take Number of items per page default to all items(0)
         * @param sortColumn Column Name to Sort By Default is Date
         * @param sortAscending Sort Ascending or Descending
         * @param search 
         * @param entityId 
         * @param entityTypeId 
         * @param categoryTypeId 
         */
        apiV1AssociatedEntitiesGetAssociatedEntitiesGet(params: {  "skip"?: number; "take"?: number; "sortColumn"?: string; "sortAscending"?: boolean; "search"?: string; "entityId"?: string; "entityTypeId"?: number; "categoryTypeId"?: number; }, options?: any) {
            return AssociatedEntitiesApiFp.apiV1AssociatedEntitiesGetAssociatedEntitiesGet(params, options)(fetch, basePath);
        },
        /** 
         * Get available source providers
         */
        apiV1AssociatedEntitiesSourceProvidersGet(options?: any) {
            return AssociatedEntitiesApiFp.apiV1AssociatedEntitiesSourceProvidersGet(options)(fetch, basePath);
        },
        /** 
         * Update Associated Entity object
         * @param body 
         */
        apiV1AssociatedEntitiesUpdateAssociatedEntityPost(params: {  "body"?: AssociatedEntityViewModel; }, options?: any) {
            return AssociatedEntitiesApiFp.apiV1AssociatedEntitiesUpdateAssociatedEntityPost(params, options)(fetch, basePath);
        },
    };
};


/**
 * AuthorizeApi - fetch parameter creator
 */
export const AuthorizeApiFetchParamCreator = {
    /** 
     * @param code 
     * @param tenantUid 
     * @param state 
     */
    authorizeGet(params: {  "code"?: string; "tenantUid"?: string; "state"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/authorize`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "code": params["code"],
            "tenantUid": params["tenantUid"],
            "state": params["state"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * AuthorizeApi - functional programming interface
 */
export const AuthorizeApiFp = {
    /** 
     * @param code 
     * @param tenantUid 
     * @param state 
     */
    authorizeGet(params: { "code"?: string; "tenantUid"?: string; "state"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = AuthorizeApiFetchParamCreator.authorizeGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * AuthorizeApi - object-oriented interface
 */
export class AuthorizeApi extends BaseAPI {
    /** 
     * @param code 
     * @param tenantUid 
     * @param state 
     */
    authorizeGet(params: {  "code"?: string; "tenantUid"?: string; "state"?: string; }, options?: any) {
        return AuthorizeApiFp.authorizeGet(params, options)(this.fetch, this.basePath);
    }
};

/**
 * AuthorizeApi - factory interface
 */
export const AuthorizeApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * @param code 
         * @param tenantUid 
         * @param state 
         */
        authorizeGet(params: {  "code"?: string; "tenantUid"?: string; "state"?: string; }, options?: any) {
            return AuthorizeApiFp.authorizeGet(params, options)(fetch, basePath);
        },
    };
};


/**
 * ClientApi - fetch parameter creator
 */
export const ClientApiFetchParamCreator = {
    /** 
     * Gets the available workflow templates for the requested client  if currently logged-in user has access.
     * @param clientId The client identifier.
     */
    apiV1ClientGetWorkflowTemplatesClientIdGet(params: {  "clientId": number; }, options?: any): FetchArgs {
        // verify required parameter "clientId" is set
        if (params["clientId"] == null) {
            throw new Error("Missing required parameter clientId when calling apiV1ClientGetWorkflowTemplatesClientIdGet");
        }
        const baseUrl = `/api/v1/Client/GetWorkflowTemplates/{clientId}`
            .replace(`{${"clientId"}}`, `${ params["clientId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * ClientApi - functional programming interface
 */
export const ClientApiFp = {
    /** 
     * Gets the available workflow templates for the requested client  if currently logged-in user has access.
     * @param clientId The client identifier.
     */
    apiV1ClientGetWorkflowTemplatesClientIdGet(params: { "clientId": number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StringWorkflowDefinitionTemplateViewModelIDictionaryResponseBase> {
        const fetchArgs = ClientApiFetchParamCreator.apiV1ClientGetWorkflowTemplatesClientIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * ClientApi - object-oriented interface
 */
export class ClientApi extends BaseAPI {
    /** 
     * Gets the available workflow templates for the requested client  if currently logged-in user has access.
     * @param clientId The client identifier.
     */
    apiV1ClientGetWorkflowTemplatesClientIdGet(params: {  "clientId": number; }, options?: any) {
        return ClientApiFp.apiV1ClientGetWorkflowTemplatesClientIdGet(params, options)(this.fetch, this.basePath);
    }
};

/**
 * ClientApi - factory interface
 */
export const ClientApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Gets the available workflow templates for the requested client  if currently logged-in user has access.
         * @param clientId The client identifier.
         */
        apiV1ClientGetWorkflowTemplatesClientIdGet(params: {  "clientId": number; }, options?: any) {
            return ClientApiFp.apiV1ClientGetWorkflowTemplatesClientIdGet(params, options)(fetch, basePath);
        },
    };
};


/**
 * ConfigurationApi - fetch parameter creator
 */
export const ConfigurationApiFetchParamCreator = {
    /** 
     * Save AdminFTP credentials
     * @param transferServiceType 
     * @param portNumber 
     * @param ftpAddress 
     * @param ftpUsername 
     * @param ftpPassword 
     * @param tenantId 
     */
    apiV1ConfigurationAdminFtpSavePost(params: {  "transferServiceType"?: string; "portNumber"?: number; "ftpAddress"?: string; "ftpUsername"?: string; "ftpPassword"?: string; "tenantId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/configuration/adminFtpSave`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "TransferServiceType": params["transferServiceType"],
            "PortNumber": params["portNumber"],
            "FtpAddress": params["ftpAddress"],
            "FtpUsername": params["ftpUsername"],
            "FtpPassword": params["ftpPassword"],
            "tenantId": params["tenantId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Load AdminFTP credentials
     * @param tenantId 
     */
    apiV1ConfigurationAdminFtpTenantIdGet(params: {  "tenantId": string; }, options?: any): FetchArgs {
        // verify required parameter "tenantId" is set
        if (params["tenantId"] == null) {
            throw new Error("Missing required parameter tenantId when calling apiV1ConfigurationAdminFtpTenantIdGet");
        }
        const baseUrl = `/api/v1/configuration/adminFtp/{tenantId}`
            .replace(`{${"tenantId"}}`, `${ params["tenantId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * To load the BigChange details
     * @param tenantId 
     */
    apiV1ConfigurationBigChangeLoadTenantIdGet(params: {  "tenantId": string; }, options?: any): FetchArgs {
        // verify required parameter "tenantId" is set
        if (params["tenantId"] == null) {
            throw new Error("Missing required parameter tenantId when calling apiV1ConfigurationBigChangeLoadTenantIdGet");
        }
        const baseUrl = `/api/v1/configuration/bigChangeLoad/{tenantId}`
            .replace(`{${"tenantId"}}`, `${ params["tenantId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * To save the BigChange configurations
     * @param username 
     * @param password 
     * @param apiKey 
     * @param baseUrl 
     * @param tenantId 
     */
    apiV1ConfigurationBigChangeSavePost(params: {  "username"?: string; "password"?: string; "apiKey"?: string; "baseUrl"?: string; "tenantId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/configuration/bigChangeSave`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "Username": params["username"],
            "Password": params["password"],
            "ApiKey": params["apiKey"],
            "BaseUrl": params["baseUrl"],
            "tenantId": params["tenantId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * To save the BigRoad configurations
     * @param apiKey 
     * @param useTruckId 
     * @param tenantId 
     */
    apiV1ConfigurationBigRoadSavePost(params: {  "apiKey"?: string; "useTruckId"?: boolean; "tenantId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/configuration/BigRoadSave`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "ApiKey": params["apiKey"],
            "UseTruckId": params["useTruckId"],
            "tenantId": params["tenantId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * To load the BigRoad details
     * @param tenantId 
     */
    apiV1ConfigurationBigRoadTenantIdGet(params: {  "tenantId": string; }, options?: any): FetchArgs {
        // verify required parameter "tenantId" is set
        if (params["tenantId"] == null) {
            throw new Error("Missing required parameter tenantId when calling apiV1ConfigurationBigRoadTenantIdGet");
        }
        const baseUrl = `/api/v1/configuration/bigRoad/{tenantId}`
            .replace(`{${"tenantId"}}`, `${ params["tenantId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * To save the blueTree configurations
     * @param username 
     * @param password 
     * @param tenantId 
     */
    apiV1ConfigurationBlueTreeSavePost(params: {  "username"?: string; "password"?: string; "tenantId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/configuration/BlueTreeSave`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "Username": params["username"],
            "Password": params["password"],
            "tenantId": params["tenantId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * To load the blueTree the details
     * @param tenantId 
     */
    apiV1ConfigurationBlueTreeTenantIdGet(params: {  "tenantId": string; }, options?: any): FetchArgs {
        // verify required parameter "tenantId" is set
        if (params["tenantId"] == null) {
            throw new Error("Missing required parameter tenantId when calling apiV1ConfigurationBlueTreeTenantIdGet");
        }
        const baseUrl = `/api/v1/configuration/blueTree/{tenantId}`
            .replace(`{${"tenantId"}}`, `${ params["tenantId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * To Save the CADEC credentials
     * @param clientId 
     * @param tenantId 
     */
    apiV1ConfigurationCADECSavePost(params: {  "clientId"?: string; "tenantId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/configuration/CADECSave`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "ClientId": params["clientId"],
            "tenantId": params["tenantId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * To Get the CADEC details
     * @param tenantId 
     */
    apiV1ConfigurationCADECTenantIdGet(params: {  "tenantId": string; }, options?: any): FetchArgs {
        // verify required parameter "tenantId" is set
        if (params["tenantId"] == null) {
            throw new Error("Missing required parameter tenantId when calling apiV1ConfigurationCADECTenantIdGet");
        }
        const baseUrl = `/api/v1/configuration/CADEC/{tenantId}`
            .replace(`{${"tenantId"}}`, `${ params["tenantId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Save Coretex credentials
     * @param username 
     * @param password 
     * @param apiKey 
     * @param tenantId 
     */
    apiV1ConfigurationCoretexSavePost(params: {  "username"?: string; "password"?: string; "apiKey"?: string; "tenantId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/configuration/coretexSave`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "Username": params["username"],
            "Password": params["password"],
            "ApiKey": params["apiKey"],
            "tenantId": params["tenantId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Load Coretex credentials
     * @param tenantId 
     */
    apiV1ConfigurationCoretexTenantIdGet(params: {  "tenantId": string; }, options?: any): FetchArgs {
        // verify required parameter "tenantId" is set
        if (params["tenantId"] == null) {
            throw new Error("Missing required parameter tenantId when calling apiV1ConfigurationCoretexTenantIdGet");
        }
        const baseUrl = `/api/v1/configuration/coretex/{tenantId}`
            .replace(`{${"tenantId"}}`, `${ params["tenantId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Save DigitalFleet credentials
     * @param apiKey 
     * @param driverIdKey 
     * @param tenantId 
     */
    apiV1ConfigurationDigitalFleetSavePost(params: {  "apiKey"?: string; "driverIdKey"?: string; "tenantId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/configuration/digitalFleetSave`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "ApiKey": params["apiKey"],
            "DriverIdKey": params["driverIdKey"],
            "tenantId": params["tenantId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Load DigitalFleet credentials
     * @param tenantId 
     */
    apiV1ConfigurationDigitalFleetTenantIdGet(params: {  "tenantId": string; }, options?: any): FetchArgs {
        // verify required parameter "tenantId" is set
        if (params["tenantId"] == null) {
            throw new Error("Missing required parameter tenantId when calling apiV1ConfigurationDigitalFleetTenantIdGet");
        }
        const baseUrl = `/api/v1/configuration/digitalFleet/{tenantId}`
            .replace(`{${"tenantId"}}`, `${ params["tenantId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * To load the drivetech configuration based on tenantId
     * @param tenantId 
     */
    apiV1ConfigurationDrivertechLoadTenantIdGet(params: {  "tenantId": string; }, options?: any): FetchArgs {
        // verify required parameter "tenantId" is set
        if (params["tenantId"] == null) {
            throw new Error("Missing required parameter tenantId when calling apiV1ConfigurationDrivertechLoadTenantIdGet");
        }
        const baseUrl = `/api/v1/configuration/drivertechLoad/{tenantId}`
            .replace(`{${"tenantId"}}`, `${ params["tenantId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * To Save the drivetech configuration.
     * @param username 
     * @param password 
     * @param datastoreName 
     * @param companyCode 
     * @param tenantId 
     */
    apiV1ConfigurationDrivertechSavePost(params: {  "username"?: string; "password"?: string; "datastoreName"?: string; "companyCode"?: string; "tenantId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/configuration/drivertechSave`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "Username": params["username"],
            "Password": params["password"],
            "DatastoreName": params["datastoreName"],
            "CompanyCode": params["companyCode"],
            "tenantId": params["tenantId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * To load the emailCSV configuration based on tenantId
     * @param tenantId 
     */
    apiV1ConfigurationEmailCSVLoadTenantIdGet(params: {  "tenantId": string; }, options?: any): FetchArgs {
        // verify required parameter "tenantId" is set
        if (params["tenantId"] == null) {
            throw new Error("Missing required parameter tenantId when calling apiV1ConfigurationEmailCSVLoadTenantIdGet");
        }
        const baseUrl = `/api/v1/configuration/emailCSVLoad/{tenantId}`
            .replace(`{${"tenantId"}}`, `${ params["tenantId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * To Save the emailCSV configuration.
     * @param messageHeaderIdentificationPrefix 
     * @param graphClientId 
     * @param graphTenantId 
     * @param graphUsername 
     * @param tenantId 
     */
    apiV1ConfigurationEmailCSVSavePost(params: {  "messageHeaderIdentificationPrefix"?: string; "graphClientId"?: string; "graphTenantId"?: string; "graphUsername"?: string; "tenantId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/configuration/emailCSVSave`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "MessageHeaderIdentificationPrefix": params["messageHeaderIdentificationPrefix"],
            "GraphClientId": params["graphClientId"],
            "GraphTenantId": params["graphTenantId"],
            "GraphUsername": params["graphUsername"],
            "tenantId": params["tenantId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Save ERoads credentials
     * @param apiKey 
     * @param tenantId 
     */
    apiV1ConfigurationEroadsSavePost(params: {  "apiKey"?: string; "tenantId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/configuration/eroadsSave`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "ApiKey": params["apiKey"],
            "tenantId": params["tenantId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Load ERoads credentials
     * @param tenantId 
     */
    apiV1ConfigurationEroadsTenantIdGet(params: {  "tenantId": string; }, options?: any): FetchArgs {
        // verify required parameter "tenantId" is set
        if (params["tenantId"] == null) {
            throw new Error("Missing required parameter tenantId when calling apiV1ConfigurationEroadsTenantIdGet");
        }
        const baseUrl = `/api/v1/configuration/eroads/{tenantId}`
            .replace(`{${"tenantId"}}`, `${ params["tenantId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get a file list from data for a tenant with {prefix}
     * @param tenantId 
     * @param prefix 
     */
    apiV1ConfigurationFilelistTenantIdPrefixGet(params: {  "tenantId": string; "prefix": string; }, options?: any): FetchArgs {
        // verify required parameter "tenantId" is set
        if (params["tenantId"] == null) {
            throw new Error("Missing required parameter tenantId when calling apiV1ConfigurationFilelistTenantIdPrefixGet");
        }
        // verify required parameter "prefix" is set
        if (params["prefix"] == null) {
            throw new Error("Missing required parameter prefix when calling apiV1ConfigurationFilelistTenantIdPrefixGet");
        }
        const baseUrl = `/api/v1/configuration/filelist/{tenantId}/{prefix}`
            .replace(`{${"tenantId"}}`, `${ params["tenantId"] }`)
            .replace(`{${"prefix"}}`, `${ params["prefix"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * To load the geotab configuration based on tenantId
     * @param tenantId 
     */
    apiV1ConfigurationGeotabLoadTenantIdGet(params: {  "tenantId": string; }, options?: any): FetchArgs {
        // verify required parameter "tenantId" is set
        if (params["tenantId"] == null) {
            throw new Error("Missing required parameter tenantId when calling apiV1ConfigurationGeotabLoadTenantIdGet");
        }
        const baseUrl = `/api/v1/configuration/geotabLoad/{tenantId}`
            .replace(`{${"tenantId"}}`, `${ params["tenantId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * To Save the geotab configuration.
     * @param database 
     * @param password 
     * @param server 
     * @param userName 
     * @param serviceOptions 
     * @param tenantId 
     */
    apiV1ConfigurationGeotabSavePost(params: {  "database"?: string; "password"?: string; "server"?: string; "userName"?: string; "serviceOptions"?: Array<string>; "tenantId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/configuration/geotabSave`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "Database": params["database"],
            "Password": params["password"],
            "Server": params["server"],
            "UserName": params["userName"],
            "ServiceOptions": params["serviceOptions"],
            "tenantId": params["tenantId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * To save the ISAACInstruments Credentials
     * @param authenticationUsername 
     * @param authenticationPassword 
     * @param authenticationGrantType 
     * @param basicToken 
     * @param hostUrl 
     * @param tenantId 
     */
    apiV1ConfigurationISAACInstrumentsSavePost(params: {  "authenticationUsername"?: string; "authenticationPassword"?: string; "authenticationGrantType"?: string; "basicToken"?: string; "hostUrl"?: string; "tenantId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/configuration/ISAACInstrumentsSave`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "Authentication.Username": params["authenticationUsername"],
            "Authentication.Password": params["authenticationPassword"],
            "Authentication.GrantType": params["authenticationGrantType"],
            "BasicToken": params["basicToken"],
            "HostUrl": params["hostUrl"],
            "tenantId": params["tenantId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * To load the ISAACInstruments details
     * @param tenantId 
     */
    apiV1ConfigurationISAACInstrumentsTenantIdGet(params: {  "tenantId": string; }, options?: any): FetchArgs {
        // verify required parameter "tenantId" is set
        if (params["tenantId"] == null) {
            throw new Error("Missing required parameter tenantId when calling apiV1ConfigurationISAACInstrumentsTenantIdGet");
        }
        const baseUrl = `/api/v1/configuration/ISAACInstruments/{tenantId}`
            .replace(`{${"tenantId"}}`, `${ params["tenantId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * To load the JJKeller configuration based on tenantId
     * @param tenantId 
     */
    apiV1ConfigurationJjkellerLoadTenantIdGet(params: {  "tenantId": string; }, options?: any): FetchArgs {
        // verify required parameter "tenantId" is set
        if (params["tenantId"] == null) {
            throw new Error("Missing required parameter tenantId when calling apiV1ConfigurationJjkellerLoadTenantIdGet");
        }
        const baseUrl = `/api/v1/configuration/jjkellerLoad/{tenantId}`
            .replace(`{${"tenantId"}}`, `${ params["tenantId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * To Save the JJKeller configuration.
     * @param userName 
     * @param password 
     * @param tenantId 
     */
    apiV1ConfigurationJjkellerSavePost(params: {  "userName"?: string; "password"?: string; "tenantId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/configuration/jjkellerSave`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "UserName": params["userName"],
            "Password": params["password"],
            "tenantId": params["tenantId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * To load the keepTruckin the details
     * @param tenantId 
     */
    apiV1ConfigurationKeepTruckinTenantIdGet(params: {  "tenantId": string; }, options?: any): FetchArgs {
        // verify required parameter "tenantId" is set
        if (params["tenantId"] == null) {
            throw new Error("Missing required parameter tenantId when calling apiV1ConfigurationKeepTruckinTenantIdGet");
        }
        const baseUrl = `/api/v1/configuration/keepTruckin/{tenantId}`
            .replace(`{${"tenantId"}}`, `${ params["tenantId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * To save the keeptruckin configurations
     * @param apiKey 
     * @param url 
     * @param companyId 
     * @param sharedSecret 
     * @param tenantId 
     */
    apiV1ConfigurationKeeptruckinSavePost(params: {  "apiKey"?: string; "url"?: string; "companyId"?: string; "sharedSecret"?: string; "tenantId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/configuration/keeptruckinSave`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "ApiKey": params["apiKey"],
            "Url": params["url"],
            "CompanyId": params["companyId"],
            "SharedSecret": params["sharedSecret"],
            "tenantId": params["tenantId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Save LoadTrek credentials
     * @param username 
     * @param password 
     * @param company 
     * @param driverGroup 
     * @param tenantId 
     */
    apiV1ConfigurationLoadTrekSavePost(params: {  "username"?: string; "password"?: string; "company"?: string; "driverGroup"?: string; "tenantId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/configuration/loadTrekSave`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "Username": params["username"],
            "Password": params["password"],
            "Company": params["company"],
            "DriverGroup": params["driverGroup"],
            "tenantId": params["tenantId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Load LoadTrek credentials
     * @param tenantId 
     */
    apiV1ConfigurationLoadTrekTenantIdGet(params: {  "tenantId": string; }, options?: any): FetchArgs {
        // verify required parameter "tenantId" is set
        if (params["tenantId"] == null) {
            throw new Error("Missing required parameter tenantId when calling apiV1ConfigurationLoadTrekTenantIdGet");
        }
        const baseUrl = `/api/v1/configuration/loadTrek/{tenantId}`
            .replace(`{${"tenantId"}}`, `${ params["tenantId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * To save the Lytx Configurations
     * @param tenantId 
     * @param body 
     */
    apiV1ConfigurationLytxSavePost(params: {  "tenantId"?: string; "body"?: Array<SingleLytxCredentials>; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/configuration/lytxSave`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "tenantId": params["tenantId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * To load the Lytx details
     * @param tenantId 
     */
    apiV1ConfigurationLytxTenantIdGet(params: {  "tenantId": string; }, options?: any): FetchArgs {
        // verify required parameter "tenantId" is set
        if (params["tenantId"] == null) {
            throw new Error("Missing required parameter tenantId when calling apiV1ConfigurationLytxTenantIdGet");
        }
        const baseUrl = `/api/v1/configuration/lytx/{tenantId}`
            .replace(`{${"tenantId"}}`, `${ params["tenantId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * To save the Netradyne Configurations
     * @param ftpAddress 
     * @param basePath 
     * @param ftpUsername 
     * @param ftpPassword 
     * @param tenantId 
     */
    apiV1ConfigurationNetradyneSavePost(params: {  "ftpAddress"?: string; "basePath"?: string; "ftpUsername"?: string; "ftpPassword"?: string; "tenantId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/configuration/netradyneSave`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "FtpAddress": params["ftpAddress"],
            "BasePath": params["basePath"],
            "FtpUsername": params["ftpUsername"],
            "FtpPassword": params["ftpPassword"],
            "tenantId": params["tenantId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * To load the Netradyne details
     * @param tenantId 
     */
    apiV1ConfigurationNetradyneTenantIdGet(params: {  "tenantId": string; }, options?: any): FetchArgs {
        // verify required parameter "tenantId" is set
        if (params["tenantId"] == null) {
            throw new Error("Missing required parameter tenantId when calling apiV1ConfigurationNetradyneTenantIdGet");
        }
        const baseUrl = `/api/v1/configuration/netradyne/{tenantId}`
            .replace(`{${"tenantId"}}`, `${ params["tenantId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Save OmniOne credentials
     * @param username 
     * @param password 
     * @param tenantId 
     */
    apiV1ConfigurationOmniOneSavePost(params: {  "username"?: string; "password"?: string; "tenantId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/configuration/omniOneSave`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "Username": params["username"],
            "Password": params["password"],
            "tenantId": params["tenantId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Load OmniOne credentials
     * @param tenantId 
     */
    apiV1ConfigurationOmniOneTenantIdGet(params: {  "tenantId": string; }, options?: any): FetchArgs {
        // verify required parameter "tenantId" is set
        if (params["tenantId"] == null) {
            throw new Error("Missing required parameter tenantId when calling apiV1ConfigurationOmniOneTenantIdGet");
        }
        const baseUrl = `/api/v1/configuration/omniOne/{tenantId}`
            .replace(`{${"tenantId"}}`, `${ params["tenantId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * To save the OmnitracsAuthLogs Configurations
     * @param authCompany 
     * @param authPassword 
     * @param authUsername 
     * @param tenantId 
     */
    apiV1ConfigurationOmnitracsAuthLogsSavePost(params: {  "authCompany"?: string; "authPassword"?: string; "authUsername"?: string; "tenantId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/configuration/omnitracsAuthLogsSave`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "AuthCompany": params["authCompany"],
            "AuthPassword": params["authPassword"],
            "AuthUsername": params["authUsername"],
            "tenantId": params["tenantId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * To load the OmnitracsAuthLogs details
     * @param tenantId 
     */
    apiV1ConfigurationOmnitracsAuthLogsTenantIdGet(params: {  "tenantId": string; }, options?: any): FetchArgs {
        // verify required parameter "tenantId" is set
        if (params["tenantId"] == null) {
            throw new Error("Missing required parameter tenantId when calling apiV1ConfigurationOmnitracsAuthLogsTenantIdGet");
        }
        const baseUrl = `/api/v1/configuration/omnitracsAuthLogs/{tenantId}`
            .replace(`{${"tenantId"}}`, `${ params["tenantId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * To load the omnitracs configuration based on tenantId
     * @param tenantId 
     */
    apiV1ConfigurationOmnitracsLoadTenantIdGet(params: {  "tenantId": string; }, options?: any): FetchArgs {
        // verify required parameter "tenantId" is set
        if (params["tenantId"] == null) {
            throw new Error("Missing required parameter tenantId when calling apiV1ConfigurationOmnitracsLoadTenantIdGet");
        }
        const baseUrl = `/api/v1/configuration/omnitracsLoad/{tenantId}`
            .replace(`{${"tenantId"}}`, `${ params["tenantId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * To Save the omnitracs configuration.
     * @param username 
     * @param password 
     * @param company 
     * @param depotsEndpoint 
     * @param driversEndpoint 
     * @param tenantId 
     */
    apiV1ConfigurationOmnitracsSavePost(params: {  "username"?: string; "password"?: string; "company"?: string; "depotsEndpoint"?: string; "driversEndpoint"?: string; "tenantId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/configuration/omnitracsSave`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "Username": params["username"],
            "Password": params["password"],
            "Company": params["company"],
            "DepotsEndpoint": params["depotsEndpoint"],
            "DriversEndpoint": params["driversEndpoint"],
            "tenantId": params["tenantId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * To load the omnitracsXRS configuration based on tenantId
     * @param tenantId 
     */
    apiV1ConfigurationOmnitracsXRSLoadTenantIdGet(params: {  "tenantId": string; }, options?: any): FetchArgs {
        // verify required parameter "tenantId" is set
        if (params["tenantId"] == null) {
            throw new Error("Missing required parameter tenantId when calling apiV1ConfigurationOmnitracsXRSLoadTenantIdGet");
        }
        const baseUrl = `/api/v1/configuration/omnitracsXRSLoad/{tenantId}`
            .replace(`{${"tenantId"}}`, `${ params["tenantId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * To Save the omnitracsXRS configuration.
     * @param url 
     * @param username 
     * @param password 
     * @param companyId 
     * @param tenantId 
     */
    apiV1ConfigurationOmnitracsXRSSavePost(params: {  "url"?: string; "username"?: string; "password"?: string; "companyId"?: string; "tenantId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/configuration/omnitracsXRSSave`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "Url": params["url"],
            "Username": params["username"],
            "Password": params["password"],
            "CompanyId": params["companyId"],
            "tenantId": params["tenantId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Save Pedigree credentials
     * @param username 
     * @param password 
     * @param tenantId 
     */
    apiV1ConfigurationPedigreeSavePost(params: {  "username"?: string; "password"?: string; "tenantId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/configuration/pedigreeSave`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "Username": params["username"],
            "Password": params["password"],
            "tenantId": params["tenantId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Load Pedigree credentials
     * @param tenantId 
     */
    apiV1ConfigurationPedigreeTenantIdGet(params: {  "tenantId": string; }, options?: any): FetchArgs {
        // verify required parameter "tenantId" is set
        if (params["tenantId"] == null) {
            throw new Error("Missing required parameter tenantId when calling apiV1ConfigurationPedigreeTenantIdGet");
        }
        const baseUrl = `/api/v1/configuration/pedigree/{tenantId}`
            .replace(`{${"tenantId"}}`, `${ params["tenantId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * To load the peoplenet configuration based on tenantId
     * @param tenantId 
     */
    apiV1ConfigurationPeoplenetLoadTenantIdGet(params: {  "tenantId": string; }, options?: any): FetchArgs {
        // verify required parameter "tenantId" is set
        if (params["tenantId"] == null) {
            throw new Error("Missing required parameter tenantId when calling apiV1ConfigurationPeoplenetLoadTenantIdGet");
        }
        const baseUrl = `/api/v1/configuration/peoplenetLoad/{tenantId}`
            .replace(`{${"tenantId"}}`, `${ params["tenantId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * To Save the peoplenet configuration.
     * @param CID 
     * @param password 
     * @param tenantId 
     */
    apiV1ConfigurationPeoplenetSavePost(params: {  "CID"?: string; "password"?: string; "tenantId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/configuration/peoplenetSave`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "CID": params["CID"],
            "Password": params["password"],
            "tenantId": params["tenantId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Save PlatformScience credentials
     * @param baseUrl 
     * @param bearerToken 
     * @param carrierIdFilter 
     * @param tenantId 
     */
    apiV1ConfigurationPlatformScienceSavePost(params: {  "baseUrl"?: string; "bearerToken"?: string; "carrierIdFilter"?: string; "tenantId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/configuration/platformScienceSave`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "BaseUrl": params["baseUrl"],
            "BearerToken": params["bearerToken"],
            "CarrierIdFilter": params["carrierIdFilter"],
            "tenantId": params["tenantId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Load PlatformScience credentials
     * @param tenantId 
     */
    apiV1ConfigurationPlatformScienceTenantIdGet(params: {  "tenantId": string; }, options?: any): FetchArgs {
        // verify required parameter "tenantId" is set
        if (params["tenantId"] == null) {
            throw new Error("Missing required parameter tenantId when calling apiV1ConfigurationPlatformScienceTenantIdGet");
        }
        const baseUrl = `/api/v1/configuration/platformScience/{tenantId}`
            .replace(`{${"tenantId"}}`, `${ params["tenantId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Save Reveal credentials
     * @param appId 
     * @param password 
     * @param url 
     * @param username 
     * @param tenantId 
     */
    apiV1ConfigurationRevealSavePost(params: {  "appId"?: string; "password"?: string; "url"?: string; "username"?: string; "tenantId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/configuration/revealSave`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "AppId": params["appId"],
            "Password": params["password"],
            "Url": params["url"],
            "Username": params["username"],
            "tenantId": params["tenantId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Load Reveal credentials
     * @param tenantId 
     */
    apiV1ConfigurationRevealTenantIdGet(params: {  "tenantId": string; }, options?: any): FetchArgs {
        // verify required parameter "tenantId" is set
        if (params["tenantId"] == null) {
            throw new Error("Missing required parameter tenantId when calling apiV1ConfigurationRevealTenantIdGet");
        }
        const baseUrl = `/api/v1/configuration/reveal/{tenantId}`
            .replace(`{${"tenantId"}}`, `${ params["tenantId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * To save the Roadnet Mobile configurations
     * @param username 
     * @param password 
     * @param subscriberId 
     * @param tenantId 
     */
    apiV1ConfigurationRoadnetMobileSavePost(params: {  "username"?: string; "password"?: string; "subscriberId"?: string; "tenantId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/configuration/RoadnetMobileSave`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "Username": params["username"],
            "Password": params["password"],
            "SubscriberId": params["subscriberId"],
            "tenantId": params["tenantId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * To load the RoadnetMobile details
     * @param tenantId 
     */
    apiV1ConfigurationRoadnetMobileTenantIdGet(params: {  "tenantId": string; }, options?: any): FetchArgs {
        // verify required parameter "tenantId" is set
        if (params["tenantId"] == null) {
            throw new Error("Missing required parameter tenantId when calling apiV1ConfigurationRoadnetMobileTenantIdGet");
        }
        const baseUrl = `/api/v1/configuration/RoadnetMobile/{tenantId}`
            .replace(`{${"tenantId"}}`, `${ params["tenantId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * To Save the SFTP credentials
     * @param ftpAddress 
     * @param ftpUsername 
     * @param ftpPassword 
     * @param tenantId 
     */
    apiV1ConfigurationSFTPSavePost(params: {  "ftpAddress"?: string; "ftpUsername"?: string; "ftpPassword"?: string; "tenantId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/configuration/SFTPSave`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "FtpAddress": params["ftpAddress"],
            "FtpUsername": params["ftpUsername"],
            "FtpPassword": params["ftpPassword"],
            "tenantId": params["tenantId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * To load the SFTP configuration details
     * @param tenantId 
     */
    apiV1ConfigurationSFTPTenantIdGet(params: {  "tenantId": string; }, options?: any): FetchArgs {
        // verify required parameter "tenantId" is set
        if (params["tenantId"] == null) {
            throw new Error("Missing required parameter tenantId when calling apiV1ConfigurationSFTPTenantIdGet");
        }
        const baseUrl = `/api/v1/configuration/SFTP/{tenantId}`
            .replace(`{${"tenantId"}}`, `${ params["tenantId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * To save the samsara configurations
     * @param url 
     * @param accessToken 
     * @param tenantId 
     */
    apiV1ConfigurationSamsaraSavePost(params: {  "url"?: string; "accessToken"?: string; "tenantId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/configuration/SamsaraSave`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "Url": params["url"],
            "AccessToken": params["accessToken"],
            "tenantId": params["tenantId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * To load the samsara the details
     * @param tenantId 
     */
    apiV1ConfigurationSamsaraTenantIdGet(params: {  "tenantId": string; }, options?: any): FetchArgs {
        // verify required parameter "tenantId" is set
        if (params["tenantId"] == null) {
            throw new Error("Missing required parameter tenantId when calling apiV1ConfigurationSamsaraTenantIdGet");
        }
        const baseUrl = `/api/v1/configuration/samsara/{tenantId}`
            .replace(`{${"tenantId"}}`, `${ params["tenantId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * To save the shaw configurations
     * @param username 
     * @param password 
     * @param companyId 
     * @param tenantId 
     */
    apiV1ConfigurationShawSavePost(params: {  "username"?: string; "password"?: string; "companyId"?: string; "tenantId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/configuration/ShawSave`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "Username": params["username"],
            "Password": params["password"],
            "CompanyId": params["companyId"],
            "tenantId": params["tenantId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * To load the shaw the details
     * @param tenantId 
     */
    apiV1ConfigurationShawTenantIdGet(params: {  "tenantId": string; }, options?: any): FetchArgs {
        // verify required parameter "tenantId" is set
        if (params["tenantId"] == null) {
            throw new Error("Missing required parameter tenantId when calling apiV1ConfigurationShawTenantIdGet");
        }
        const baseUrl = `/api/v1/configuration/shaw/{tenantId}`
            .replace(`{${"tenantId"}}`, `${ params["tenantId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * To load the smart drive details
     * @param tenantId 
     */
    apiV1ConfigurationSmartDriveLoadTenantIdGet(params: {  "tenantId": string; }, options?: any): FetchArgs {
        // verify required parameter "tenantId" is set
        if (params["tenantId"] == null) {
            throw new Error("Missing required parameter tenantId when calling apiV1ConfigurationSmartDriveLoadTenantIdGet");
        }
        const baseUrl = `/api/v1/configuration/smartDriveLoad/{tenantId}`
            .replace(`{${"tenantId"}}`, `${ params["tenantId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * To save the smart drive details
     * @param bearer 
     * @param siteName 
     * @param url 
     * @param tenantId 
     */
    apiV1ConfigurationSmartDriveSavePost(params: {  "bearer"?: string; "siteName"?: string; "url"?: string; "tenantId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/configuration/smartDriveSave`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "Bearer": params["bearer"],
            "SiteName": params["siteName"],
            "Url": params["url"],
            "tenantId": params["tenantId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * To Save the teletrac configuration.
     * @param username 
     * @param password 
     * @param tenantId 
     * @param accountId 
     * @param tenantId2 
     */
    apiV1ConfigurationTeletracSavePost(params: {  "username"?: string; "password"?: string; "tenantId"?: string; "accountId"?: string; "tenantId2"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/configuration/teletracSave`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "Username": params["username"],
            "Password": params["password"],
            "TenantId": params["tenantId"],
            "AccountId": params["accountId"],
            "tenantId": params["tenantId2"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * To load the teletrac configuration based on tenantId
     * @param tenantId 
     */
    apiV1ConfigurationTeletracTenantIdGet(params: {  "tenantId": string; }, options?: any): FetchArgs {
        // verify required parameter "tenantId" is set
        if (params["tenantId"] == null) {
            throw new Error("Missing required parameter tenantId when calling apiV1ConfigurationTeletracTenantIdGet");
        }
        const baseUrl = `/api/v1/configuration/teletrac/{tenantId}`
            .replace(`{${"tenantId"}}`, `${ params["tenantId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * To load the telogis configuration based on tenantId
     * @param tenantId 
     */
    apiV1ConfigurationTelogisLoadTenantIdGet(params: {  "tenantId": string; }, options?: any): FetchArgs {
        // verify required parameter "tenantId" is set
        if (params["tenantId"] == null) {
            throw new Error("Missing required parameter tenantId when calling apiV1ConfigurationTelogisLoadTenantIdGet");
        }
        const baseUrl = `/api/v1/configuration/telogisLoad/{tenantId}`
            .replace(`{${"tenantId"}}`, `${ params["tenantId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * To Save the telogis configuration.
     * @param userName 
     * @param password 
     * @param companyID 
     * @param tenantId 
     */
    apiV1ConfigurationTelogisSavePost(params: {  "userName"?: string; "password"?: string; "companyID"?: string; "tenantId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/configuration/telogisSave`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "UserName": params["userName"],
            "Password": params["password"],
            "CompanyID": params["companyID"],
            "tenantId": params["tenantId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Save TrackIt credentials
     * @param webKey 
     * @param timeZoneString 
     * @param tenantId 
     */
    apiV1ConfigurationTrackItSavePost(params: {  "webKey"?: string; "timeZoneString"?: string; "tenantId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/configuration/trackItSave`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "WebKey": params["webKey"],
            "TimeZoneString": params["timeZoneString"],
            "tenantId": params["tenantId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Load TrackIt credentials
     * @param tenantId 
     */
    apiV1ConfigurationTrackItTenantIdGet(params: {  "tenantId": string; }, options?: any): FetchArgs {
        // verify required parameter "tenantId" is set
        if (params["tenantId"] == null) {
            throw new Error("Missing required parameter tenantId when calling apiV1ConfigurationTrackItTenantIdGet");
        }
        const baseUrl = `/api/v1/configuration/trackIt/{tenantId}`
            .replace(`{${"tenantId"}}`, `${ params["tenantId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * To Save the geotab configuration.
     * @param providerType 
     * @param body 
     */
    apiV1ConfigurationValidatePost(params: {  "providerType"?: string; "body"?: any; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/configuration/validate`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "providerType": params["providerType"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Gets a vehicle count report by filename
     * @param filename 
     */
    apiV1ConfigurationVehicleCountReportFilenameGet(params: {  "filename": string; }, options?: any): FetchArgs {
        // verify required parameter "filename" is set
        if (params["filename"] == null) {
            throw new Error("Missing required parameter filename when calling apiV1ConfigurationVehicleCountReportFilenameGet");
        }
        const baseUrl = `/api/v1/configuration/vehicleCountReport/{filename}`
            .replace(`{${"filename"}}`, `${ params["filename"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Save VisTracks credentials
     * @param username 
     * @param password 
     * @param tenantId 
     */
    apiV1ConfigurationVisTracksSavePost(params: {  "username"?: string; "password"?: string; "tenantId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/configuration/visTracksSave`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "Username": params["username"],
            "Password": params["password"],
            "tenantId": params["tenantId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Load VisTracks credentials
     * @param tenantId 
     */
    apiV1ConfigurationVisTracksTenantIdGet(params: {  "tenantId": string; }, options?: any): FetchArgs {
        // verify required parameter "tenantId" is set
        if (params["tenantId"] == null) {
            throw new Error("Missing required parameter tenantId when calling apiV1ConfigurationVisTracksTenantIdGet");
        }
        const baseUrl = `/api/v1/configuration/visTracks/{tenantId}`
            .replace(`{${"tenantId"}}`, `${ params["tenantId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * To save the zonar configurations
     * @param username 
     * @param password 
     * @param url 
     * @param tenantId 
     */
    apiV1ConfigurationZonarSavePost(params: {  "username"?: string; "password"?: string; "url"?: string; "tenantId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/configuration/ZonarSave`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "Username": params["username"],
            "Password": params["password"],
            "Url": params["url"],
            "tenantId": params["tenantId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * To load the zonar the details
     * @param tenantId 
     */
    apiV1ConfigurationZonarTenantIdGet(params: {  "tenantId": string; }, options?: any): FetchArgs {
        // verify required parameter "tenantId" is set
        if (params["tenantId"] == null) {
            throw new Error("Missing required parameter tenantId when calling apiV1ConfigurationZonarTenantIdGet");
        }
        const baseUrl = `/api/v1/configuration/zonar/{tenantId}`
            .replace(`{${"tenantId"}}`, `${ params["tenantId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Save Lytx error report email recipients
     * @param tenantId 
     * @param body 
     */
    apiV1ContactsLytxSavePost(params: {  "tenantId"?: string; "body"?: LytxContacts; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/contacts/lytxSave`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "tenantId": params["tenantId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Load Lytx error report email recipients
     * @param tenantId 
     */
    apiV1ContactsLytxTenantIdGet(params: {  "tenantId": string; }, options?: any): FetchArgs {
        // verify required parameter "tenantId" is set
        if (params["tenantId"] == null) {
            throw new Error("Missing required parameter tenantId when calling apiV1ContactsLytxTenantIdGet");
        }
        const baseUrl = `/api/v1/contacts/lytx/{tenantId}`
            .replace(`{${"tenantId"}}`, `${ params["tenantId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Save Lytx email recipients
     * @param tenantId 
     * @param body 
     */
    apiV1EmailRecipientsLytxSavePost(params: {  "tenantId"?: string; "body"?: LytxEmailRecipients; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/emailRecipients/lytxSave`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "tenantId": params["tenantId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Load Lytx Email Recipients
     * @param tenantId 
     */
    apiV1EmailRecipientsLytxTenantIdGet(params: {  "tenantId": string; }, options?: any): FetchArgs {
        // verify required parameter "tenantId" is set
        if (params["tenantId"] == null) {
            throw new Error("Missing required parameter tenantId when calling apiV1EmailRecipientsLytxTenantIdGet");
        }
        const baseUrl = `/api/v1/emailRecipients/lytx/{tenantId}`
            .replace(`{${"tenantId"}}`, `${ params["tenantId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * ConfigurationApi - functional programming interface
 */
export const ConfigurationApiFp = {
    /** 
     * Save AdminFTP credentials
     * @param transferServiceType 
     * @param portNumber 
     * @param ftpAddress 
     * @param ftpUsername 
     * @param ftpPassword 
     * @param tenantId 
     */
    apiV1ConfigurationAdminFtpSavePost(params: { "transferServiceType"?: string; "portNumber"?: number; "ftpAddress"?: string; "ftpUsername"?: string; "ftpPassword"?: string; "tenantId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationAdminFtpSavePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Load AdminFTP credentials
     * @param tenantId 
     */
    apiV1ConfigurationAdminFtpTenantIdGet(params: { "tenantId": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdminFtpCredentialsResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationAdminFtpTenantIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * To load the BigChange details
     * @param tenantId 
     */
    apiV1ConfigurationBigChangeLoadTenantIdGet(params: { "tenantId": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BigChangeCredentialsResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationBigChangeLoadTenantIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * To save the BigChange configurations
     * @param username 
     * @param password 
     * @param apiKey 
     * @param baseUrl 
     * @param tenantId 
     */
    apiV1ConfigurationBigChangeSavePost(params: { "username"?: string; "password"?: string; "apiKey"?: string; "baseUrl"?: string; "tenantId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationBigChangeSavePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * To save the BigRoad configurations
     * @param apiKey 
     * @param useTruckId 
     * @param tenantId 
     */
    apiV1ConfigurationBigRoadSavePost(params: { "apiKey"?: string; "useTruckId"?: boolean; "tenantId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationBigRoadSavePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * To load the BigRoad details
     * @param tenantId 
     */
    apiV1ConfigurationBigRoadTenantIdGet(params: { "tenantId": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BigRoadCredentialsResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationBigRoadTenantIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * To save the blueTree configurations
     * @param username 
     * @param password 
     * @param tenantId 
     */
    apiV1ConfigurationBlueTreeSavePost(params: { "username"?: string; "password"?: string; "tenantId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationBlueTreeSavePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * To load the blueTree the details
     * @param tenantId 
     */
    apiV1ConfigurationBlueTreeTenantIdGet(params: { "tenantId": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<BlueTreeCredentialsResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationBlueTreeTenantIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * To Save the CADEC credentials
     * @param clientId 
     * @param tenantId 
     */
    apiV1ConfigurationCADECSavePost(params: { "clientId"?: string; "tenantId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationCADECSavePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * To Get the CADEC details
     * @param tenantId 
     */
    apiV1ConfigurationCADECTenantIdGet(params: { "tenantId": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CADECCredentialsResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationCADECTenantIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Save Coretex credentials
     * @param username 
     * @param password 
     * @param apiKey 
     * @param tenantId 
     */
    apiV1ConfigurationCoretexSavePost(params: { "username"?: string; "password"?: string; "apiKey"?: string; "tenantId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationCoretexSavePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Load Coretex credentials
     * @param tenantId 
     */
    apiV1ConfigurationCoretexTenantIdGet(params: { "tenantId": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CoretexCredentialsResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationCoretexTenantIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Save DigitalFleet credentials
     * @param apiKey 
     * @param driverIdKey 
     * @param tenantId 
     */
    apiV1ConfigurationDigitalFleetSavePost(params: { "apiKey"?: string; "driverIdKey"?: string; "tenantId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationDigitalFleetSavePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Load DigitalFleet credentials
     * @param tenantId 
     */
    apiV1ConfigurationDigitalFleetTenantIdGet(params: { "tenantId": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DigitalFleetCredentialsResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationDigitalFleetTenantIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * To load the drivetech configuration based on tenantId
     * @param tenantId 
     */
    apiV1ConfigurationDrivertechLoadTenantIdGet(params: { "tenantId": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DriverTechCredentialsResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationDrivertechLoadTenantIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * To Save the drivetech configuration.
     * @param username 
     * @param password 
     * @param datastoreName 
     * @param companyCode 
     * @param tenantId 
     */
    apiV1ConfigurationDrivertechSavePost(params: { "username"?: string; "password"?: string; "datastoreName"?: string; "companyCode"?: string; "tenantId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationDrivertechSavePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * To load the emailCSV configuration based on tenantId
     * @param tenantId 
     */
    apiV1ConfigurationEmailCSVLoadTenantIdGet(params: { "tenantId": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EmailCSVCredentialsResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationEmailCSVLoadTenantIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * To Save the emailCSV configuration.
     * @param messageHeaderIdentificationPrefix 
     * @param graphClientId 
     * @param graphTenantId 
     * @param graphUsername 
     * @param tenantId 
     */
    apiV1ConfigurationEmailCSVSavePost(params: { "messageHeaderIdentificationPrefix"?: string; "graphClientId"?: string; "graphTenantId"?: string; "graphUsername"?: string; "tenantId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationEmailCSVSavePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Save ERoads credentials
     * @param apiKey 
     * @param tenantId 
     */
    apiV1ConfigurationEroadsSavePost(params: { "apiKey"?: string; "tenantId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationEroadsSavePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Load ERoads credentials
     * @param tenantId 
     */
    apiV1ConfigurationEroadsTenantIdGet(params: { "tenantId": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ERoadsCredentialsResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationEroadsTenantIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get a file list from data for a tenant with {prefix}
     * @param tenantId 
     * @param prefix 
     */
    apiV1ConfigurationFilelistTenantIdPrefixGet(params: { "tenantId": string; "prefix": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StringListResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationFilelistTenantIdPrefixGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * To load the geotab configuration based on tenantId
     * @param tenantId 
     */
    apiV1ConfigurationGeotabLoadTenantIdGet(params: { "tenantId": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GeotabCredentialsResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationGeotabLoadTenantIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * To Save the geotab configuration.
     * @param database 
     * @param password 
     * @param server 
     * @param userName 
     * @param serviceOptions 
     * @param tenantId 
     */
    apiV1ConfigurationGeotabSavePost(params: { "database"?: string; "password"?: string; "server"?: string; "userName"?: string; "serviceOptions"?: Array<string>; "tenantId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationGeotabSavePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * To save the ISAACInstruments Credentials
     * @param authenticationUsername 
     * @param authenticationPassword 
     * @param authenticationGrantType 
     * @param basicToken 
     * @param hostUrl 
     * @param tenantId 
     */
    apiV1ConfigurationISAACInstrumentsSavePost(params: { "authenticationUsername"?: string; "authenticationPassword"?: string; "authenticationGrantType"?: string; "basicToken"?: string; "hostUrl"?: string; "tenantId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationISAACInstrumentsSavePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * To load the ISAACInstruments details
     * @param tenantId 
     */
    apiV1ConfigurationISAACInstrumentsTenantIdGet(params: { "tenantId": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ISAACInstrumentsCredentialsResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationISAACInstrumentsTenantIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * To load the JJKeller configuration based on tenantId
     * @param tenantId 
     */
    apiV1ConfigurationJjkellerLoadTenantIdGet(params: { "tenantId": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JJKellerCredentialsResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationJjkellerLoadTenantIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * To Save the JJKeller configuration.
     * @param userName 
     * @param password 
     * @param tenantId 
     */
    apiV1ConfigurationJjkellerSavePost(params: { "userName"?: string; "password"?: string; "tenantId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationJjkellerSavePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * To load the keepTruckin the details
     * @param tenantId 
     */
    apiV1ConfigurationKeepTruckinTenantIdGet(params: { "tenantId": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<KeepTruckinCredentialsResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationKeepTruckinTenantIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * To save the keeptruckin configurations
     * @param apiKey 
     * @param url 
     * @param companyId 
     * @param sharedSecret 
     * @param tenantId 
     */
    apiV1ConfigurationKeeptruckinSavePost(params: { "apiKey"?: string; "url"?: string; "companyId"?: string; "sharedSecret"?: string; "tenantId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationKeeptruckinSavePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Save LoadTrek credentials
     * @param username 
     * @param password 
     * @param company 
     * @param driverGroup 
     * @param tenantId 
     */
    apiV1ConfigurationLoadTrekSavePost(params: { "username"?: string; "password"?: string; "company"?: string; "driverGroup"?: string; "tenantId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationLoadTrekSavePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Load LoadTrek credentials
     * @param tenantId 
     */
    apiV1ConfigurationLoadTrekTenantIdGet(params: { "tenantId": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LoadTrekCredentialsResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationLoadTrekTenantIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * To save the Lytx Configurations
     * @param tenantId 
     * @param body 
     */
    apiV1ConfigurationLytxSavePost(params: { "tenantId"?: string; "body"?: Array<SingleLytxCredentials>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationLytxSavePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * To load the Lytx details
     * @param tenantId 
     */
    apiV1ConfigurationLytxTenantIdGet(params: { "tenantId": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LytxCredentialsResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationLytxTenantIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * To save the Netradyne Configurations
     * @param ftpAddress 
     * @param basePath 
     * @param ftpUsername 
     * @param ftpPassword 
     * @param tenantId 
     */
    apiV1ConfigurationNetradyneSavePost(params: { "ftpAddress"?: string; "basePath"?: string; "ftpUsername"?: string; "ftpPassword"?: string; "tenantId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationNetradyneSavePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * To load the Netradyne details
     * @param tenantId 
     */
    apiV1ConfigurationNetradyneTenantIdGet(params: { "tenantId": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<NetradyneCredentialsResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationNetradyneTenantIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Save OmniOne credentials
     * @param username 
     * @param password 
     * @param tenantId 
     */
    apiV1ConfigurationOmniOneSavePost(params: { "username"?: string; "password"?: string; "tenantId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationOmniOneSavePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Load OmniOne credentials
     * @param tenantId 
     */
    apiV1ConfigurationOmniOneTenantIdGet(params: { "tenantId": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OmniOneCredentialsResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationOmniOneTenantIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * To save the OmnitracsAuthLogs Configurations
     * @param authCompany 
     * @param authPassword 
     * @param authUsername 
     * @param tenantId 
     */
    apiV1ConfigurationOmnitracsAuthLogsSavePost(params: { "authCompany"?: string; "authPassword"?: string; "authUsername"?: string; "tenantId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationOmnitracsAuthLogsSavePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * To load the OmnitracsAuthLogs details
     * @param tenantId 
     */
    apiV1ConfigurationOmnitracsAuthLogsTenantIdGet(params: { "tenantId": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OmnitracsAuthLogsCredentialsResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationOmnitracsAuthLogsTenantIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * To load the omnitracs configuration based on tenantId
     * @param tenantId 
     */
    apiV1ConfigurationOmnitracsLoadTenantIdGet(params: { "tenantId": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OmnitracsCredentialsResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationOmnitracsLoadTenantIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * To Save the omnitracs configuration.
     * @param username 
     * @param password 
     * @param company 
     * @param depotsEndpoint 
     * @param driversEndpoint 
     * @param tenantId 
     */
    apiV1ConfigurationOmnitracsSavePost(params: { "username"?: string; "password"?: string; "company"?: string; "depotsEndpoint"?: string; "driversEndpoint"?: string; "tenantId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationOmnitracsSavePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * To load the omnitracsXRS configuration based on tenantId
     * @param tenantId 
     */
    apiV1ConfigurationOmnitracsXRSLoadTenantIdGet(params: { "tenantId": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OmnitracsXRSCredentialsResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationOmnitracsXRSLoadTenantIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * To Save the omnitracsXRS configuration.
     * @param url 
     * @param username 
     * @param password 
     * @param companyId 
     * @param tenantId 
     */
    apiV1ConfigurationOmnitracsXRSSavePost(params: { "url"?: string; "username"?: string; "password"?: string; "companyId"?: string; "tenantId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationOmnitracsXRSSavePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Save Pedigree credentials
     * @param username 
     * @param password 
     * @param tenantId 
     */
    apiV1ConfigurationPedigreeSavePost(params: { "username"?: string; "password"?: string; "tenantId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationPedigreeSavePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Load Pedigree credentials
     * @param tenantId 
     */
    apiV1ConfigurationPedigreeTenantIdGet(params: { "tenantId": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PedigreeCredentialsResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationPedigreeTenantIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * To load the peoplenet configuration based on tenantId
     * @param tenantId 
     */
    apiV1ConfigurationPeoplenetLoadTenantIdGet(params: { "tenantId": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PeoplenetCredentialsResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationPeoplenetLoadTenantIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * To Save the peoplenet configuration.
     * @param CID 
     * @param password 
     * @param tenantId 
     */
    apiV1ConfigurationPeoplenetSavePost(params: { "CID"?: string; "password"?: string; "tenantId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationPeoplenetSavePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Save PlatformScience credentials
     * @param baseUrl 
     * @param bearerToken 
     * @param carrierIdFilter 
     * @param tenantId 
     */
    apiV1ConfigurationPlatformScienceSavePost(params: { "baseUrl"?: string; "bearerToken"?: string; "carrierIdFilter"?: string; "tenantId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationPlatformScienceSavePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Load PlatformScience credentials
     * @param tenantId 
     */
    apiV1ConfigurationPlatformScienceTenantIdGet(params: { "tenantId": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PlatformScienceCredentialsResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationPlatformScienceTenantIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Save Reveal credentials
     * @param appId 
     * @param password 
     * @param url 
     * @param username 
     * @param tenantId 
     */
    apiV1ConfigurationRevealSavePost(params: { "appId"?: string; "password"?: string; "url"?: string; "username"?: string; "tenantId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationRevealSavePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Load Reveal credentials
     * @param tenantId 
     */
    apiV1ConfigurationRevealTenantIdGet(params: { "tenantId": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RevealCredentialsResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationRevealTenantIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * To save the Roadnet Mobile configurations
     * @param username 
     * @param password 
     * @param subscriberId 
     * @param tenantId 
     */
    apiV1ConfigurationRoadnetMobileSavePost(params: { "username"?: string; "password"?: string; "subscriberId"?: string; "tenantId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationRoadnetMobileSavePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * To load the RoadnetMobile details
     * @param tenantId 
     */
    apiV1ConfigurationRoadnetMobileTenantIdGet(params: { "tenantId": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RoadnetMobileCredentialsResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationRoadnetMobileTenantIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * To Save the SFTP credentials
     * @param ftpAddress 
     * @param ftpUsername 
     * @param ftpPassword 
     * @param tenantId 
     */
    apiV1ConfigurationSFTPSavePost(params: { "ftpAddress"?: string; "ftpUsername"?: string; "ftpPassword"?: string; "tenantId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationSFTPSavePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * To load the SFTP configuration details
     * @param tenantId 
     */
    apiV1ConfigurationSFTPTenantIdGet(params: { "tenantId": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SFTPCredentialsResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationSFTPTenantIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * To save the samsara configurations
     * @param url 
     * @param accessToken 
     * @param tenantId 
     */
    apiV1ConfigurationSamsaraSavePost(params: { "url"?: string; "accessToken"?: string; "tenantId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationSamsaraSavePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * To load the samsara the details
     * @param tenantId 
     */
    apiV1ConfigurationSamsaraTenantIdGet(params: { "tenantId": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SamsaraCredentialsResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationSamsaraTenantIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * To save the shaw configurations
     * @param username 
     * @param password 
     * @param companyId 
     * @param tenantId 
     */
    apiV1ConfigurationShawSavePost(params: { "username"?: string; "password"?: string; "companyId"?: string; "tenantId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationShawSavePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * To load the shaw the details
     * @param tenantId 
     */
    apiV1ConfigurationShawTenantIdGet(params: { "tenantId": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ShawCredentialsResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationShawTenantIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * To load the smart drive details
     * @param tenantId 
     */
    apiV1ConfigurationSmartDriveLoadTenantIdGet(params: { "tenantId": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SmartDriveCredentialsResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationSmartDriveLoadTenantIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * To save the smart drive details
     * @param bearer 
     * @param siteName 
     * @param url 
     * @param tenantId 
     */
    apiV1ConfigurationSmartDriveSavePost(params: { "bearer"?: string; "siteName"?: string; "url"?: string; "tenantId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationSmartDriveSavePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * To Save the teletrac configuration.
     * @param username 
     * @param password 
     * @param tenantId 
     * @param accountId 
     * @param tenantId2 
     */
    apiV1ConfigurationTeletracSavePost(params: { "username"?: string; "password"?: string; "tenantId"?: string; "accountId"?: string; "tenantId2"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationTeletracSavePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * To load the teletrac configuration based on tenantId
     * @param tenantId 
     */
    apiV1ConfigurationTeletracTenantIdGet(params: { "tenantId": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TeletracCredentialsResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationTeletracTenantIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * To load the telogis configuration based on tenantId
     * @param tenantId 
     */
    apiV1ConfigurationTelogisLoadTenantIdGet(params: { "tenantId": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TelogisCredentialsResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationTelogisLoadTenantIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * To Save the telogis configuration.
     * @param userName 
     * @param password 
     * @param companyID 
     * @param tenantId 
     */
    apiV1ConfigurationTelogisSavePost(params: { "userName"?: string; "password"?: string; "companyID"?: string; "tenantId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationTelogisSavePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Save TrackIt credentials
     * @param webKey 
     * @param timeZoneString 
     * @param tenantId 
     */
    apiV1ConfigurationTrackItSavePost(params: { "webKey"?: string; "timeZoneString"?: string; "tenantId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationTrackItSavePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Load TrackIt credentials
     * @param tenantId 
     */
    apiV1ConfigurationTrackItTenantIdGet(params: { "tenantId": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TrackItCredentialsResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationTrackItTenantIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * To Save the geotab configuration.
     * @param providerType 
     * @param body 
     */
    apiV1ConfigurationValidatePost(params: { "providerType"?: string; "body"?: any;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VdasValidationResult> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationValidatePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Gets a vehicle count report by filename
     * @param filename 
     */
    apiV1ConfigurationVehicleCountReportFilenameGet(params: { "filename": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VehicleCountReportViewModelResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationVehicleCountReportFilenameGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Save VisTracks credentials
     * @param username 
     * @param password 
     * @param tenantId 
     */
    apiV1ConfigurationVisTracksSavePost(params: { "username"?: string; "password"?: string; "tenantId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationVisTracksSavePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Load VisTracks credentials
     * @param tenantId 
     */
    apiV1ConfigurationVisTracksTenantIdGet(params: { "tenantId": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VisTracksCredentialsResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationVisTracksTenantIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * To save the zonar configurations
     * @param username 
     * @param password 
     * @param url 
     * @param tenantId 
     */
    apiV1ConfigurationZonarSavePost(params: { "username"?: string; "password"?: string; "url"?: string; "tenantId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationZonarSavePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * To load the zonar the details
     * @param tenantId 
     */
    apiV1ConfigurationZonarTenantIdGet(params: { "tenantId": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ZonarCredentialsResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ConfigurationZonarTenantIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Save Lytx error report email recipients
     * @param tenantId 
     * @param body 
     */
    apiV1ContactsLytxSavePost(params: { "tenantId"?: string; "body"?: LytxContacts;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ContactsLytxSavePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Load Lytx error report email recipients
     * @param tenantId 
     */
    apiV1ContactsLytxTenantIdGet(params: { "tenantId": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LytxContactsResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1ContactsLytxTenantIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Save Lytx email recipients
     * @param tenantId 
     * @param body 
     */
    apiV1EmailRecipientsLytxSavePost(params: { "tenantId"?: string; "body"?: LytxEmailRecipients;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1EmailRecipientsLytxSavePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Load Lytx Email Recipients
     * @param tenantId 
     */
    apiV1EmailRecipientsLytxTenantIdGet(params: { "tenantId": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LytxEmailRecipientsResponseBase> {
        const fetchArgs = ConfigurationApiFetchParamCreator.apiV1EmailRecipientsLytxTenantIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * ConfigurationApi - object-oriented interface
 */
export class ConfigurationApi extends BaseAPI {
    /** 
     * Save AdminFTP credentials
     * @param transferServiceType 
     * @param portNumber 
     * @param ftpAddress 
     * @param ftpUsername 
     * @param ftpPassword 
     * @param tenantId 
     */
    apiV1ConfigurationAdminFtpSavePost(params: {  "transferServiceType"?: string; "portNumber"?: number; "ftpAddress"?: string; "ftpUsername"?: string; "ftpPassword"?: string; "tenantId"?: string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationAdminFtpSavePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Load AdminFTP credentials
     * @param tenantId 
     */
    apiV1ConfigurationAdminFtpTenantIdGet(params: {  "tenantId": string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationAdminFtpTenantIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * To load the BigChange details
     * @param tenantId 
     */
    apiV1ConfigurationBigChangeLoadTenantIdGet(params: {  "tenantId": string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationBigChangeLoadTenantIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * To save the BigChange configurations
     * @param username 
     * @param password 
     * @param apiKey 
     * @param baseUrl 
     * @param tenantId 
     */
    apiV1ConfigurationBigChangeSavePost(params: {  "username"?: string; "password"?: string; "apiKey"?: string; "baseUrl"?: string; "tenantId"?: string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationBigChangeSavePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * To save the BigRoad configurations
     * @param apiKey 
     * @param useTruckId 
     * @param tenantId 
     */
    apiV1ConfigurationBigRoadSavePost(params: {  "apiKey"?: string; "useTruckId"?: boolean; "tenantId"?: string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationBigRoadSavePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * To load the BigRoad details
     * @param tenantId 
     */
    apiV1ConfigurationBigRoadTenantIdGet(params: {  "tenantId": string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationBigRoadTenantIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * To save the blueTree configurations
     * @param username 
     * @param password 
     * @param tenantId 
     */
    apiV1ConfigurationBlueTreeSavePost(params: {  "username"?: string; "password"?: string; "tenantId"?: string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationBlueTreeSavePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * To load the blueTree the details
     * @param tenantId 
     */
    apiV1ConfigurationBlueTreeTenantIdGet(params: {  "tenantId": string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationBlueTreeTenantIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * To Save the CADEC credentials
     * @param clientId 
     * @param tenantId 
     */
    apiV1ConfigurationCADECSavePost(params: {  "clientId"?: string; "tenantId"?: string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationCADECSavePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * To Get the CADEC details
     * @param tenantId 
     */
    apiV1ConfigurationCADECTenantIdGet(params: {  "tenantId": string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationCADECTenantIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Save Coretex credentials
     * @param username 
     * @param password 
     * @param apiKey 
     * @param tenantId 
     */
    apiV1ConfigurationCoretexSavePost(params: {  "username"?: string; "password"?: string; "apiKey"?: string; "tenantId"?: string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationCoretexSavePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Load Coretex credentials
     * @param tenantId 
     */
    apiV1ConfigurationCoretexTenantIdGet(params: {  "tenantId": string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationCoretexTenantIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Save DigitalFleet credentials
     * @param apiKey 
     * @param driverIdKey 
     * @param tenantId 
     */
    apiV1ConfigurationDigitalFleetSavePost(params: {  "apiKey"?: string; "driverIdKey"?: string; "tenantId"?: string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationDigitalFleetSavePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Load DigitalFleet credentials
     * @param tenantId 
     */
    apiV1ConfigurationDigitalFleetTenantIdGet(params: {  "tenantId": string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationDigitalFleetTenantIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * To load the drivetech configuration based on tenantId
     * @param tenantId 
     */
    apiV1ConfigurationDrivertechLoadTenantIdGet(params: {  "tenantId": string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationDrivertechLoadTenantIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * To Save the drivetech configuration.
     * @param username 
     * @param password 
     * @param datastoreName 
     * @param companyCode 
     * @param tenantId 
     */
    apiV1ConfigurationDrivertechSavePost(params: {  "username"?: string; "password"?: string; "datastoreName"?: string; "companyCode"?: string; "tenantId"?: string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationDrivertechSavePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * To load the emailCSV configuration based on tenantId
     * @param tenantId 
     */
    apiV1ConfigurationEmailCSVLoadTenantIdGet(params: {  "tenantId": string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationEmailCSVLoadTenantIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * To Save the emailCSV configuration.
     * @param messageHeaderIdentificationPrefix 
     * @param graphClientId 
     * @param graphTenantId 
     * @param graphUsername 
     * @param tenantId 
     */
    apiV1ConfigurationEmailCSVSavePost(params: {  "messageHeaderIdentificationPrefix"?: string; "graphClientId"?: string; "graphTenantId"?: string; "graphUsername"?: string; "tenantId"?: string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationEmailCSVSavePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Save ERoads credentials
     * @param apiKey 
     * @param tenantId 
     */
    apiV1ConfigurationEroadsSavePost(params: {  "apiKey"?: string; "tenantId"?: string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationEroadsSavePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Load ERoads credentials
     * @param tenantId 
     */
    apiV1ConfigurationEroadsTenantIdGet(params: {  "tenantId": string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationEroadsTenantIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get a file list from data for a tenant with {prefix}
     * @param tenantId 
     * @param prefix 
     */
    apiV1ConfigurationFilelistTenantIdPrefixGet(params: {  "tenantId": string; "prefix": string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationFilelistTenantIdPrefixGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * To load the geotab configuration based on tenantId
     * @param tenantId 
     */
    apiV1ConfigurationGeotabLoadTenantIdGet(params: {  "tenantId": string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationGeotabLoadTenantIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * To Save the geotab configuration.
     * @param database 
     * @param password 
     * @param server 
     * @param userName 
     * @param serviceOptions 
     * @param tenantId 
     */
    apiV1ConfigurationGeotabSavePost(params: {  "database"?: string; "password"?: string; "server"?: string; "userName"?: string; "serviceOptions"?: Array<string>; "tenantId"?: string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationGeotabSavePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * To save the ISAACInstruments Credentials
     * @param authenticationUsername 
     * @param authenticationPassword 
     * @param authenticationGrantType 
     * @param basicToken 
     * @param hostUrl 
     * @param tenantId 
     */
    apiV1ConfigurationISAACInstrumentsSavePost(params: {  "authenticationUsername"?: string; "authenticationPassword"?: string; "authenticationGrantType"?: string; "basicToken"?: string; "hostUrl"?: string; "tenantId"?: string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationISAACInstrumentsSavePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * To load the ISAACInstruments details
     * @param tenantId 
     */
    apiV1ConfigurationISAACInstrumentsTenantIdGet(params: {  "tenantId": string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationISAACInstrumentsTenantIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * To load the JJKeller configuration based on tenantId
     * @param tenantId 
     */
    apiV1ConfigurationJjkellerLoadTenantIdGet(params: {  "tenantId": string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationJjkellerLoadTenantIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * To Save the JJKeller configuration.
     * @param userName 
     * @param password 
     * @param tenantId 
     */
    apiV1ConfigurationJjkellerSavePost(params: {  "userName"?: string; "password"?: string; "tenantId"?: string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationJjkellerSavePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * To load the keepTruckin the details
     * @param tenantId 
     */
    apiV1ConfigurationKeepTruckinTenantIdGet(params: {  "tenantId": string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationKeepTruckinTenantIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * To save the keeptruckin configurations
     * @param apiKey 
     * @param url 
     * @param companyId 
     * @param sharedSecret 
     * @param tenantId 
     */
    apiV1ConfigurationKeeptruckinSavePost(params: {  "apiKey"?: string; "url"?: string; "companyId"?: string; "sharedSecret"?: string; "tenantId"?: string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationKeeptruckinSavePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Save LoadTrek credentials
     * @param username 
     * @param password 
     * @param company 
     * @param driverGroup 
     * @param tenantId 
     */
    apiV1ConfigurationLoadTrekSavePost(params: {  "username"?: string; "password"?: string; "company"?: string; "driverGroup"?: string; "tenantId"?: string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationLoadTrekSavePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Load LoadTrek credentials
     * @param tenantId 
     */
    apiV1ConfigurationLoadTrekTenantIdGet(params: {  "tenantId": string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationLoadTrekTenantIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * To save the Lytx Configurations
     * @param tenantId 
     * @param body 
     */
    apiV1ConfigurationLytxSavePost(params: {  "tenantId"?: string; "body"?: Array<SingleLytxCredentials>; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationLytxSavePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * To load the Lytx details
     * @param tenantId 
     */
    apiV1ConfigurationLytxTenantIdGet(params: {  "tenantId": string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationLytxTenantIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * To save the Netradyne Configurations
     * @param ftpAddress 
     * @param basePath 
     * @param ftpUsername 
     * @param ftpPassword 
     * @param tenantId 
     */
    apiV1ConfigurationNetradyneSavePost(params: {  "ftpAddress"?: string; "basePath"?: string; "ftpUsername"?: string; "ftpPassword"?: string; "tenantId"?: string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationNetradyneSavePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * To load the Netradyne details
     * @param tenantId 
     */
    apiV1ConfigurationNetradyneTenantIdGet(params: {  "tenantId": string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationNetradyneTenantIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Save OmniOne credentials
     * @param username 
     * @param password 
     * @param tenantId 
     */
    apiV1ConfigurationOmniOneSavePost(params: {  "username"?: string; "password"?: string; "tenantId"?: string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationOmniOneSavePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Load OmniOne credentials
     * @param tenantId 
     */
    apiV1ConfigurationOmniOneTenantIdGet(params: {  "tenantId": string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationOmniOneTenantIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * To save the OmnitracsAuthLogs Configurations
     * @param authCompany 
     * @param authPassword 
     * @param authUsername 
     * @param tenantId 
     */
    apiV1ConfigurationOmnitracsAuthLogsSavePost(params: {  "authCompany"?: string; "authPassword"?: string; "authUsername"?: string; "tenantId"?: string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationOmnitracsAuthLogsSavePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * To load the OmnitracsAuthLogs details
     * @param tenantId 
     */
    apiV1ConfigurationOmnitracsAuthLogsTenantIdGet(params: {  "tenantId": string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationOmnitracsAuthLogsTenantIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * To load the omnitracs configuration based on tenantId
     * @param tenantId 
     */
    apiV1ConfigurationOmnitracsLoadTenantIdGet(params: {  "tenantId": string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationOmnitracsLoadTenantIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * To Save the omnitracs configuration.
     * @param username 
     * @param password 
     * @param company 
     * @param depotsEndpoint 
     * @param driversEndpoint 
     * @param tenantId 
     */
    apiV1ConfigurationOmnitracsSavePost(params: {  "username"?: string; "password"?: string; "company"?: string; "depotsEndpoint"?: string; "driversEndpoint"?: string; "tenantId"?: string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationOmnitracsSavePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * To load the omnitracsXRS configuration based on tenantId
     * @param tenantId 
     */
    apiV1ConfigurationOmnitracsXRSLoadTenantIdGet(params: {  "tenantId": string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationOmnitracsXRSLoadTenantIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * To Save the omnitracsXRS configuration.
     * @param url 
     * @param username 
     * @param password 
     * @param companyId 
     * @param tenantId 
     */
    apiV1ConfigurationOmnitracsXRSSavePost(params: {  "url"?: string; "username"?: string; "password"?: string; "companyId"?: string; "tenantId"?: string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationOmnitracsXRSSavePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Save Pedigree credentials
     * @param username 
     * @param password 
     * @param tenantId 
     */
    apiV1ConfigurationPedigreeSavePost(params: {  "username"?: string; "password"?: string; "tenantId"?: string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationPedigreeSavePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Load Pedigree credentials
     * @param tenantId 
     */
    apiV1ConfigurationPedigreeTenantIdGet(params: {  "tenantId": string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationPedigreeTenantIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * To load the peoplenet configuration based on tenantId
     * @param tenantId 
     */
    apiV1ConfigurationPeoplenetLoadTenantIdGet(params: {  "tenantId": string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationPeoplenetLoadTenantIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * To Save the peoplenet configuration.
     * @param CID 
     * @param password 
     * @param tenantId 
     */
    apiV1ConfigurationPeoplenetSavePost(params: {  "CID"?: string; "password"?: string; "tenantId"?: string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationPeoplenetSavePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Save PlatformScience credentials
     * @param baseUrl 
     * @param bearerToken 
     * @param carrierIdFilter 
     * @param tenantId 
     */
    apiV1ConfigurationPlatformScienceSavePost(params: {  "baseUrl"?: string; "bearerToken"?: string; "carrierIdFilter"?: string; "tenantId"?: string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationPlatformScienceSavePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Load PlatformScience credentials
     * @param tenantId 
     */
    apiV1ConfigurationPlatformScienceTenantIdGet(params: {  "tenantId": string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationPlatformScienceTenantIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Save Reveal credentials
     * @param appId 
     * @param password 
     * @param url 
     * @param username 
     * @param tenantId 
     */
    apiV1ConfigurationRevealSavePost(params: {  "appId"?: string; "password"?: string; "url"?: string; "username"?: string; "tenantId"?: string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationRevealSavePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Load Reveal credentials
     * @param tenantId 
     */
    apiV1ConfigurationRevealTenantIdGet(params: {  "tenantId": string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationRevealTenantIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * To save the Roadnet Mobile configurations
     * @param username 
     * @param password 
     * @param subscriberId 
     * @param tenantId 
     */
    apiV1ConfigurationRoadnetMobileSavePost(params: {  "username"?: string; "password"?: string; "subscriberId"?: string; "tenantId"?: string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationRoadnetMobileSavePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * To load the RoadnetMobile details
     * @param tenantId 
     */
    apiV1ConfigurationRoadnetMobileTenantIdGet(params: {  "tenantId": string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationRoadnetMobileTenantIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * To Save the SFTP credentials
     * @param ftpAddress 
     * @param ftpUsername 
     * @param ftpPassword 
     * @param tenantId 
     */
    apiV1ConfigurationSFTPSavePost(params: {  "ftpAddress"?: string; "ftpUsername"?: string; "ftpPassword"?: string; "tenantId"?: string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationSFTPSavePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * To load the SFTP configuration details
     * @param tenantId 
     */
    apiV1ConfigurationSFTPTenantIdGet(params: {  "tenantId": string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationSFTPTenantIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * To save the samsara configurations
     * @param url 
     * @param accessToken 
     * @param tenantId 
     */
    apiV1ConfigurationSamsaraSavePost(params: {  "url"?: string; "accessToken"?: string; "tenantId"?: string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationSamsaraSavePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * To load the samsara the details
     * @param tenantId 
     */
    apiV1ConfigurationSamsaraTenantIdGet(params: {  "tenantId": string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationSamsaraTenantIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * To save the shaw configurations
     * @param username 
     * @param password 
     * @param companyId 
     * @param tenantId 
     */
    apiV1ConfigurationShawSavePost(params: {  "username"?: string; "password"?: string; "companyId"?: string; "tenantId"?: string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationShawSavePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * To load the shaw the details
     * @param tenantId 
     */
    apiV1ConfigurationShawTenantIdGet(params: {  "tenantId": string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationShawTenantIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * To load the smart drive details
     * @param tenantId 
     */
    apiV1ConfigurationSmartDriveLoadTenantIdGet(params: {  "tenantId": string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationSmartDriveLoadTenantIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * To save the smart drive details
     * @param bearer 
     * @param siteName 
     * @param url 
     * @param tenantId 
     */
    apiV1ConfigurationSmartDriveSavePost(params: {  "bearer"?: string; "siteName"?: string; "url"?: string; "tenantId"?: string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationSmartDriveSavePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * To Save the teletrac configuration.
     * @param username 
     * @param password 
     * @param tenantId 
     * @param accountId 
     * @param tenantId2 
     */
    apiV1ConfigurationTeletracSavePost(params: {  "username"?: string; "password"?: string; "tenantId"?: string; "accountId"?: string; "tenantId2"?: string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationTeletracSavePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * To load the teletrac configuration based on tenantId
     * @param tenantId 
     */
    apiV1ConfigurationTeletracTenantIdGet(params: {  "tenantId": string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationTeletracTenantIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * To load the telogis configuration based on tenantId
     * @param tenantId 
     */
    apiV1ConfigurationTelogisLoadTenantIdGet(params: {  "tenantId": string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationTelogisLoadTenantIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * To Save the telogis configuration.
     * @param userName 
     * @param password 
     * @param companyID 
     * @param tenantId 
     */
    apiV1ConfigurationTelogisSavePost(params: {  "userName"?: string; "password"?: string; "companyID"?: string; "tenantId"?: string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationTelogisSavePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Save TrackIt credentials
     * @param webKey 
     * @param timeZoneString 
     * @param tenantId 
     */
    apiV1ConfigurationTrackItSavePost(params: {  "webKey"?: string; "timeZoneString"?: string; "tenantId"?: string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationTrackItSavePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Load TrackIt credentials
     * @param tenantId 
     */
    apiV1ConfigurationTrackItTenantIdGet(params: {  "tenantId": string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationTrackItTenantIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * To Save the geotab configuration.
     * @param providerType 
     * @param body 
     */
    apiV1ConfigurationValidatePost(params: {  "providerType"?: string; "body"?: any; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationValidatePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Gets a vehicle count report by filename
     * @param filename 
     */
    apiV1ConfigurationVehicleCountReportFilenameGet(params: {  "filename": string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationVehicleCountReportFilenameGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Save VisTracks credentials
     * @param username 
     * @param password 
     * @param tenantId 
     */
    apiV1ConfigurationVisTracksSavePost(params: {  "username"?: string; "password"?: string; "tenantId"?: string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationVisTracksSavePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Load VisTracks credentials
     * @param tenantId 
     */
    apiV1ConfigurationVisTracksTenantIdGet(params: {  "tenantId": string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationVisTracksTenantIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * To save the zonar configurations
     * @param username 
     * @param password 
     * @param url 
     * @param tenantId 
     */
    apiV1ConfigurationZonarSavePost(params: {  "username"?: string; "password"?: string; "url"?: string; "tenantId"?: string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationZonarSavePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * To load the zonar the details
     * @param tenantId 
     */
    apiV1ConfigurationZonarTenantIdGet(params: {  "tenantId": string; }, options?: any) {
        return ConfigurationApiFp.apiV1ConfigurationZonarTenantIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Save Lytx error report email recipients
     * @param tenantId 
     * @param body 
     */
    apiV1ContactsLytxSavePost(params: {  "tenantId"?: string; "body"?: LytxContacts; }, options?: any) {
        return ConfigurationApiFp.apiV1ContactsLytxSavePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Load Lytx error report email recipients
     * @param tenantId 
     */
    apiV1ContactsLytxTenantIdGet(params: {  "tenantId": string; }, options?: any) {
        return ConfigurationApiFp.apiV1ContactsLytxTenantIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Save Lytx email recipients
     * @param tenantId 
     * @param body 
     */
    apiV1EmailRecipientsLytxSavePost(params: {  "tenantId"?: string; "body"?: LytxEmailRecipients; }, options?: any) {
        return ConfigurationApiFp.apiV1EmailRecipientsLytxSavePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Load Lytx Email Recipients
     * @param tenantId 
     */
    apiV1EmailRecipientsLytxTenantIdGet(params: {  "tenantId": string; }, options?: any) {
        return ConfigurationApiFp.apiV1EmailRecipientsLytxTenantIdGet(params, options)(this.fetch, this.basePath);
    }
};

/**
 * ConfigurationApi - factory interface
 */
export const ConfigurationApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Save AdminFTP credentials
         * @param transferServiceType 
         * @param portNumber 
         * @param ftpAddress 
         * @param ftpUsername 
         * @param ftpPassword 
         * @param tenantId 
         */
        apiV1ConfigurationAdminFtpSavePost(params: {  "transferServiceType"?: string; "portNumber"?: number; "ftpAddress"?: string; "ftpUsername"?: string; "ftpPassword"?: string; "tenantId"?: string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationAdminFtpSavePost(params, options)(fetch, basePath);
        },
        /** 
         * Load AdminFTP credentials
         * @param tenantId 
         */
        apiV1ConfigurationAdminFtpTenantIdGet(params: {  "tenantId": string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationAdminFtpTenantIdGet(params, options)(fetch, basePath);
        },
        /** 
         * To load the BigChange details
         * @param tenantId 
         */
        apiV1ConfigurationBigChangeLoadTenantIdGet(params: {  "tenantId": string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationBigChangeLoadTenantIdGet(params, options)(fetch, basePath);
        },
        /** 
         * To save the BigChange configurations
         * @param username 
         * @param password 
         * @param apiKey 
         * @param baseUrl 
         * @param tenantId 
         */
        apiV1ConfigurationBigChangeSavePost(params: {  "username"?: string; "password"?: string; "apiKey"?: string; "baseUrl"?: string; "tenantId"?: string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationBigChangeSavePost(params, options)(fetch, basePath);
        },
        /** 
         * To save the BigRoad configurations
         * @param apiKey 
         * @param useTruckId 
         * @param tenantId 
         */
        apiV1ConfigurationBigRoadSavePost(params: {  "apiKey"?: string; "useTruckId"?: boolean; "tenantId"?: string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationBigRoadSavePost(params, options)(fetch, basePath);
        },
        /** 
         * To load the BigRoad details
         * @param tenantId 
         */
        apiV1ConfigurationBigRoadTenantIdGet(params: {  "tenantId": string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationBigRoadTenantIdGet(params, options)(fetch, basePath);
        },
        /** 
         * To save the blueTree configurations
         * @param username 
         * @param password 
         * @param tenantId 
         */
        apiV1ConfigurationBlueTreeSavePost(params: {  "username"?: string; "password"?: string; "tenantId"?: string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationBlueTreeSavePost(params, options)(fetch, basePath);
        },
        /** 
         * To load the blueTree the details
         * @param tenantId 
         */
        apiV1ConfigurationBlueTreeTenantIdGet(params: {  "tenantId": string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationBlueTreeTenantIdGet(params, options)(fetch, basePath);
        },
        /** 
         * To Save the CADEC credentials
         * @param clientId 
         * @param tenantId 
         */
        apiV1ConfigurationCADECSavePost(params: {  "clientId"?: string; "tenantId"?: string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationCADECSavePost(params, options)(fetch, basePath);
        },
        /** 
         * To Get the CADEC details
         * @param tenantId 
         */
        apiV1ConfigurationCADECTenantIdGet(params: {  "tenantId": string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationCADECTenantIdGet(params, options)(fetch, basePath);
        },
        /** 
         * Save Coretex credentials
         * @param username 
         * @param password 
         * @param apiKey 
         * @param tenantId 
         */
        apiV1ConfigurationCoretexSavePost(params: {  "username"?: string; "password"?: string; "apiKey"?: string; "tenantId"?: string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationCoretexSavePost(params, options)(fetch, basePath);
        },
        /** 
         * Load Coretex credentials
         * @param tenantId 
         */
        apiV1ConfigurationCoretexTenantIdGet(params: {  "tenantId": string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationCoretexTenantIdGet(params, options)(fetch, basePath);
        },
        /** 
         * Save DigitalFleet credentials
         * @param apiKey 
         * @param driverIdKey 
         * @param tenantId 
         */
        apiV1ConfigurationDigitalFleetSavePost(params: {  "apiKey"?: string; "driverIdKey"?: string; "tenantId"?: string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationDigitalFleetSavePost(params, options)(fetch, basePath);
        },
        /** 
         * Load DigitalFleet credentials
         * @param tenantId 
         */
        apiV1ConfigurationDigitalFleetTenantIdGet(params: {  "tenantId": string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationDigitalFleetTenantIdGet(params, options)(fetch, basePath);
        },
        /** 
         * To load the drivetech configuration based on tenantId
         * @param tenantId 
         */
        apiV1ConfigurationDrivertechLoadTenantIdGet(params: {  "tenantId": string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationDrivertechLoadTenantIdGet(params, options)(fetch, basePath);
        },
        /** 
         * To Save the drivetech configuration.
         * @param username 
         * @param password 
         * @param datastoreName 
         * @param companyCode 
         * @param tenantId 
         */
        apiV1ConfigurationDrivertechSavePost(params: {  "username"?: string; "password"?: string; "datastoreName"?: string; "companyCode"?: string; "tenantId"?: string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationDrivertechSavePost(params, options)(fetch, basePath);
        },
        /** 
         * To load the emailCSV configuration based on tenantId
         * @param tenantId 
         */
        apiV1ConfigurationEmailCSVLoadTenantIdGet(params: {  "tenantId": string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationEmailCSVLoadTenantIdGet(params, options)(fetch, basePath);
        },
        /** 
         * To Save the emailCSV configuration.
         * @param messageHeaderIdentificationPrefix 
         * @param graphClientId 
         * @param graphTenantId 
         * @param graphUsername 
         * @param tenantId 
         */
        apiV1ConfigurationEmailCSVSavePost(params: {  "messageHeaderIdentificationPrefix"?: string; "graphClientId"?: string; "graphTenantId"?: string; "graphUsername"?: string; "tenantId"?: string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationEmailCSVSavePost(params, options)(fetch, basePath);
        },
        /** 
         * Save ERoads credentials
         * @param apiKey 
         * @param tenantId 
         */
        apiV1ConfigurationEroadsSavePost(params: {  "apiKey"?: string; "tenantId"?: string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationEroadsSavePost(params, options)(fetch, basePath);
        },
        /** 
         * Load ERoads credentials
         * @param tenantId 
         */
        apiV1ConfigurationEroadsTenantIdGet(params: {  "tenantId": string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationEroadsTenantIdGet(params, options)(fetch, basePath);
        },
        /** 
         * Get a file list from data for a tenant with {prefix}
         * @param tenantId 
         * @param prefix 
         */
        apiV1ConfigurationFilelistTenantIdPrefixGet(params: {  "tenantId": string; "prefix": string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationFilelistTenantIdPrefixGet(params, options)(fetch, basePath);
        },
        /** 
         * To load the geotab configuration based on tenantId
         * @param tenantId 
         */
        apiV1ConfigurationGeotabLoadTenantIdGet(params: {  "tenantId": string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationGeotabLoadTenantIdGet(params, options)(fetch, basePath);
        },
        /** 
         * To Save the geotab configuration.
         * @param database 
         * @param password 
         * @param server 
         * @param userName 
         * @param serviceOptions 
         * @param tenantId 
         */
        apiV1ConfigurationGeotabSavePost(params: {  "database"?: string; "password"?: string; "server"?: string; "userName"?: string; "serviceOptions"?: Array<string>; "tenantId"?: string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationGeotabSavePost(params, options)(fetch, basePath);
        },
        /** 
         * To save the ISAACInstruments Credentials
         * @param authenticationUsername 
         * @param authenticationPassword 
         * @param authenticationGrantType 
         * @param basicToken 
         * @param hostUrl 
         * @param tenantId 
         */
        apiV1ConfigurationISAACInstrumentsSavePost(params: {  "authenticationUsername"?: string; "authenticationPassword"?: string; "authenticationGrantType"?: string; "basicToken"?: string; "hostUrl"?: string; "tenantId"?: string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationISAACInstrumentsSavePost(params, options)(fetch, basePath);
        },
        /** 
         * To load the ISAACInstruments details
         * @param tenantId 
         */
        apiV1ConfigurationISAACInstrumentsTenantIdGet(params: {  "tenantId": string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationISAACInstrumentsTenantIdGet(params, options)(fetch, basePath);
        },
        /** 
         * To load the JJKeller configuration based on tenantId
         * @param tenantId 
         */
        apiV1ConfigurationJjkellerLoadTenantIdGet(params: {  "tenantId": string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationJjkellerLoadTenantIdGet(params, options)(fetch, basePath);
        },
        /** 
         * To Save the JJKeller configuration.
         * @param userName 
         * @param password 
         * @param tenantId 
         */
        apiV1ConfigurationJjkellerSavePost(params: {  "userName"?: string; "password"?: string; "tenantId"?: string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationJjkellerSavePost(params, options)(fetch, basePath);
        },
        /** 
         * To load the keepTruckin the details
         * @param tenantId 
         */
        apiV1ConfigurationKeepTruckinTenantIdGet(params: {  "tenantId": string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationKeepTruckinTenantIdGet(params, options)(fetch, basePath);
        },
        /** 
         * To save the keeptruckin configurations
         * @param apiKey 
         * @param url 
         * @param companyId 
         * @param sharedSecret 
         * @param tenantId 
         */
        apiV1ConfigurationKeeptruckinSavePost(params: {  "apiKey"?: string; "url"?: string; "companyId"?: string; "sharedSecret"?: string; "tenantId"?: string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationKeeptruckinSavePost(params, options)(fetch, basePath);
        },
        /** 
         * Save LoadTrek credentials
         * @param username 
         * @param password 
         * @param company 
         * @param driverGroup 
         * @param tenantId 
         */
        apiV1ConfigurationLoadTrekSavePost(params: {  "username"?: string; "password"?: string; "company"?: string; "driverGroup"?: string; "tenantId"?: string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationLoadTrekSavePost(params, options)(fetch, basePath);
        },
        /** 
         * Load LoadTrek credentials
         * @param tenantId 
         */
        apiV1ConfigurationLoadTrekTenantIdGet(params: {  "tenantId": string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationLoadTrekTenantIdGet(params, options)(fetch, basePath);
        },
        /** 
         * To save the Lytx Configurations
         * @param tenantId 
         * @param body 
         */
        apiV1ConfigurationLytxSavePost(params: {  "tenantId"?: string; "body"?: Array<SingleLytxCredentials>; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationLytxSavePost(params, options)(fetch, basePath);
        },
        /** 
         * To load the Lytx details
         * @param tenantId 
         */
        apiV1ConfigurationLytxTenantIdGet(params: {  "tenantId": string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationLytxTenantIdGet(params, options)(fetch, basePath);
        },
        /** 
         * To save the Netradyne Configurations
         * @param ftpAddress 
         * @param basePath 
         * @param ftpUsername 
         * @param ftpPassword 
         * @param tenantId 
         */
        apiV1ConfigurationNetradyneSavePost(params: {  "ftpAddress"?: string; "basePath"?: string; "ftpUsername"?: string; "ftpPassword"?: string; "tenantId"?: string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationNetradyneSavePost(params, options)(fetch, basePath);
        },
        /** 
         * To load the Netradyne details
         * @param tenantId 
         */
        apiV1ConfigurationNetradyneTenantIdGet(params: {  "tenantId": string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationNetradyneTenantIdGet(params, options)(fetch, basePath);
        },
        /** 
         * Save OmniOne credentials
         * @param username 
         * @param password 
         * @param tenantId 
         */
        apiV1ConfigurationOmniOneSavePost(params: {  "username"?: string; "password"?: string; "tenantId"?: string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationOmniOneSavePost(params, options)(fetch, basePath);
        },
        /** 
         * Load OmniOne credentials
         * @param tenantId 
         */
        apiV1ConfigurationOmniOneTenantIdGet(params: {  "tenantId": string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationOmniOneTenantIdGet(params, options)(fetch, basePath);
        },
        /** 
         * To save the OmnitracsAuthLogs Configurations
         * @param authCompany 
         * @param authPassword 
         * @param authUsername 
         * @param tenantId 
         */
        apiV1ConfigurationOmnitracsAuthLogsSavePost(params: {  "authCompany"?: string; "authPassword"?: string; "authUsername"?: string; "tenantId"?: string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationOmnitracsAuthLogsSavePost(params, options)(fetch, basePath);
        },
        /** 
         * To load the OmnitracsAuthLogs details
         * @param tenantId 
         */
        apiV1ConfigurationOmnitracsAuthLogsTenantIdGet(params: {  "tenantId": string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationOmnitracsAuthLogsTenantIdGet(params, options)(fetch, basePath);
        },
        /** 
         * To load the omnitracs configuration based on tenantId
         * @param tenantId 
         */
        apiV1ConfigurationOmnitracsLoadTenantIdGet(params: {  "tenantId": string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationOmnitracsLoadTenantIdGet(params, options)(fetch, basePath);
        },
        /** 
         * To Save the omnitracs configuration.
         * @param username 
         * @param password 
         * @param company 
         * @param depotsEndpoint 
         * @param driversEndpoint 
         * @param tenantId 
         */
        apiV1ConfigurationOmnitracsSavePost(params: {  "username"?: string; "password"?: string; "company"?: string; "depotsEndpoint"?: string; "driversEndpoint"?: string; "tenantId"?: string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationOmnitracsSavePost(params, options)(fetch, basePath);
        },
        /** 
         * To load the omnitracsXRS configuration based on tenantId
         * @param tenantId 
         */
        apiV1ConfigurationOmnitracsXRSLoadTenantIdGet(params: {  "tenantId": string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationOmnitracsXRSLoadTenantIdGet(params, options)(fetch, basePath);
        },
        /** 
         * To Save the omnitracsXRS configuration.
         * @param url 
         * @param username 
         * @param password 
         * @param companyId 
         * @param tenantId 
         */
        apiV1ConfigurationOmnitracsXRSSavePost(params: {  "url"?: string; "username"?: string; "password"?: string; "companyId"?: string; "tenantId"?: string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationOmnitracsXRSSavePost(params, options)(fetch, basePath);
        },
        /** 
         * Save Pedigree credentials
         * @param username 
         * @param password 
         * @param tenantId 
         */
        apiV1ConfigurationPedigreeSavePost(params: {  "username"?: string; "password"?: string; "tenantId"?: string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationPedigreeSavePost(params, options)(fetch, basePath);
        },
        /** 
         * Load Pedigree credentials
         * @param tenantId 
         */
        apiV1ConfigurationPedigreeTenantIdGet(params: {  "tenantId": string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationPedigreeTenantIdGet(params, options)(fetch, basePath);
        },
        /** 
         * To load the peoplenet configuration based on tenantId
         * @param tenantId 
         */
        apiV1ConfigurationPeoplenetLoadTenantIdGet(params: {  "tenantId": string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationPeoplenetLoadTenantIdGet(params, options)(fetch, basePath);
        },
        /** 
         * To Save the peoplenet configuration.
         * @param CID 
         * @param password 
         * @param tenantId 
         */
        apiV1ConfigurationPeoplenetSavePost(params: {  "CID"?: string; "password"?: string; "tenantId"?: string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationPeoplenetSavePost(params, options)(fetch, basePath);
        },
        /** 
         * Save PlatformScience credentials
         * @param baseUrl 
         * @param bearerToken 
         * @param carrierIdFilter 
         * @param tenantId 
         */
        apiV1ConfigurationPlatformScienceSavePost(params: {  "baseUrl"?: string; "bearerToken"?: string; "carrierIdFilter"?: string; "tenantId"?: string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationPlatformScienceSavePost(params, options)(fetch, basePath);
        },
        /** 
         * Load PlatformScience credentials
         * @param tenantId 
         */
        apiV1ConfigurationPlatformScienceTenantIdGet(params: {  "tenantId": string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationPlatformScienceTenantIdGet(params, options)(fetch, basePath);
        },
        /** 
         * Save Reveal credentials
         * @param appId 
         * @param password 
         * @param url 
         * @param username 
         * @param tenantId 
         */
        apiV1ConfigurationRevealSavePost(params: {  "appId"?: string; "password"?: string; "url"?: string; "username"?: string; "tenantId"?: string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationRevealSavePost(params, options)(fetch, basePath);
        },
        /** 
         * Load Reveal credentials
         * @param tenantId 
         */
        apiV1ConfigurationRevealTenantIdGet(params: {  "tenantId": string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationRevealTenantIdGet(params, options)(fetch, basePath);
        },
        /** 
         * To save the Roadnet Mobile configurations
         * @param username 
         * @param password 
         * @param subscriberId 
         * @param tenantId 
         */
        apiV1ConfigurationRoadnetMobileSavePost(params: {  "username"?: string; "password"?: string; "subscriberId"?: string; "tenantId"?: string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationRoadnetMobileSavePost(params, options)(fetch, basePath);
        },
        /** 
         * To load the RoadnetMobile details
         * @param tenantId 
         */
        apiV1ConfigurationRoadnetMobileTenantIdGet(params: {  "tenantId": string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationRoadnetMobileTenantIdGet(params, options)(fetch, basePath);
        },
        /** 
         * To Save the SFTP credentials
         * @param ftpAddress 
         * @param ftpUsername 
         * @param ftpPassword 
         * @param tenantId 
         */
        apiV1ConfigurationSFTPSavePost(params: {  "ftpAddress"?: string; "ftpUsername"?: string; "ftpPassword"?: string; "tenantId"?: string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationSFTPSavePost(params, options)(fetch, basePath);
        },
        /** 
         * To load the SFTP configuration details
         * @param tenantId 
         */
        apiV1ConfigurationSFTPTenantIdGet(params: {  "tenantId": string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationSFTPTenantIdGet(params, options)(fetch, basePath);
        },
        /** 
         * To save the samsara configurations
         * @param url 
         * @param accessToken 
         * @param tenantId 
         */
        apiV1ConfigurationSamsaraSavePost(params: {  "url"?: string; "accessToken"?: string; "tenantId"?: string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationSamsaraSavePost(params, options)(fetch, basePath);
        },
        /** 
         * To load the samsara the details
         * @param tenantId 
         */
        apiV1ConfigurationSamsaraTenantIdGet(params: {  "tenantId": string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationSamsaraTenantIdGet(params, options)(fetch, basePath);
        },
        /** 
         * To save the shaw configurations
         * @param username 
         * @param password 
         * @param companyId 
         * @param tenantId 
         */
        apiV1ConfigurationShawSavePost(params: {  "username"?: string; "password"?: string; "companyId"?: string; "tenantId"?: string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationShawSavePost(params, options)(fetch, basePath);
        },
        /** 
         * To load the shaw the details
         * @param tenantId 
         */
        apiV1ConfigurationShawTenantIdGet(params: {  "tenantId": string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationShawTenantIdGet(params, options)(fetch, basePath);
        },
        /** 
         * To load the smart drive details
         * @param tenantId 
         */
        apiV1ConfigurationSmartDriveLoadTenantIdGet(params: {  "tenantId": string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationSmartDriveLoadTenantIdGet(params, options)(fetch, basePath);
        },
        /** 
         * To save the smart drive details
         * @param bearer 
         * @param siteName 
         * @param url 
         * @param tenantId 
         */
        apiV1ConfigurationSmartDriveSavePost(params: {  "bearer"?: string; "siteName"?: string; "url"?: string; "tenantId"?: string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationSmartDriveSavePost(params, options)(fetch, basePath);
        },
        /** 
         * To Save the teletrac configuration.
         * @param username 
         * @param password 
         * @param tenantId 
         * @param accountId 
         * @param tenantId2 
         */
        apiV1ConfigurationTeletracSavePost(params: {  "username"?: string; "password"?: string; "tenantId"?: string; "accountId"?: string; "tenantId2"?: string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationTeletracSavePost(params, options)(fetch, basePath);
        },
        /** 
         * To load the teletrac configuration based on tenantId
         * @param tenantId 
         */
        apiV1ConfigurationTeletracTenantIdGet(params: {  "tenantId": string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationTeletracTenantIdGet(params, options)(fetch, basePath);
        },
        /** 
         * To load the telogis configuration based on tenantId
         * @param tenantId 
         */
        apiV1ConfigurationTelogisLoadTenantIdGet(params: {  "tenantId": string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationTelogisLoadTenantIdGet(params, options)(fetch, basePath);
        },
        /** 
         * To Save the telogis configuration.
         * @param userName 
         * @param password 
         * @param companyID 
         * @param tenantId 
         */
        apiV1ConfigurationTelogisSavePost(params: {  "userName"?: string; "password"?: string; "companyID"?: string; "tenantId"?: string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationTelogisSavePost(params, options)(fetch, basePath);
        },
        /** 
         * Save TrackIt credentials
         * @param webKey 
         * @param timeZoneString 
         * @param tenantId 
         */
        apiV1ConfigurationTrackItSavePost(params: {  "webKey"?: string; "timeZoneString"?: string; "tenantId"?: string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationTrackItSavePost(params, options)(fetch, basePath);
        },
        /** 
         * Load TrackIt credentials
         * @param tenantId 
         */
        apiV1ConfigurationTrackItTenantIdGet(params: {  "tenantId": string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationTrackItTenantIdGet(params, options)(fetch, basePath);
        },
        /** 
         * To Save the geotab configuration.
         * @param providerType 
         * @param body 
         */
        apiV1ConfigurationValidatePost(params: {  "providerType"?: string; "body"?: any; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationValidatePost(params, options)(fetch, basePath);
        },
        /** 
         * Gets a vehicle count report by filename
         * @param filename 
         */
        apiV1ConfigurationVehicleCountReportFilenameGet(params: {  "filename": string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationVehicleCountReportFilenameGet(params, options)(fetch, basePath);
        },
        /** 
         * Save VisTracks credentials
         * @param username 
         * @param password 
         * @param tenantId 
         */
        apiV1ConfigurationVisTracksSavePost(params: {  "username"?: string; "password"?: string; "tenantId"?: string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationVisTracksSavePost(params, options)(fetch, basePath);
        },
        /** 
         * Load VisTracks credentials
         * @param tenantId 
         */
        apiV1ConfigurationVisTracksTenantIdGet(params: {  "tenantId": string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationVisTracksTenantIdGet(params, options)(fetch, basePath);
        },
        /** 
         * To save the zonar configurations
         * @param username 
         * @param password 
         * @param url 
         * @param tenantId 
         */
        apiV1ConfigurationZonarSavePost(params: {  "username"?: string; "password"?: string; "url"?: string; "tenantId"?: string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationZonarSavePost(params, options)(fetch, basePath);
        },
        /** 
         * To load the zonar the details
         * @param tenantId 
         */
        apiV1ConfigurationZonarTenantIdGet(params: {  "tenantId": string; }, options?: any) {
            return ConfigurationApiFp.apiV1ConfigurationZonarTenantIdGet(params, options)(fetch, basePath);
        },
        /** 
         * Save Lytx error report email recipients
         * @param tenantId 
         * @param body 
         */
        apiV1ContactsLytxSavePost(params: {  "tenantId"?: string; "body"?: LytxContacts; }, options?: any) {
            return ConfigurationApiFp.apiV1ContactsLytxSavePost(params, options)(fetch, basePath);
        },
        /** 
         * Load Lytx error report email recipients
         * @param tenantId 
         */
        apiV1ContactsLytxTenantIdGet(params: {  "tenantId": string; }, options?: any) {
            return ConfigurationApiFp.apiV1ContactsLytxTenantIdGet(params, options)(fetch, basePath);
        },
        /** 
         * Save Lytx email recipients
         * @param tenantId 
         * @param body 
         */
        apiV1EmailRecipientsLytxSavePost(params: {  "tenantId"?: string; "body"?: LytxEmailRecipients; }, options?: any) {
            return ConfigurationApiFp.apiV1EmailRecipientsLytxSavePost(params, options)(fetch, basePath);
        },
        /** 
         * Load Lytx Email Recipients
         * @param tenantId 
         */
        apiV1EmailRecipientsLytxTenantIdGet(params: {  "tenantId": string; }, options?: any) {
            return ConfigurationApiFp.apiV1EmailRecipientsLytxTenantIdGet(params, options)(fetch, basePath);
        },
    };
};


/**
 * DashboardApi - fetch parameter creator
 */
export const DashboardApiFetchParamCreator = {
    /** 
     * Gets the adapter summary.
     */
    apiV1DashboardAdapterSummaryGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/Dashboard/AdapterSummary`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get list of delinquent tenants that the user has access to.
     */
    apiV1DashboardDelinquentTenantsGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/Dashboard/DelinquentTenants`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * DashboardApi - functional programming interface
 */
export const DashboardApiFp = {
    /** 
     * Gets the adapter summary.
     */
    apiV1DashboardAdapterSummaryGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdapterSummaryModelIEnumerableResponseBase> {
        const fetchArgs = DashboardApiFetchParamCreator.apiV1DashboardAdapterSummaryGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get list of delinquent tenants that the user has access to.
     */
    apiV1DashboardDelinquentTenantsGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DelinquentTenantViewModelIEnumerableResponseBase> {
        const fetchArgs = DashboardApiFetchParamCreator.apiV1DashboardDelinquentTenantsGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * DashboardApi - object-oriented interface
 */
export class DashboardApi extends BaseAPI {
    /** 
     * Gets the adapter summary.
     */
    apiV1DashboardAdapterSummaryGet(options?: any) {
        return DashboardApiFp.apiV1DashboardAdapterSummaryGet(options)(this.fetch, this.basePath);
    }
    /** 
     * Get list of delinquent tenants that the user has access to.
     */
    apiV1DashboardDelinquentTenantsGet(options?: any) {
        return DashboardApiFp.apiV1DashboardDelinquentTenantsGet(options)(this.fetch, this.basePath);
    }
};

/**
 * DashboardApi - factory interface
 */
export const DashboardApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Gets the adapter summary.
         */
        apiV1DashboardAdapterSummaryGet(options?: any) {
            return DashboardApiFp.apiV1DashboardAdapterSummaryGet(options)(fetch, basePath);
        },
        /** 
         * Get list of delinquent tenants that the user has access to.
         */
        apiV1DashboardDelinquentTenantsGet(options?: any) {
            return DashboardApiFp.apiV1DashboardDelinquentTenantsGet(options)(fetch, basePath);
        },
    };
};


/**
 * JobDetailApi - fetch parameter creator
 */
export const JobDetailApiFetchParamCreator = {
    /** 
     * Fetch reports for Lytx Admin
     * UserAdminActivitySnapshotReport is under the UserMaintenance link
     * @param jobId ID of job to fetch reports for
     */
    apiV1JobDetailLytxAdminReportsGet(params: {  "jobId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/JobDetail/LytxAdminReports`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "jobId": params["jobId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get drive cam submission results
     * @param jobIds ID of job to fetch submission results from
     */
    apiV1JobDetailMultipleSubmissionResultsGet(params: {  "jobIds"?: Array<string>; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/JobDetail/multipleSubmissionResults`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "jobIds": params["jobIds"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get Samsara HOS export errors for a job
     * @param jobId Job ID
     */
    apiV1JobDetailSamsaraHosExportErrorsGet(params: {  "jobId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/JobDetail/samsaraHosExportErrors`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "jobId": params["jobId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get Samsara Rair export errors for a job
     * @param jobId Job ID
     */
    apiV1JobDetailSamsaraRairExportErrorsGet(params: {  "jobId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/JobDetail/samsaraRairExportErrors`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "jobId": params["jobId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get SmartDrive export errors for a job
     * @param jobId Job ID
     */
    apiV1JobDetailSmartDriveExportErrorsGet(params: {  "jobId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/JobDetail/smartDriveExportErrors`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "jobId": params["jobId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get SmartDrive export errors for a multiple jobs
     * @param jobIds array of Job IDs
     */
    apiV1JobDetailSmartDriveMultipleJobsExportErrorsGet(params: {  "jobIds"?: Array<string>; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/JobDetail/smartDriveMultipleJobsExportErrors`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "jobIds": params["jobIds"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get drive cam submission results
     * @param jobId ID of job to fetch submission results from
     */
    apiV1JobDetailSubmissionResultsGet(params: {  "jobId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/JobDetail/submissionResults`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "jobId": params["jobId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * JobDetailApi - functional programming interface
 */
export const JobDetailApiFp = {
    /** 
     * Fetch reports for Lytx Admin
     * UserAdminActivitySnapshotReport is under the UserMaintenance link
     * @param jobId ID of job to fetch reports for
     */
    apiV1JobDetailLytxAdminReportsGet(params: { "jobId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LytxAdminReportViewListResponseBase> {
        const fetchArgs = JobDetailApiFetchParamCreator.apiV1JobDetailLytxAdminReportsGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get drive cam submission results
     * @param jobIds ID of job to fetch submission results from
     */
    apiV1JobDetailMultipleSubmissionResultsGet(params: { "jobIds"?: Array<string>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DriveCamSubmissionResultViewModelResponseBase> {
        const fetchArgs = JobDetailApiFetchParamCreator.apiV1JobDetailMultipleSubmissionResultsGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get Samsara HOS export errors for a job
     * @param jobId Job ID
     */
    apiV1JobDetailSamsaraHosExportErrorsGet(params: { "jobId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SamsaraHosExportSubmissionResultListResponseBase> {
        const fetchArgs = JobDetailApiFetchParamCreator.apiV1JobDetailSamsaraHosExportErrorsGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get Samsara Rair export errors for a job
     * @param jobId Job ID
     */
    apiV1JobDetailSamsaraRairExportErrorsGet(params: { "jobId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StringStringDictionaryResponseBase> {
        const fetchArgs = JobDetailApiFetchParamCreator.apiV1JobDetailSamsaraRairExportErrorsGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get SmartDrive export errors for a job
     * @param jobId Job ID
     */
    apiV1JobDetailSmartDriveExportErrorsGet(params: { "jobId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SmartDriveExportResultListResponseBase> {
        const fetchArgs = JobDetailApiFetchParamCreator.apiV1JobDetailSmartDriveExportErrorsGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get SmartDrive export errors for a multiple jobs
     * @param jobIds array of Job IDs
     */
    apiV1JobDetailSmartDriveMultipleJobsExportErrorsGet(params: { "jobIds"?: Array<string>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SmartDriveExportResultListResponseBase> {
        const fetchArgs = JobDetailApiFetchParamCreator.apiV1JobDetailSmartDriveMultipleJobsExportErrorsGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get drive cam submission results
     * @param jobId ID of job to fetch submission results from
     */
    apiV1JobDetailSubmissionResultsGet(params: { "jobId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DriveCamSubmissionResultViewModelResponseBase> {
        const fetchArgs = JobDetailApiFetchParamCreator.apiV1JobDetailSubmissionResultsGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * JobDetailApi - object-oriented interface
 */
export class JobDetailApi extends BaseAPI {
    /** 
     * Fetch reports for Lytx Admin
     * UserAdminActivitySnapshotReport is under the UserMaintenance link
     * @param jobId ID of job to fetch reports for
     */
    apiV1JobDetailLytxAdminReportsGet(params: {  "jobId"?: string; }, options?: any) {
        return JobDetailApiFp.apiV1JobDetailLytxAdminReportsGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get drive cam submission results
     * @param jobIds ID of job to fetch submission results from
     */
    apiV1JobDetailMultipleSubmissionResultsGet(params: {  "jobIds"?: Array<string>; }, options?: any) {
        return JobDetailApiFp.apiV1JobDetailMultipleSubmissionResultsGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get Samsara HOS export errors for a job
     * @param jobId Job ID
     */
    apiV1JobDetailSamsaraHosExportErrorsGet(params: {  "jobId"?: string; }, options?: any) {
        return JobDetailApiFp.apiV1JobDetailSamsaraHosExportErrorsGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get Samsara Rair export errors for a job
     * @param jobId Job ID
     */
    apiV1JobDetailSamsaraRairExportErrorsGet(params: {  "jobId"?: string; }, options?: any) {
        return JobDetailApiFp.apiV1JobDetailSamsaraRairExportErrorsGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get SmartDrive export errors for a job
     * @param jobId Job ID
     */
    apiV1JobDetailSmartDriveExportErrorsGet(params: {  "jobId"?: string; }, options?: any) {
        return JobDetailApiFp.apiV1JobDetailSmartDriveExportErrorsGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get SmartDrive export errors for a multiple jobs
     * @param jobIds array of Job IDs
     */
    apiV1JobDetailSmartDriveMultipleJobsExportErrorsGet(params: {  "jobIds"?: Array<string>; }, options?: any) {
        return JobDetailApiFp.apiV1JobDetailSmartDriveMultipleJobsExportErrorsGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get drive cam submission results
     * @param jobId ID of job to fetch submission results from
     */
    apiV1JobDetailSubmissionResultsGet(params: {  "jobId"?: string; }, options?: any) {
        return JobDetailApiFp.apiV1JobDetailSubmissionResultsGet(params, options)(this.fetch, this.basePath);
    }
};

/**
 * JobDetailApi - factory interface
 */
export const JobDetailApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Fetch reports for Lytx Admin
         * UserAdminActivitySnapshotReport is under the UserMaintenance link
         * @param jobId ID of job to fetch reports for
         */
        apiV1JobDetailLytxAdminReportsGet(params: {  "jobId"?: string; }, options?: any) {
            return JobDetailApiFp.apiV1JobDetailLytxAdminReportsGet(params, options)(fetch, basePath);
        },
        /** 
         * Get drive cam submission results
         * @param jobIds ID of job to fetch submission results from
         */
        apiV1JobDetailMultipleSubmissionResultsGet(params: {  "jobIds"?: Array<string>; }, options?: any) {
            return JobDetailApiFp.apiV1JobDetailMultipleSubmissionResultsGet(params, options)(fetch, basePath);
        },
        /** 
         * Get Samsara HOS export errors for a job
         * @param jobId Job ID
         */
        apiV1JobDetailSamsaraHosExportErrorsGet(params: {  "jobId"?: string; }, options?: any) {
            return JobDetailApiFp.apiV1JobDetailSamsaraHosExportErrorsGet(params, options)(fetch, basePath);
        },
        /** 
         * Get Samsara Rair export errors for a job
         * @param jobId Job ID
         */
        apiV1JobDetailSamsaraRairExportErrorsGet(params: {  "jobId"?: string; }, options?: any) {
            return JobDetailApiFp.apiV1JobDetailSamsaraRairExportErrorsGet(params, options)(fetch, basePath);
        },
        /** 
         * Get SmartDrive export errors for a job
         * @param jobId Job ID
         */
        apiV1JobDetailSmartDriveExportErrorsGet(params: {  "jobId"?: string; }, options?: any) {
            return JobDetailApiFp.apiV1JobDetailSmartDriveExportErrorsGet(params, options)(fetch, basePath);
        },
        /** 
         * Get SmartDrive export errors for a multiple jobs
         * @param jobIds array of Job IDs
         */
        apiV1JobDetailSmartDriveMultipleJobsExportErrorsGet(params: {  "jobIds"?: Array<string>; }, options?: any) {
            return JobDetailApiFp.apiV1JobDetailSmartDriveMultipleJobsExportErrorsGet(params, options)(fetch, basePath);
        },
        /** 
         * Get drive cam submission results
         * @param jobId ID of job to fetch submission results from
         */
        apiV1JobDetailSubmissionResultsGet(params: {  "jobId"?: string; }, options?: any) {
            return JobDetailApiFp.apiV1JobDetailSubmissionResultsGet(params, options)(fetch, basePath);
        },
    };
};


/**
 * LoginApi - fetch parameter creator
 */
export const LoginApiFetchParamCreator = {
    /** 
     * Logouts this instance.
     */
    apiV1LoginLogoutPost(options?: any): FetchArgs {
        const baseUrl = `/api/v1/login/logout`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Attempts to log the user in.
     * @param body 
     */
    apiV1LoginPost(params: {  "body"?: LoginViewModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/login`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Attempts to refresh the user&#39;s token so they don&#39;t have to login again.
     */
    apiV1LoginRefreshPost(options?: any): FetchArgs {
        const baseUrl = `/api/v1/login/refresh`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * LoginApi - functional programming interface
 */
export const LoginApiFp = {
    /** 
     * Logouts this instance.
     */
    apiV1LoginLogoutPost(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseBase> {
        const fetchArgs = LoginApiFetchParamCreator.apiV1LoginLogoutPost(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Attempts to log the user in.
     * @param body 
     */
    apiV1LoginPost(params: { "body"?: LoginViewModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseBase> {
        const fetchArgs = LoginApiFetchParamCreator.apiV1LoginPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Attempts to refresh the user&#39;s token so they don&#39;t have to login again.
     */
    apiV1LoginRefreshPost(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseBase> {
        const fetchArgs = LoginApiFetchParamCreator.apiV1LoginRefreshPost(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * LoginApi - object-oriented interface
 */
export class LoginApi extends BaseAPI {
    /** 
     * Logouts this instance.
     */
    apiV1LoginLogoutPost(options?: any) {
        return LoginApiFp.apiV1LoginLogoutPost(options)(this.fetch, this.basePath);
    }
    /** 
     * Attempts to log the user in.
     * @param body 
     */
    apiV1LoginPost(params: {  "body"?: LoginViewModel; }, options?: any) {
        return LoginApiFp.apiV1LoginPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Attempts to refresh the user&#39;s token so they don&#39;t have to login again.
     */
    apiV1LoginRefreshPost(options?: any) {
        return LoginApiFp.apiV1LoginRefreshPost(options)(this.fetch, this.basePath);
    }
};

/**
 * LoginApi - factory interface
 */
export const LoginApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Logouts this instance.
         */
        apiV1LoginLogoutPost(options?: any) {
            return LoginApiFp.apiV1LoginLogoutPost(options)(fetch, basePath);
        },
        /** 
         * Attempts to log the user in.
         * @param body 
         */
        apiV1LoginPost(params: {  "body"?: LoginViewModel; }, options?: any) {
            return LoginApiFp.apiV1LoginPost(params, options)(fetch, basePath);
        },
        /** 
         * Attempts to refresh the user&#39;s token so they don&#39;t have to login again.
         */
        apiV1LoginRefreshPost(options?: any) {
            return LoginApiFp.apiV1LoginRefreshPost(options)(fetch, basePath);
        },
    };
};


/**
 * ReportApi - fetch parameter creator
 */
export const ReportApiFetchParamCreator = {
    /** 
     * Get the date range that has data within it
     * @param tenantId Tenant ID
     */
    apiV1GenericReportGetDataDateRangeGet(params: {  "tenantId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/GenericReport/GetDataDateRange`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "tenantId": params["tenantId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get dictionary of day summaries for a given date range keyed on vehicle name
     * @param tenantId Tenant ID
     * @param startDate Start Date
     * @param endDate End Date
     * @param groupFullName Fullname of the group to get summaries for
     * @param vehicleName Vehicle name to get summary for
     */
    apiV1GenericReportGetDaysInRangeGet(params: {  "tenantId"?: string; "startDate"?: string; "endDate"?: string; "groupFullName"?: string; "vehicleName"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/GenericReport/GetDaysInRange`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "tenantId": params["tenantId"],
            "startDate": params["startDate"],
            "endDate": params["endDate"],
            "groupFullName": params["groupFullName"],
            "vehicleName": params["vehicleName"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get a list of group full names
     * @param tenantId Tenant ID
     */
    apiV1GenericReportGetGroupsGet(params: {  "tenantId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/GenericReport/GetGroups`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "tenantId": params["tenantId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get a list of vehicle names
     * @param tenantId Tenant ID
     * @param groupFullName Group full name
     * @param startDate Start Date
     * @param endDate End Date
     */
    apiV1GenericReportGetVehiclesGet(params: {  "tenantId"?: string; "groupFullName"?: string; "startDate"?: string; "endDate"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/GenericReport/GetVehicles`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "tenantId": params["tenantId"],
            "groupFullName": params["groupFullName"],
            "startDate": params["startDate"],
            "endDate": params["endDate"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * ReportApi - functional programming interface
 */
export const ReportApiFp = {
    /** 
     * Get the date range that has data within it
     * @param tenantId Tenant ID
     */
    apiV1GenericReportGetDataDateRangeGet(params: { "tenantId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DateTimeDateTimeTupleResponseBase> {
        const fetchArgs = ReportApiFetchParamCreator.apiV1GenericReportGetDataDateRangeGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get dictionary of day summaries for a given date range keyed on vehicle name
     * @param tenantId Tenant ID
     * @param startDate Start Date
     * @param endDate End Date
     * @param groupFullName Fullname of the group to get summaries for
     * @param vehicleName Vehicle name to get summary for
     */
    apiV1GenericReportGetDaysInRangeGet(params: { "tenantId"?: string; "startDate"?: string; "endDate"?: string; "groupFullName"?: string; "vehicleName"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StringDaySummaryListDictionaryResponseBase> {
        const fetchArgs = ReportApiFetchParamCreator.apiV1GenericReportGetDaysInRangeGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get a list of group full names
     * @param tenantId Tenant ID
     */
    apiV1GenericReportGetGroupsGet(params: { "tenantId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StringIEnumerableResponseBase> {
        const fetchArgs = ReportApiFetchParamCreator.apiV1GenericReportGetGroupsGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get a list of vehicle names
     * @param tenantId Tenant ID
     * @param groupFullName Group full name
     * @param startDate Start Date
     * @param endDate End Date
     */
    apiV1GenericReportGetVehiclesGet(params: { "tenantId"?: string; "groupFullName"?: string; "startDate"?: string; "endDate"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StringIEnumerableResponseBase> {
        const fetchArgs = ReportApiFetchParamCreator.apiV1GenericReportGetVehiclesGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * ReportApi - object-oriented interface
 */
export class ReportApi extends BaseAPI {
    /** 
     * Get the date range that has data within it
     * @param tenantId Tenant ID
     */
    apiV1GenericReportGetDataDateRangeGet(params: {  "tenantId"?: string; }, options?: any) {
        return ReportApiFp.apiV1GenericReportGetDataDateRangeGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get dictionary of day summaries for a given date range keyed on vehicle name
     * @param tenantId Tenant ID
     * @param startDate Start Date
     * @param endDate End Date
     * @param groupFullName Fullname of the group to get summaries for
     * @param vehicleName Vehicle name to get summary for
     */
    apiV1GenericReportGetDaysInRangeGet(params: {  "tenantId"?: string; "startDate"?: string; "endDate"?: string; "groupFullName"?: string; "vehicleName"?: string; }, options?: any) {
        return ReportApiFp.apiV1GenericReportGetDaysInRangeGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get a list of group full names
     * @param tenantId Tenant ID
     */
    apiV1GenericReportGetGroupsGet(params: {  "tenantId"?: string; }, options?: any) {
        return ReportApiFp.apiV1GenericReportGetGroupsGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get a list of vehicle names
     * @param tenantId Tenant ID
     * @param groupFullName Group full name
     * @param startDate Start Date
     * @param endDate End Date
     */
    apiV1GenericReportGetVehiclesGet(params: {  "tenantId"?: string; "groupFullName"?: string; "startDate"?: string; "endDate"?: string; }, options?: any) {
        return ReportApiFp.apiV1GenericReportGetVehiclesGet(params, options)(this.fetch, this.basePath);
    }
};

/**
 * ReportApi - factory interface
 */
export const ReportApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Get the date range that has data within it
         * @param tenantId Tenant ID
         */
        apiV1GenericReportGetDataDateRangeGet(params: {  "tenantId"?: string; }, options?: any) {
            return ReportApiFp.apiV1GenericReportGetDataDateRangeGet(params, options)(fetch, basePath);
        },
        /** 
         * Get dictionary of day summaries for a given date range keyed on vehicle name
         * @param tenantId Tenant ID
         * @param startDate Start Date
         * @param endDate End Date
         * @param groupFullName Fullname of the group to get summaries for
         * @param vehicleName Vehicle name to get summary for
         */
        apiV1GenericReportGetDaysInRangeGet(params: {  "tenantId"?: string; "startDate"?: string; "endDate"?: string; "groupFullName"?: string; "vehicleName"?: string; }, options?: any) {
            return ReportApiFp.apiV1GenericReportGetDaysInRangeGet(params, options)(fetch, basePath);
        },
        /** 
         * Get a list of group full names
         * @param tenantId Tenant ID
         */
        apiV1GenericReportGetGroupsGet(params: {  "tenantId"?: string; }, options?: any) {
            return ReportApiFp.apiV1GenericReportGetGroupsGet(params, options)(fetch, basePath);
        },
        /** 
         * Get a list of vehicle names
         * @param tenantId Tenant ID
         * @param groupFullName Group full name
         * @param startDate Start Date
         * @param endDate End Date
         */
        apiV1GenericReportGetVehiclesGet(params: {  "tenantId"?: string; "groupFullName"?: string; "startDate"?: string; "endDate"?: string; }, options?: any) {
            return ReportApiFp.apiV1GenericReportGetVehiclesGet(params, options)(fetch, basePath);
        },
    };
};


/**
 * TenantApi - fetch parameter creator
 */
export const TenantApiFetchParamCreator = {
    /** 
     * Activates the tenant
     * @param body 
     */
    apiV1TenantActivatePost(params: {  "body"?: TenantViewModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/tenant/activate`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Saves the tenant. If it doesn&#39;t exist yet, it creates the tenant
     * @param body 
     */
    apiV1TenantAdvancedSettingsSavePost(params: {  "body"?: TenantViewModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/tenant/advancedSettingsSave`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Create a new tenant
     * @param body 
     */
    apiV1TenantCreatePost(params: {  "body"?: NewTenantViewModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/tenant/create`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Deletes the tenant
     * @param body 
     */
    apiV1TenantDeletePost(params: {  "body"?: TenantViewModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/tenant/delete`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Deletes the tenant
     * @param body 
     */
    apiV1TenantHarddeletePost(params: {  "body"?: TenantViewModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/tenant/harddelete`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get details on specific tenant
     * @param id Tenant UId to fetch
     */
    apiV1TenantIdGet(params: {  "id": string; }, options?: any): FetchArgs {
        // verify required parameter "id" is set
        if (params["id"] == null) {
            throw new Error("Missing required parameter id when calling apiV1TenantIdGet");
        }
        const baseUrl = `/api/v1/tenant/{id}`
            .replace(`{${"id"}}`, `${ params["id"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get all monthly success summaries for tenants
     * @param isActive Active only
     */
    apiV1TenantMonthlySuccessSummaryGet(params: {  "isActive"?: boolean; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/tenantMonthlySuccessSummary`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "isActive": params["isActive"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get a list of all Tenants names for filters
     * @param clientId 
     */
    apiV1TenantNamesGet(params: {  "clientId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/tenantNames`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "clientId": params["clientId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Change the friendly name for a tenant
     * @param body View model of tenant to rename. Only needs the tenant ID, and the new friendly name
     */
    apiV1TenantRenamePost(params: {  "body"?: TenantViewModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/tenant/rename`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * To restart the tenant. The functionality will be implemented in future stories
     * @param body 
     */
    apiV1TenantRestartPost(params: {  "body"?: TenantViewModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/tenant/restart`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Saves the tenant. If it doesn&#39;t exist yet, it creates the tenant
     * @param body 
     */
    apiV1TenantSavePost(params: {  "body"?: TenantViewModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/tenant/save`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Starts a job
     * @param body 
     */
    apiV1TenantStartPost(params: {  "body"?: TenantViewModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/tenant/start`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["body"]) {
            fetchOptions.body = JSON.stringify(params["body"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get all sucess summaries for tenants
     * @param isActive Active only
     */
    apiV1TenantSuccessSummaryGet(params: {  "isActive"?: boolean; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/tenantSuccessSummary`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "isActive": params["isActive"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get all recent tenants filtered and cropped to page.
     * @param isActive Active only
     */
    apiV1TenantSummaryGet(params: {  "isActive"?: boolean; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/tenantSummary`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "isActive": params["isActive"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Uploads vehicle mappings
     * @param tenantId 
     * @param mappingsFile 
     */
    apiV1TenantUploadMappingsPost(params: {  "tenantId"?: string; "mappingsFile"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/tenant/uploadMappings`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "tenantId": params["tenantId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/x-www-form-urlencoded" };
        fetchOptions.body = querystring.stringify({
            "mappingsFile": params["mappingsFile"],
        });
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get a list of all Tenants Types
     * @param tenantId 
     */
    apiV1TenantVehicleMappingsTenantIdGet(params: {  "tenantId": string; }, options?: any): FetchArgs {
        // verify required parameter "tenantId" is set
        if (params["tenantId"] == null) {
            throw new Error("Missing required parameter tenantId when calling apiV1TenantVehicleMappingsTenantIdGet");
        }
        const baseUrl = `/api/v1/tenant/vehicleMappings/{tenantId}`
            .replace(`{${"tenantId"}}`, `${ params["tenantId"] }`);
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get all recent tenants filtered and cropped to page.
     * @param isActive Active only
     */
    apiV1TenantsGet(params: {  "isActive"?: boolean; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/tenants`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "isActive": params["isActive"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get token expiration dates for tenants
     * @param isActive Active only
     */
    apiV1TokenExpirationDatesGet(params: {  "isActive"?: boolean; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/tokenExpirationDates`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "isActive": params["isActive"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * TenantApi - functional programming interface
 */
export const TenantApiFp = {
    /** 
     * Activates the tenant
     * @param body 
     */
    apiV1TenantActivatePost(params: { "body"?: TenantViewModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseBase> {
        const fetchArgs = TenantApiFetchParamCreator.apiV1TenantActivatePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Saves the tenant. If it doesn&#39;t exist yet, it creates the tenant
     * @param body 
     */
    apiV1TenantAdvancedSettingsSavePost(params: { "body"?: TenantViewModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseBase> {
        const fetchArgs = TenantApiFetchParamCreator.apiV1TenantAdvancedSettingsSavePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Create a new tenant
     * @param body 
     */
    apiV1TenantCreatePost(params: { "body"?: NewTenantViewModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TenantViewModelResponseBase> {
        const fetchArgs = TenantApiFetchParamCreator.apiV1TenantCreatePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Deletes the tenant
     * @param body 
     */
    apiV1TenantDeletePost(params: { "body"?: TenantViewModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseBase> {
        const fetchArgs = TenantApiFetchParamCreator.apiV1TenantDeletePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Deletes the tenant
     * @param body 
     */
    apiV1TenantHarddeletePost(params: { "body"?: TenantViewModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseBase> {
        const fetchArgs = TenantApiFetchParamCreator.apiV1TenantHarddeletePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get details on specific tenant
     * @param id Tenant UId to fetch
     */
    apiV1TenantIdGet(params: { "id": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TenantViewModelResponseBase> {
        const fetchArgs = TenantApiFetchParamCreator.apiV1TenantIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get all monthly success summaries for tenants
     * @param isActive Active only
     */
    apiV1TenantMonthlySuccessSummaryGet(params: { "isActive"?: boolean;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TenantMonthlySuccessSummaryViewModelIEnumerableResponseBase> {
        const fetchArgs = TenantApiFetchParamCreator.apiV1TenantMonthlySuccessSummaryGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get a list of all Tenants names for filters
     * @param clientId 
     */
    apiV1TenantNamesGet(params: { "clientId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StringIEnumerableResponseBase> {
        const fetchArgs = TenantApiFetchParamCreator.apiV1TenantNamesGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Change the friendly name for a tenant
     * @param body View model of tenant to rename. Only needs the tenant ID, and the new friendly name
     */
    apiV1TenantRenamePost(params: { "body"?: TenantViewModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseBase> {
        const fetchArgs = TenantApiFetchParamCreator.apiV1TenantRenamePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * To restart the tenant. The functionality will be implemented in future stories
     * @param body 
     */
    apiV1TenantRestartPost(params: { "body"?: TenantViewModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseBase> {
        const fetchArgs = TenantApiFetchParamCreator.apiV1TenantRestartPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Saves the tenant. If it doesn&#39;t exist yet, it creates the tenant
     * @param body 
     */
    apiV1TenantSavePost(params: { "body"?: TenantViewModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseBase> {
        const fetchArgs = TenantApiFetchParamCreator.apiV1TenantSavePost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Starts a job
     * @param body 
     */
    apiV1TenantStartPost(params: { "body"?: TenantViewModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseBase> {
        const fetchArgs = TenantApiFetchParamCreator.apiV1TenantStartPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get all sucess summaries for tenants
     * @param isActive Active only
     */
    apiV1TenantSuccessSummaryGet(params: { "isActive"?: boolean;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TenantSuccessSummaryViewModelIEnumerableResponseBase> {
        const fetchArgs = TenantApiFetchParamCreator.apiV1TenantSuccessSummaryGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get all recent tenants filtered and cropped to page.
     * @param isActive Active only
     */
    apiV1TenantSummaryGet(params: { "isActive"?: boolean;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TenantSummaryViewModelIEnumerableResponseBase> {
        const fetchArgs = TenantApiFetchParamCreator.apiV1TenantSummaryGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Uploads vehicle mappings
     * @param tenantId 
     * @param mappingsFile 
     */
    apiV1TenantUploadMappingsPost(params: { "tenantId"?: string; "mappingsFile"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StringResponseBase> {
        const fetchArgs = TenantApiFetchParamCreator.apiV1TenantUploadMappingsPost(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get a list of all Tenants Types
     * @param tenantId 
     */
    apiV1TenantVehicleMappingsTenantIdGet(params: { "tenantId": string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FileDownloadResponseBase> {
        const fetchArgs = TenantApiFetchParamCreator.apiV1TenantVehicleMappingsTenantIdGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get all recent tenants filtered and cropped to page.
     * @param isActive Active only
     */
    apiV1TenantsGet(params: { "isActive"?: boolean;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TenantViewModelIEnumerableResponseBase> {
        const fetchArgs = TenantApiFetchParamCreator.apiV1TenantsGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get token expiration dates for tenants
     * @param isActive Active only
     */
    apiV1TokenExpirationDatesGet(params: { "isActive"?: boolean;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TenantTokenExpirationDateViewModelIEnumerableResponseBase> {
        const fetchArgs = TenantApiFetchParamCreator.apiV1TokenExpirationDatesGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * TenantApi - object-oriented interface
 */
export class TenantApi extends BaseAPI {
    /** 
     * Activates the tenant
     * @param body 
     */
    apiV1TenantActivatePost(params: {  "body"?: TenantViewModel; }, options?: any) {
        return TenantApiFp.apiV1TenantActivatePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Saves the tenant. If it doesn&#39;t exist yet, it creates the tenant
     * @param body 
     */
    apiV1TenantAdvancedSettingsSavePost(params: {  "body"?: TenantViewModel; }, options?: any) {
        return TenantApiFp.apiV1TenantAdvancedSettingsSavePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Create a new tenant
     * @param body 
     */
    apiV1TenantCreatePost(params: {  "body"?: NewTenantViewModel; }, options?: any) {
        return TenantApiFp.apiV1TenantCreatePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Deletes the tenant
     * @param body 
     */
    apiV1TenantDeletePost(params: {  "body"?: TenantViewModel; }, options?: any) {
        return TenantApiFp.apiV1TenantDeletePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Deletes the tenant
     * @param body 
     */
    apiV1TenantHarddeletePost(params: {  "body"?: TenantViewModel; }, options?: any) {
        return TenantApiFp.apiV1TenantHarddeletePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get details on specific tenant
     * @param id Tenant UId to fetch
     */
    apiV1TenantIdGet(params: {  "id": string; }, options?: any) {
        return TenantApiFp.apiV1TenantIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get all monthly success summaries for tenants
     * @param isActive Active only
     */
    apiV1TenantMonthlySuccessSummaryGet(params: {  "isActive"?: boolean; }, options?: any) {
        return TenantApiFp.apiV1TenantMonthlySuccessSummaryGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get a list of all Tenants names for filters
     * @param clientId 
     */
    apiV1TenantNamesGet(params: {  "clientId"?: string; }, options?: any) {
        return TenantApiFp.apiV1TenantNamesGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Change the friendly name for a tenant
     * @param body View model of tenant to rename. Only needs the tenant ID, and the new friendly name
     */
    apiV1TenantRenamePost(params: {  "body"?: TenantViewModel; }, options?: any) {
        return TenantApiFp.apiV1TenantRenamePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * To restart the tenant. The functionality will be implemented in future stories
     * @param body 
     */
    apiV1TenantRestartPost(params: {  "body"?: TenantViewModel; }, options?: any) {
        return TenantApiFp.apiV1TenantRestartPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Saves the tenant. If it doesn&#39;t exist yet, it creates the tenant
     * @param body 
     */
    apiV1TenantSavePost(params: {  "body"?: TenantViewModel; }, options?: any) {
        return TenantApiFp.apiV1TenantSavePost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Starts a job
     * @param body 
     */
    apiV1TenantStartPost(params: {  "body"?: TenantViewModel; }, options?: any) {
        return TenantApiFp.apiV1TenantStartPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get all sucess summaries for tenants
     * @param isActive Active only
     */
    apiV1TenantSuccessSummaryGet(params: {  "isActive"?: boolean; }, options?: any) {
        return TenantApiFp.apiV1TenantSuccessSummaryGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get all recent tenants filtered and cropped to page.
     * @param isActive Active only
     */
    apiV1TenantSummaryGet(params: {  "isActive"?: boolean; }, options?: any) {
        return TenantApiFp.apiV1TenantSummaryGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Uploads vehicle mappings
     * @param tenantId 
     * @param mappingsFile 
     */
    apiV1TenantUploadMappingsPost(params: {  "tenantId"?: string; "mappingsFile"?: string; }, options?: any) {
        return TenantApiFp.apiV1TenantUploadMappingsPost(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get a list of all Tenants Types
     * @param tenantId 
     */
    apiV1TenantVehicleMappingsTenantIdGet(params: {  "tenantId": string; }, options?: any) {
        return TenantApiFp.apiV1TenantVehicleMappingsTenantIdGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get all recent tenants filtered and cropped to page.
     * @param isActive Active only
     */
    apiV1TenantsGet(params: {  "isActive"?: boolean; }, options?: any) {
        return TenantApiFp.apiV1TenantsGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get token expiration dates for tenants
     * @param isActive Active only
     */
    apiV1TokenExpirationDatesGet(params: {  "isActive"?: boolean; }, options?: any) {
        return TenantApiFp.apiV1TokenExpirationDatesGet(params, options)(this.fetch, this.basePath);
    }
};

/**
 * TenantApi - factory interface
 */
export const TenantApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Activates the tenant
         * @param body 
         */
        apiV1TenantActivatePost(params: {  "body"?: TenantViewModel; }, options?: any) {
            return TenantApiFp.apiV1TenantActivatePost(params, options)(fetch, basePath);
        },
        /** 
         * Saves the tenant. If it doesn&#39;t exist yet, it creates the tenant
         * @param body 
         */
        apiV1TenantAdvancedSettingsSavePost(params: {  "body"?: TenantViewModel; }, options?: any) {
            return TenantApiFp.apiV1TenantAdvancedSettingsSavePost(params, options)(fetch, basePath);
        },
        /** 
         * Create a new tenant
         * @param body 
         */
        apiV1TenantCreatePost(params: {  "body"?: NewTenantViewModel; }, options?: any) {
            return TenantApiFp.apiV1TenantCreatePost(params, options)(fetch, basePath);
        },
        /** 
         * Deletes the tenant
         * @param body 
         */
        apiV1TenantDeletePost(params: {  "body"?: TenantViewModel; }, options?: any) {
            return TenantApiFp.apiV1TenantDeletePost(params, options)(fetch, basePath);
        },
        /** 
         * Deletes the tenant
         * @param body 
         */
        apiV1TenantHarddeletePost(params: {  "body"?: TenantViewModel; }, options?: any) {
            return TenantApiFp.apiV1TenantHarddeletePost(params, options)(fetch, basePath);
        },
        /** 
         * Get details on specific tenant
         * @param id Tenant UId to fetch
         */
        apiV1TenantIdGet(params: {  "id": string; }, options?: any) {
            return TenantApiFp.apiV1TenantIdGet(params, options)(fetch, basePath);
        },
        /** 
         * Get all monthly success summaries for tenants
         * @param isActive Active only
         */
        apiV1TenantMonthlySuccessSummaryGet(params: {  "isActive"?: boolean; }, options?: any) {
            return TenantApiFp.apiV1TenantMonthlySuccessSummaryGet(params, options)(fetch, basePath);
        },
        /** 
         * Get a list of all Tenants names for filters
         * @param clientId 
         */
        apiV1TenantNamesGet(params: {  "clientId"?: string; }, options?: any) {
            return TenantApiFp.apiV1TenantNamesGet(params, options)(fetch, basePath);
        },
        /** 
         * Change the friendly name for a tenant
         * @param body View model of tenant to rename. Only needs the tenant ID, and the new friendly name
         */
        apiV1TenantRenamePost(params: {  "body"?: TenantViewModel; }, options?: any) {
            return TenantApiFp.apiV1TenantRenamePost(params, options)(fetch, basePath);
        },
        /** 
         * To restart the tenant. The functionality will be implemented in future stories
         * @param body 
         */
        apiV1TenantRestartPost(params: {  "body"?: TenantViewModel; }, options?: any) {
            return TenantApiFp.apiV1TenantRestartPost(params, options)(fetch, basePath);
        },
        /** 
         * Saves the tenant. If it doesn&#39;t exist yet, it creates the tenant
         * @param body 
         */
        apiV1TenantSavePost(params: {  "body"?: TenantViewModel; }, options?: any) {
            return TenantApiFp.apiV1TenantSavePost(params, options)(fetch, basePath);
        },
        /** 
         * Starts a job
         * @param body 
         */
        apiV1TenantStartPost(params: {  "body"?: TenantViewModel; }, options?: any) {
            return TenantApiFp.apiV1TenantStartPost(params, options)(fetch, basePath);
        },
        /** 
         * Get all sucess summaries for tenants
         * @param isActive Active only
         */
        apiV1TenantSuccessSummaryGet(params: {  "isActive"?: boolean; }, options?: any) {
            return TenantApiFp.apiV1TenantSuccessSummaryGet(params, options)(fetch, basePath);
        },
        /** 
         * Get all recent tenants filtered and cropped to page.
         * @param isActive Active only
         */
        apiV1TenantSummaryGet(params: {  "isActive"?: boolean; }, options?: any) {
            return TenantApiFp.apiV1TenantSummaryGet(params, options)(fetch, basePath);
        },
        /** 
         * Uploads vehicle mappings
         * @param tenantId 
         * @param mappingsFile 
         */
        apiV1TenantUploadMappingsPost(params: {  "tenantId"?: string; "mappingsFile"?: string; }, options?: any) {
            return TenantApiFp.apiV1TenantUploadMappingsPost(params, options)(fetch, basePath);
        },
        /** 
         * Get a list of all Tenants Types
         * @param tenantId 
         */
        apiV1TenantVehicleMappingsTenantIdGet(params: {  "tenantId": string; }, options?: any) {
            return TenantApiFp.apiV1TenantVehicleMappingsTenantIdGet(params, options)(fetch, basePath);
        },
        /** 
         * Get all recent tenants filtered and cropped to page.
         * @param isActive Active only
         */
        apiV1TenantsGet(params: {  "isActive"?: boolean; }, options?: any) {
            return TenantApiFp.apiV1TenantsGet(params, options)(fetch, basePath);
        },
        /** 
         * Get token expiration dates for tenants
         * @param isActive Active only
         */
        apiV1TokenExpirationDatesGet(params: {  "isActive"?: boolean; }, options?: any) {
            return TenantApiFp.apiV1TokenExpirationDatesGet(params, options)(fetch, basePath);
        },
    };
};


/**
 * TransportationApi - fetch parameter creator
 */
export const TransportationApiFetchParamCreator = {
    /** 
     * Get driving faults for the given job ID
     * @param jobId 
     */
    apiV1DrivingFaultsGet(params: {  "jobId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/drivingFaults`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "jobId": params["jobId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get driving statuses for the given job ID
     * @param jobId 
     */
    apiV1DrivingStatusesGet(params: {  "jobId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/drivingStatuses`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "jobId": params["jobId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get raw data blobs (paged) for the given job ID
     * @param jobId 
     * @param skip 
     * @param take 
     */
    apiV1RawDataBlobGet(params: {  "jobId"?: string; "skip"?: number; "take"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/RawDataBlob`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "jobId": params["jobId"],
            "skip": params["skip"],
            "take": params["take"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get vehicle assignments for the given job ID
     * @param jobId 
     */
    apiV1VehicleAssignmentsGet(params: {  "jobId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/vehicleAssignments`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "jobId": params["jobId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * TransportationApi - functional programming interface
 */
export const TransportationApiFp = {
    /** 
     * Get driving faults for the given job ID
     * @param jobId 
     */
    apiV1DrivingFaultsGet(params: { "jobId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DrivingFaultEventIEnumerableResponseBase> {
        const fetchArgs = TransportationApiFetchParamCreator.apiV1DrivingFaultsGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get driving statuses for the given job ID
     * @param jobId 
     */
    apiV1DrivingStatusesGet(params: { "jobId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DrivingStatusEventIEnumerableResponseBase> {
        const fetchArgs = TransportationApiFetchParamCreator.apiV1DrivingStatusesGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get raw data blobs (paged) for the given job ID
     * @param jobId 
     * @param skip 
     * @param take 
     */
    apiV1RawDataBlobGet(params: { "jobId"?: string; "skip"?: number; "take"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RawDataViewModelIEnumerablePagedResponseBase> {
        const fetchArgs = TransportationApiFetchParamCreator.apiV1RawDataBlobGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get vehicle assignments for the given job ID
     * @param jobId 
     */
    apiV1VehicleAssignmentsGet(params: { "jobId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VehicleAssignmentViewModelIEnumerableResponseBase> {
        const fetchArgs = TransportationApiFetchParamCreator.apiV1VehicleAssignmentsGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * TransportationApi - object-oriented interface
 */
export class TransportationApi extends BaseAPI {
    /** 
     * Get driving faults for the given job ID
     * @param jobId 
     */
    apiV1DrivingFaultsGet(params: {  "jobId"?: string; }, options?: any) {
        return TransportationApiFp.apiV1DrivingFaultsGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get driving statuses for the given job ID
     * @param jobId 
     */
    apiV1DrivingStatusesGet(params: {  "jobId"?: string; }, options?: any) {
        return TransportationApiFp.apiV1DrivingStatusesGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get raw data blobs (paged) for the given job ID
     * @param jobId 
     * @param skip 
     * @param take 
     */
    apiV1RawDataBlobGet(params: {  "jobId"?: string; "skip"?: number; "take"?: number; }, options?: any) {
        return TransportationApiFp.apiV1RawDataBlobGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get vehicle assignments for the given job ID
     * @param jobId 
     */
    apiV1VehicleAssignmentsGet(params: {  "jobId"?: string; }, options?: any) {
        return TransportationApiFp.apiV1VehicleAssignmentsGet(params, options)(this.fetch, this.basePath);
    }
};

/**
 * TransportationApi - factory interface
 */
export const TransportationApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Get driving faults for the given job ID
         * @param jobId 
         */
        apiV1DrivingFaultsGet(params: {  "jobId"?: string; }, options?: any) {
            return TransportationApiFp.apiV1DrivingFaultsGet(params, options)(fetch, basePath);
        },
        /** 
         * Get driving statuses for the given job ID
         * @param jobId 
         */
        apiV1DrivingStatusesGet(params: {  "jobId"?: string; }, options?: any) {
            return TransportationApiFp.apiV1DrivingStatusesGet(params, options)(fetch, basePath);
        },
        /** 
         * Get raw data blobs (paged) for the given job ID
         * @param jobId 
         * @param skip 
         * @param take 
         */
        apiV1RawDataBlobGet(params: {  "jobId"?: string; "skip"?: number; "take"?: number; }, options?: any) {
            return TransportationApiFp.apiV1RawDataBlobGet(params, options)(fetch, basePath);
        },
        /** 
         * Get vehicle assignments for the given job ID
         * @param jobId 
         */
        apiV1VehicleAssignmentsGet(params: {  "jobId"?: string; }, options?: any) {
            return TransportationApiFp.apiV1VehicleAssignmentsGet(params, options)(fetch, basePath);
        },
    };
};


/**
 * UserApi - fetch parameter creator
 */
export const UserApiFetchParamCreator = {
    /** 
     * Gets the user.
     */
    apiV1UserGet(options?: any): FetchArgs {
        const baseUrl = `/api/v1/User`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * UserApi - functional programming interface
 */
export const UserApiFp = {
    /** 
     * Gets the user.
     */
    apiV1UserGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserInfoViewModel> {
        const fetchArgs = UserApiFetchParamCreator.apiV1UserGet(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * UserApi - object-oriented interface
 */
export class UserApi extends BaseAPI {
    /** 
     * Gets the user.
     */
    apiV1UserGet(options?: any) {
        return UserApiFp.apiV1UserGet(options)(this.fetch, this.basePath);
    }
};

/**
 * UserApi - factory interface
 */
export const UserApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Gets the user.
         */
        apiV1UserGet(options?: any) {
            return UserApiFp.apiV1UserGet(options)(fetch, basePath);
        },
    };
};


/**
 * VehicleSearchApi - fetch parameter creator
 */
export const VehicleSearchApiFetchParamCreator = {
    /** 
     * Get duty statuses attached to given vehicle search criteria
     * @param tenantId 
     * @param startDateTime 
     * @param endDateTime 
     * @param vehicleId 
     * @param driverId 
     * @param lastName 
     */
    apiV1VehicleSearchDutyStatusGet(params: {  "tenantId"?: string; "startDateTime"?: string; "endDateTime"?: string; "vehicleId"?: string; "driverId"?: string; "lastName"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/VehicleSearch/dutyStatus`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "tenantId": params["tenantId"],
            "startDateTime": params["startDateTime"],
            "endDateTime": params["endDateTime"],
            "vehicleId": params["vehicleId"],
            "driverId": params["driverId"],
            "lastName": params["lastName"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /** 
     * Get vehicle assignments for given search criteria
     * @param tenantId 
     * @param startDateTime 
     * @param endDateTime 
     * @param vehicleId 
     * @param driverId 
     * @param lastName 
     */
    apiV1VehicleSearchVehicleAssignmentGet(params: {  "tenantId"?: string; "startDateTime"?: string; "endDateTime"?: string; "vehicleId"?: string; "driverId"?: string; "lastName"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/v1/VehicleSearch/vehicleAssignment`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "tenantId": params["tenantId"],
            "startDateTime": params["startDateTime"],
            "endDateTime": params["endDateTime"],
            "vehicleId": params["vehicleId"],
            "driverId": params["driverId"],
            "lastName": params["lastName"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = contentTypeHeader;
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * VehicleSearchApi - functional programming interface
 */
export const VehicleSearchApiFp = {
    /** 
     * Get duty statuses attached to given vehicle search criteria
     * @param tenantId 
     * @param startDateTime 
     * @param endDateTime 
     * @param vehicleId 
     * @param driverId 
     * @param lastName 
     */
    apiV1VehicleSearchDutyStatusGet(params: { "tenantId"?: string; "startDateTime"?: string; "endDateTime"?: string; "vehicleId"?: string; "driverId"?: string; "lastName"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DutyStatusActivityIEnumerableResponseBase> {
        const fetchArgs = VehicleSearchApiFetchParamCreator.apiV1VehicleSearchDutyStatusGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /** 
     * Get vehicle assignments for given search criteria
     * @param tenantId 
     * @param startDateTime 
     * @param endDateTime 
     * @param vehicleId 
     * @param driverId 
     * @param lastName 
     */
    apiV1VehicleSearchVehicleAssignmentGet(params: { "tenantId"?: string; "startDateTime"?: string; "endDateTime"?: string; "vehicleId"?: string; "driverId"?: string; "lastName"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AssignmentIEnumerableResponseBase> {
        const fetchArgs = VehicleSearchApiFetchParamCreator.apiV1VehicleSearchVehicleAssignmentGet(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * VehicleSearchApi - object-oriented interface
 */
export class VehicleSearchApi extends BaseAPI {
    /** 
     * Get duty statuses attached to given vehicle search criteria
     * @param tenantId 
     * @param startDateTime 
     * @param endDateTime 
     * @param vehicleId 
     * @param driverId 
     * @param lastName 
     */
    apiV1VehicleSearchDutyStatusGet(params: {  "tenantId"?: string; "startDateTime"?: string; "endDateTime"?: string; "vehicleId"?: string; "driverId"?: string; "lastName"?: string; }, options?: any) {
        return VehicleSearchApiFp.apiV1VehicleSearchDutyStatusGet(params, options)(this.fetch, this.basePath);
    }
    /** 
     * Get vehicle assignments for given search criteria
     * @param tenantId 
     * @param startDateTime 
     * @param endDateTime 
     * @param vehicleId 
     * @param driverId 
     * @param lastName 
     */
    apiV1VehicleSearchVehicleAssignmentGet(params: {  "tenantId"?: string; "startDateTime"?: string; "endDateTime"?: string; "vehicleId"?: string; "driverId"?: string; "lastName"?: string; }, options?: any) {
        return VehicleSearchApiFp.apiV1VehicleSearchVehicleAssignmentGet(params, options)(this.fetch, this.basePath);
    }
};

/**
 * VehicleSearchApi - factory interface
 */
export const VehicleSearchApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /** 
         * Get duty statuses attached to given vehicle search criteria
         * @param tenantId 
         * @param startDateTime 
         * @param endDateTime 
         * @param vehicleId 
         * @param driverId 
         * @param lastName 
         */
        apiV1VehicleSearchDutyStatusGet(params: {  "tenantId"?: string; "startDateTime"?: string; "endDateTime"?: string; "vehicleId"?: string; "driverId"?: string; "lastName"?: string; }, options?: any) {
            return VehicleSearchApiFp.apiV1VehicleSearchDutyStatusGet(params, options)(fetch, basePath);
        },
        /** 
         * Get vehicle assignments for given search criteria
         * @param tenantId 
         * @param startDateTime 
         * @param endDateTime 
         * @param vehicleId 
         * @param driverId 
         * @param lastName 
         */
        apiV1VehicleSearchVehicleAssignmentGet(params: {  "tenantId"?: string; "startDateTime"?: string; "endDateTime"?: string; "vehicleId"?: string; "driverId"?: string; "lastName"?: string; }, options?: any) {
            return VehicleSearchApiFp.apiV1VehicleSearchVehicleAssignmentGet(params, options)(fetch, basePath);
        },
    };
};
